export const ApiUserRole = `${process.env.REACT_APP_PUBLIC_SERVICE_URL}/user-role`;
export const ApiUserRoleAdd = `${process.env.REACT_APP_PUBLIC_SERVICE_URL}/user-role/add-section-role`;
export const ApiUserRoleWC = `${process.env.REACT_APP_PUBLIC_SERVICE_URL}/user-role/waste-collectors`;
export const ApiUserRoleId = (id: string) =>
    `${process.env.REACT_APP_PUBLIC_SERVICE_URL}/user-role/${id}`;
export const ApiUserRoleIdWithoutSections = (id: string) =>
    `${process.env.REACT_APP_PUBLIC_SERVICE_URL}/user-role/${id}/without-sections`;
export const ApiUserRoleIdUsers = (id: string) =>
    `${process.env.REACT_APP_PUBLIC_SERVICE_URL}/user-role/${id}/users`;
export const ApiUserRoleByRole = (id: string) =>
    `${process.env.REACT_APP_PUBLIC_SERVICE_URL}/user-role/by-role/${id}`;
