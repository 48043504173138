export const Zfill = (number, width) => {
    const numberOutput = Math.abs(number); /* Valor absoluto del número */
    const length = number.toString().length; /* Largo del número */
    const zero = '0'; /* String de cero */

    if (width <= length) {
        if (number < 0) {
            return '-' + numberOutput.toString();
        } else {
            return numberOutput.toString();
        }
    } else {
        if (number < 0) {
            return '-' + zero.repeat(width - length) + numberOutput.toString();
        } else {
            return zero.repeat(width - length) + numberOutput.toString();
        }
    }
};

export const NormalizeName = (name: string) => {
    const from = 'ÁÄÂÀÃÅÇÉËÊÈÍÏÎÌÑÓÖÔÒÕÚÜÛÙÝáäâàãåçéëêèíïîìñóöôòõúüûùýÿ';
    const to = 'AAAAAACEEEEIIIINOOOOOUUUUYaaaaaaceeeeiiiinooooouuuuyy';
    const mapping: any = {};

    for (let i = 0; i < from.length; i++) {
        mapping[from.charAt(i)] = to.charAt(i);
    }

    const removeAccents = (str: string) => {
        return str
            .split('')
            .map(char => mapping[char] || char)
            .join('');
    };
    return removeAccents(name)
        .toLowerCase()
        .replace(/\s+/g, '')
        .replace(/[^\w-]/g, '');
};
