export const ApiUsers = `${process.env.REACT_APP_PUBLIC_SERVICE_URL}/user`;
export const ApiUsersRequestResetPassword = `${process.env.REACT_APP_PUBLIC_SERVICE_URL}/user/request-reset-password`;
export const ApiUsersResetPassword = `${process.env.REACT_APP_PUBLIC_SERVICE_URL}/user/reset-password`;
export const ApiUsersResetPass = `${process.env.REACT_APP_PUBLIC_SERVICE_URL}/user/reset-pass`;
export const ApiUsersVerifyRptId = (id: string) =>
    `${process.env.REACT_APP_PUBLIC_SERVICE_URL}/user/verify-rpt/${id}`;
export const ApiUsersByEmail = (email: string) =>
    `${process.env.REACT_APP_PUBLIC_SERVICE_URL}/user/by-email/${email}`;
export const ApiUsersId = (id: string) =>
    `${process.env.REACT_APP_PUBLIC_SERVICE_URL}/user/${id}`;
