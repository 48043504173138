const Loader = () => {
    return (
        <div
            className='loading-component'
            style={{
                position: 'fixed',
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
                background: 'rgba(255,255,255,0.5)',
                zIndex: 10,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >
            <div className='container'>
                <img className='loader' src={`/svg-icons/LoaderIcon.svg`} />
            </div>
        </div>
    );
};

export default Loader;
