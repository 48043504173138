import { useEffect } from 'react';

interface Props {
    children: any;
    handler: any;
    onMessage: any;
}

const minutesdelay = 15;

const IdleTimer = ({ children, handler, onMessage }: Props) => {
    const checkInactivity = () => {
        const expireTime: string | null = localStorage.getItem('expireTime');
        if (expireTime && parseInt(expireTime) < Date.now()) {
            onMessage();
            setTimeout(() => {
                handler();
            }, 4000);
        }
    };

    const updateExpireTime = () => {
        const deffaultSessionTime = Date.now() + 1000 * 60 * minutesdelay;
        localStorage.setItem('expireTime', deffaultSessionTime.toString());
    };

    useEffect(() => {
        const interval = setInterval(() => {
            checkInactivity();
        }, 5000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        updateExpireTime();
        window.addEventListener('click', updateExpireTime);
        window.addEventListener('keypress', updateExpireTime);
        window.addEventListener('scroll', updateExpireTime);
        window.addEventListener('mousemove', updateExpireTime);

        return () => {
            window.removeEventListener('click', updateExpireTime);
            window.removeEventListener('keypress', updateExpireTime);
            window.removeEventListener('scroll', updateExpireTime);
            window.removeEventListener('mousemove', updateExpireTime);
        };
    }, []);

    return <> {children} </>;
};

export default IdleTimer;
