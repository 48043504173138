import { createSlice } from '@reduxjs/toolkit';
import { ClientDefault } from './initialValues';

export const clientSlice = createSlice({
    name: 'client',
    initialState: ClientDefault,
    reducers: {
        setClientData: (state, action) => {
            const data = action.payload;
            state.clientData = data;
        },
        setSelectedClients: (state, action) => {
            const data = action.payload;
            state.selected = data;
        }
    }
});

export const { setClientData, setSelectedClients } = clientSlice.actions;

export default clientSlice.reducer;
