import DetailsComponent from '../../../components/details/details';
import { AppSectionInterface } from '../../../interface';
import { useEffect, useState } from 'react';
import { Button } from 'antd';
import { Container } from '../../../components/styles';
import './roleAccess.scss';

interface Props {
    showDetails: boolean;
    setShowDetails: any;
    detailsTitle: string;
    asociatedTitle: string;
    unasociatedTitle: string;
    asociatedAccessList: AppSectionInterface[];
    unassociatedAccessList: AppSectionInterface[];
    SuccessSave: any;
}

const RoleAccess = ({
    showDetails,
    setShowDetails,
    detailsTitle,
    asociatedTitle,
    unasociatedTitle,
    asociatedAccessList,
    unassociatedAccessList,
    SuccessSave
}: Props) => {
    const [asociatedList, setAsociatedList] = useState<
        AppSectionInterface[] | undefined
    >([]);
    const [unassociatedList, setUnassociatedList] = useState<
        AppSectionInterface[] | undefined
    >([]);

    useEffect(() => {
        if (asociatedAccessList) {
            setAsociatedList(asociatedAccessList);
        }
    }, [asociatedAccessList]);

    useEffect(() => {
        if (unassociatedAccessList) {
            setUnassociatedList(unassociatedAccessList);
        }
    }, [asociatedAccessList]);

    const AddOrRemove = (id: string, action: string) => {
        SuccessSave(id, action);
    };

    return (
        <DetailsComponent
            showSection={showDetails}
            closeSection={setShowDetails}
            title={detailsTitle}
        >
            <Container>
                <h6 className='role_access_title'>{unasociatedTitle}</h6>
                {unassociatedList?.map((unassociated: AppSectionInterface) => {
                    return (
                        <ol key={unassociated.id}>
                            <li>
                                <div className='ms-2 me-auto'>
                                    <div className='ctItems'>
                                        <span className='lg-name'>
                                            {unassociated.name}
                                        </span>
                                        {unassociated.isAside && (
                                            <span className='lg-type'>
                                                Main
                                            </span>
                                        )}
                                        {unassociated.isHeader && (
                                            <span className='lg-type'>
                                                Header
                                            </span>
                                        )}
                                        {unassociated.isIcon && (
                                            <span className='lg-type'>
                                                Icon
                                            </span>
                                        )}
                                        <Button
                                            className='bzero_btn_primary'
                                            onClick={() => {
                                                AddOrRemove(
                                                    unassociated.id,
                                                    'ADD'
                                                );
                                            }}
                                        >
                                            {' '}
                                            +{' '}
                                        </Button>
                                    </div>
                                </div>
                            </li>
                        </ol>
                    );
                })}
            </Container>
            <Container>
                <h6 className='role_access_title'>{asociatedTitle}</h6>
                {asociatedList?.map(asociated => {
                    return (
                        <ol key={asociated.id}>
                            <li>
                                <div className='ms-2 me-auto'>
                                    <div className='ctItems'>
                                        <span className='lg-name'>
                                            {asociated.name}
                                        </span>
                                        {asociated.isAside && (
                                            <span className='lg-type'>
                                                Main
                                            </span>
                                        )}
                                        {asociated.isHeader && (
                                            <span className='lg-type'>
                                                Header
                                            </span>
                                        )}
                                        {asociated.isIcon && (
                                            <span className='lg-type'>
                                                Icon
                                            </span>
                                        )}
                                        <Button
                                            className='bzero_btn_danger'
                                            onClick={() => {
                                                AddOrRemove(
                                                    asociated.id,
                                                    'REMOVE'
                                                );
                                            }}
                                        >
                                            {' '}
                                            -{' '}
                                        </Button>
                                    </div>
                                </div>
                            </li>
                        </ol>
                    );
                })}
            </Container>
        </DetailsComponent>
    );
};

export default RoleAccess;
