export const lang = {
    es: {
        titles: {
            delette: 'Eliminar',
            update: 'Modificar',
            create: 'Crear',
            closeSession: 'Cerar sesión'
        },
        okButtons: {
            okButtonAccept: 'Aceptar',
            okButtonOk: 'Ok',
            okButtonYes: 'Si',
            okButtonSave: 'Guardar',
            okButtonModify: 'Modificar',
            okButtonCreate: 'Crear'
        },
        cancelButtons: {
            cancelButtonsCancel: 'Cancelar',
            cancelButtonsNo: 'No',
            cancelButtonsClose: 'Cerrar',
            cancelButtonsExit: 'Salir',
            cancelButtonsCloseSession: 'Cerrar sesión'
        }
    },
    en: {
        titles: {
            delette: 'Delete',
            update: 'Update',
            create: 'Create',
            closeSession: 'Close session'
        },
        okButtons: {
            okButtonAccept: 'Accept',
            okButtonOk: 'Ok',
            okButtonYes: 'Yes',
            okButtonSave: 'Save',
            okButtonModify: 'Update',
            okButtonCreate: 'Create'
        },
        cancelButtons: {
            cancelButtonsCancel: 'Cancel',
            cancelButtonsNo: 'No',
            cancelButtonsClose: 'Close',
            cancelButtonsExit: 'Exit',
            cancelButtonsCloseSession: 'Close session'
        }
    }
};
