import { FormBuilderFields } from '../../../../../components/formBuilder/form-builder.interface';

export const FormCreateSupplyRequirement: FormBuilderFields[] = [
    {
        label: 'Nombre',
        fieldNameId: 'name',
        fieldType: 'text',
        fieldPlaceholder: 'Ingrese el nombre',
        isFocusInClean: true,
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            },
            min: {
                length: 3,
                state: true,
                message: 'Mínimo 3 carateres'
            },
            max: {
                length: 40,
                state: true,
                message: 'Máximo 40 caracteres'
            }
        }
    },
    {
        label: 'Fase de entrega',
        fieldNameId: 'deliveryPhase',
        fieldType: 'select',
        elements: [
            { id: 'A', name: 'A' },
            { id: 'B', name: 'B' }
        ],
        fieldPlaceholder: 'Seleccione la fase de entrega',
        isFocusInClean: true,
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            }
        }
    },
    {
        label: 'Area',
        fieldNameId: 'area',
        fieldType: 'select',
        elements: [
            { id: 'garbage', name: 'Basura' },
            { id: 'reusable', name: 'Reutilizable' }
        ],
        fieldPlaceholder: 'Seleccione el area',
        isFocusInClean: true,
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            }
        }
    },
    {
        label: 'Tipología',
        fieldNameId: 'tipology',
        fieldType: 'select',
        elements: [
            { id: 'direct', name: 'Directo' },
            { id: 'indirect', name: 'Indirecto' },
            { id: 'notVisible', name: 'Invisible' }
        ],
        fieldPlaceholder: 'Seleccione la tipología',
        isFocusInClean: true,
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            }
        }
    },
    {
        label: 'Nombre indirecto',
        fieldNameId: 'nameIndirect',
        /* depends: 'tipology',
        dependsValues: ['Indirect', 'indirect'], */
        fieldType: 'text',
        fieldPlaceholder: 'Ingrese el nombre indirecto',
        isFocusInClean: true,
        validations: {
            type: 'string',
            required: {
                state: false,
                message: 'Este campo es requerido'
            },
            min: {
                length: 3,
                state: true,
                message: 'Mínimo 3 carateres'
            },
            max: {
                length: 200,
                state: true,
                message: 'Máximo 200 caracteres'
            }
        }
    },
    {
        label: 'Stock mínimo',
        fieldNameId: 'minStock',
        fieldType: 'number',
        fieldPlaceholder: 'Ingrese el stock mínimo',
        isFocusInClean: true,
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            }
        }
    },
    {
        label: 'Precio',
        fieldNameId: 'price',
        fieldType: 'number',
        fieldPlaceholder: 'Ingrese el precio',
        isFocusInClean: true,
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            }
        }
    },
    {
        label: 'Inmueble',
        fieldNameId: 'property',
        fieldType: 'select',
        elements: [],
        fieldPlaceholder: 'Seleccione el inmueble',
        isFocusInClean: true,
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            }
        }
    },
    {
        label: 'Unidad',
        fieldNameId: 'propertyUnit',
        fieldType: 'select',
        elements: [],
        fieldPlaceholder: 'Seleccione la unidad',
        isFocusInClean: true,
        validations: {
            type: 'string',
            required: {
                state: false,
                message: 'Este campo es requerido'
            }
        }
    },
    {
        label: 'Insumo',
        fieldNameId: 'supply',
        fieldType: 'select',
        elements: [],
        fieldPlaceholder: 'Seleccione el insumo',
        isFocusInClean: true,
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            }
        }
    }
    /* {
        label: 'Requerimiento invisible',
        fieldNameId: 'supplyRequirementAppendixInvisible',
        depends: 'tipology',
        dependsValues: ['Indirect', 'indirect'],
        fieldType: 'select',
        elements: [],
        fieldPlaceholder: 'Seleccione el requerimiento invisible',
        isFocusInClean: true,
        validations: {
            type: 'string',
            required: {
                state: false,
                message: 'Este campo es requerido'
            }
        }
    }, */
];
