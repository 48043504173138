import './settingsBar.scss';
//  <--COMPONENTS--> //
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { GetStorage } from '../../config/utils/Storage';
import { useAppSelector } from '../../store/hooks';
import useWindowDimensions from '../../config/hooks/useWindowDimentions';
import { AppSectionInterface } from '../../interface';
import { Tooltip } from 'antd';
import { lang } from '../langs';
import { UserStoreInterface } from '../../store/slices/users/user.interface';

const deffaultRole =
    parseInt(GetStorage(process.env.REACT_APP_PUBLIC_DEFFAULT_ROLE)) || 0;

interface Props {
    title: string;
    icon: string;
    kind?: string;
    option?: string;
}

const SettingsBar = ({ title, icon, kind, option }: Props) => {
    const { preferences }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const defaultLang: string = preferences.lang;
    const key = defaultLang;
    const {
        [key as keyof typeof lang]: {
            settingsBar: { titleSettingsBar }
        }
    } = lang;
    const { userData /* , preferences */ } = useAppSelector(
        state => state.users
    );
    const { roles } = userData;
    const { appSections } = roles[deffaultRole];
    const [sections, setSections] = useState<AppSectionInterface[]>([]);
    const { width } = useWindowDimensions();
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        setSections(appSections);
    }, [appSections]);

    useEffect(() => {
        setIsMobile(width <= 768);
    }, [width]);

    const numColumns = sections.filter(item => item.isAside).length;

    return (
        <div className='settingsBar'>
            {kind === 'settings' ? (
                <div className='settingsBar__setTitle'>
                    <Link to='/panel/settings' className='link-bar'>
                        <img
                            className='settingsBar__setTitle__icon'
                            src={`/svg-icons/SettingsIconB.svg`}
                        ></img>
                        <div className='settingsBar__setTitle__text title'>
                            {titleSettingsBar}
                        </div>
                    </Link>
                </div>
            ) : (
                <div className='settingsBar__setTitle'>
                    <img
                        className='settingsBar__setTitle__icon'
                        src={`/svg-icons/${icon}`}
                    ></img>
                    <div className='settingsBar__setTitle__text title'>
                        {title}
                    </div>
                </div>
            )}
            {!isMobile && (
                <div
                    className='settingsBar__grid-container'
                    style={{
                        gridTemplateColumns: `repeat(${numColumns}, 1fr)`
                    }}
                >
                    {kind === 'settings' &&
                        sections.map(item => {
                            return (
                                item.isAside && (
                                    <Tooltip
                                        title={
                                            <span className='settingsBar__grid-container__tooltip'>
                                                {item.name}
                                            </span>
                                        }
                                        color='#fff'
                                        key={item.id}
                                    >
                                        <Link
                                            style={{ textDecoration: 'none' }}
                                            to={`/panel/${item.path}`}
                                        >
                                            {' '}
                                            {item.name !== option ? (
                                                <div className='settingsBar__grid-container__setItem'>
                                                    {item.icon !== null ? (
                                                        <img
                                                            className='settingsBar__grid-container__setItem__setIcon'
                                                            src={`/svg-icons/settings/${item.icon}W.svg`}
                                                        />
                                                    ) : (
                                                        item.name
                                                    )}
                                                </div>
                                            ) : (
                                                <div className='settingsBar__grid-container__setItemActive'>
                                                    {item.icon !== null ? (
                                                        <img
                                                            className='settingsBar__grid-container__setItemActive__setIcon'
                                                            src={`/svg-icons/settings/${item.icon}.svg`}
                                                        />
                                                    ) : (
                                                        item.name
                                                    )}
                                                </div>
                                            )}
                                        </Link>
                                    </Tooltip>
                                )
                            );
                        })}
                </div>
            )}
        </div>
    );
};

export default SettingsBar;
