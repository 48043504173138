import './calendarRequest.scss';
import { CloseOutlined } from '@ant-design/icons';
import CalendarRequestCreateComponent from './components/create';

const CalendarRequest = () => {
    const onClose = () => {
        console.log('');
    };

    return (
        <div className='calendarRequest'>
            <div className={`calendarRequest__main`}>
                <div className='calendarRequest__main__menu subsubtitle'>
                    <CloseOutlined
                        className='calendarRequest__main__menu__close'
                        onClick={onClose}
                        rev={''}
                    />
                    <hr />
                </div>
                <div className='calendarRequest__main__header title'>
                    <div className='calendarRequest__main__header__title'>
                        <div className='calendarRequest__main__header__title__circle' />
                        <div className='calendarRequest__main__header__title__text'>
                            Crear solicitud de retiro por gestor
                        </div>
                    </div>
                </div>
                <div className='calendarRequest__main__text subtitle'>
                    <CalendarRequestCreateComponent />
                </div>
            </div>
        </div>
    );
};

export default CalendarRequest;
