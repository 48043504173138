export interface GestorUserInterface {
    id: string;
    name: string;
    lastName: string;
    email: string;
    rut: string;
    phone: string;
    role: string;
    state: number;
    createdAt: string;
    updatedAt: string;
}

export interface GestorUserFormCreateInterface {
    name: string;
    lastName: string;
    email: string;
    rut: string;
    phone: string;
    role: string;
}

export const GestorUserFormValues: GestorUserFormCreateInterface = {
    name: '',
    lastName: '',
    email: '',
    rut: '',
    phone: '',
    role: ''
};
