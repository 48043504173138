import { PropertyUnitInterface } from './property-unit.interface';
import { PropertyInterface } from './property.interface';
import { SupplyRequirementAppendixInterface } from './supply-requirement-appendix.interface';
import { SupplyStockInterface } from './supply-stock.interface';
import { SupplyInterface } from './supply.interface';

export interface SupplyRequirementInterface {
    id: string;
    name: string;
    deliveryPhase: string;
    area: string;
    tipology: string;
    minStock: number;
    price: number;
    nameIndirect: string;
    stockControl: boolean;
    property: PropertyInterface;
    propertyUnit: PropertyUnitInterface;
    supply: SupplyInterface;
    supplyStock: SupplyStockInterface;
    supplyRequirementAppendixInvisible: SupplyRequirementAppendixInterface;
    state: number;
    createdAt: string;
    updatedAt: string;
}

export interface SupplyRequirementFormCreateInterface {
    name: string;
    deliveryPhase: string;
    area: string;
    tipology: string;
    minStock: number;
    price: number;
    nameIndirect: string;
    stockControl: number;
    property: string;
    propertyUnit: string | null;
    supply: string;
    /*  supplyRequirementAppendixInvisible: string; */
}

export const SupplyRequirementFormValues: SupplyRequirementFormCreateInterface =
    {
        name: '',
        deliveryPhase: '',
        area: '',
        tipology: '',
        minStock: 0,
        price: 0,
        nameIndirect: '',
        stockControl: 0,
        property: '',
        propertyUnit: null,
        supply: ''
        /* supplyRequirementAppendixInvisible: '', */
    };
