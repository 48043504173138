import '../../../../settings.scss';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
//  <--COMPONENTS--> //
import SubNavBar from '../../../../../../components/layout/main/subnavbar/subnavbar';
import Header from '../../../../../../components/header/header';
import RequirementsTableComponent from '../requirements-table';
import FormBuilder from '../../../../../../components/formBuilder/formBuilder';
import ButtonBody from '../../../../../../components/button/button';
//  <--HOOKS--> //
import { useToast } from '../../../../../../config/hooks/useToast';
import { useAlert } from '../../../../../../config/hooks/useAlert';
import { useCallApi } from '../../../../../../config/hooks/useCallApi';
import { HTTP_METHODS } from '../../../../../../config/hooks/useCallApi/constants';
//  <--INTERFACE--> //
import {
    RequirementFormCreateInterface,
    RequirementFormValues,
    /* FontInterface, */
    MaterialInterface,
    GestorInterface,
    FontInterface,
    PropertyUnitInterface
} from '../../../../../../interface';
//  <--REDUX--> //
import { UserStoreInterface } from '../../../../../../store/slices/users/user.interface';
import { PropertyStoreInterface } from '../../../../../../store/slices/property/property.interface';
import { useAppSelector } from '../../../../../../store/hooks';
//  <--SERVICES--> //
import {
    ApiRequirement,
    ApiRequirementId,
    ApiPropertyId,
    ApiMaterial,
    ApiGestor,
    ApiFontId
} from '../../../../../../config/service';
//  <--OTHERS--> //
import { lang } from '../../../../../langs';
import { FormCreateRequirement } from './form';
import '../../../../../../scss/global/global.scss';

const RequirementsCreate = () => {
    const { GetData, LoadingData, LoaderElement } = useCallApi();
    const {
        ConfigToast,
        setConfigToast,
        ToastElement,
        toastManagerRef,
        Colors
    } = useToast();
    const { preferences }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const { propertyData }: PropertyStoreInterface = useAppSelector(
        state => state.property
    );
    const defaultLang: string = preferences.lang;
    const {
        AlertElement,
        alertManagerRef,
        ConfigAlert,
        setConfigAlert,
        Defaults
    } = useAlert({ defaultLang });
    const fieldRef = useRef(null);
    const { id, requirementId } = useParams();
    const {
        [defaultLang as keyof typeof lang]: {
            pageData: {
                title,
                option1,
                option2,
                requirement: {
                    createTitle,
                    editTitle,
                    detailTitle,
                    deleteMessage,
                    deleteTitle,
                    newTitle
                }
            }
        }
    } = lang;
    const navigate = useNavigate();
    const [entityId, setEntityId] = useState<string>('');
    const [entity, setEntity] = useState<RequirementFormCreateInterface>();
    const [entityFontId, setEntityFontId] = useState<string>('');
    const [entityFont, setEntityFont] = useState<FontInterface>();
    const [readyForm, setReadyForm] = useState<boolean>(false);
    const [detailPage, setDetailPage] = useState<boolean>(true);
    const [updates, setUpdates] = useState<boolean>(false);
    const [showFont, setShowFont] = useState(true);
    const [dataList, setDataList] = useState<MaterialInterface[]>([]);
    const [dataList2, setDataList2] = useState<PropertyUnitInterface[]>([]);
    const [dataList3, setDataList3] = useState<GestorInterface[]>([]);

    /**
     * @description CallDataFont function, call the api to get the data of the font and set the data in state to show title
     */
    const CallDataFont = async () => {
        const response = await GetData(
            ApiFontId(entityFontId),
            HTTP_METHODS.GET
        );
        const { data } = response;
        setEntityFont(data);
        RequirementFormValues.dataFont = data.id;
        RequirementFormValues.property = data.property?.id;
        RequirementFormValues.propertyUnit = data.propertyUnit?.id;
        RequirementFormValues.material = data.material?.id;
        RequirementFormValues.gestor = data.gestor?.id;
        const columnsArray = JSON.parse(data.columns);
        if (
            data.propertyUnit ||
            columnsArray?.some(
                column => column.col === 0 && column.dat === 'property_unit'
            )
        ) {
            FormCreateRequirement[8].editable = false;
        }
        if (
            data.gestor ||
            columnsArray.some(
                column => column.col === 0 && column.dat === 'gestor'
            )
        ) {
            FormCreateRequirement[9].editable = false;
        }
    };

    /**
     * @description
     * @function SetData is a function that sets data lists to show in form as options
     */
    const SetData = async () => {
        setReadyForm(false);
        const response = await GetData(ApiMaterial, HTTP_METHODS.GET);
        setDataList(
            [...response.data].sort((a, b) => a.name.localeCompare(b.name))
        );
        const response2 = await GetData(ApiGestor, HTTP_METHODS.GET);
        setDataList3(
            [...response2.data].sort((a, b) => a.name.localeCompare(b.name))
        );

        if (entityFont?.property) {
            const response2 = await GetData(
                ApiPropertyId(entityFont.property.id),
                HTTP_METHODS.GET
            );
            const { data, status, message } = response2;
            if (status) {
                setDataList2(
                    [...data.propertyUnits].sort((a, b) =>
                        a.name.localeCompare(b.name)
                    )
                );
                setReadyForm(true);
            } else {
                setDataList2([]);
                ConfigToast.text = message;
                ConfigToast.backColor = Colors.Error;
                ConfigToast.title = 'Error';
                setConfigToast(ConfigToast);
            }
        } else {
            setReadyForm(true);
        }
    };

    /**
     * @description CallDataEdit function, call the api to get the data of the entity and set the data in state
     */
    const CallDataEdit = async () => {
        const response = await GetData(
            ApiRequirementId(entityId),
            HTTP_METHODS.GET
        );
        const { data, status } = response;
        const binaryRepresentation = data.alarmType.toString(2);
        const reversedBinary = binaryRepresentation.split('').reverse();
        const exponents: any = [];
        reversedBinary.forEach((bit, index) => {
            if (bit === '1') {
                exponents.push(index.toString());
            }
        });
        const newData = {
            ...data,
            dataFont: data.dataFont ? data.dataFont.id : null,
            gestor: data.gestor ? data.gestor.id : null,
            material: data.material ? data.material.id : null,
            property: data.property ? data.property.id : null,
            propertyUnit: data.propertyUnit ? data.propertyUnit.id : null,
            alarmType: exponents,
            unitMeasurement: data.unitMeasurement ? data.unitMeasurement : null
        };
        setEntity(newData);
        setReadyForm(true);
        if (!status) {
            ConfigToast.text = data.message;
            ConfigToast.backColor = Colors.Error;
            ConfigToast.title = 'Error';
            setConfigToast(ConfigToast);
        }
    };

    /**
     * @description
     * @function OnSubmit is a function that is called when the form is submitted
     * @param values is a object that contains the data of the form
     */
    const OnSubmit = async (values: RequirementFormCreateInterface) => {
        const alarmType = values.alarmType;
        const sum = alarmType.reduce((accumulator, type) => {
            return accumulator + Math.pow(2, type);
        }, 0);
        const newValues = {
            ...values,
            alarmType: sum
        };

        const response = requirementId
            ? await GetData(
                  ApiRequirementId(entityId),
                  HTTP_METHODS.PATCH,
                  newValues
              )
            : await GetData(ApiRequirement, HTTP_METHODS.POST, newValues);
        const { message, status } = response;
        if (status) {
            ConfigToast.text = message;
            ConfigToast.backColor = Colors.Success;
            setConfigToast(ConfigToast);
            setReadyForm(false);
            CallDataEdit();
            setUpdates(true);
        } else {
            ConfigToast.text = message;
            ConfigToast.backColor = Colors.Error;
            ConfigToast.title = 'Error';
            setConfigToast(ConfigToast);
        }
    };

    /**
     * @description
     * @function FocusOnInput is a function that is called when the form's cancel button is clicked
     * @param ref is a reference to the form
     */
    const FocusOnInput = (ref: any) => {
        ref.current.focus();
    };

    /**
     * @description
     * @constant FormButtons is a object that contains the buttons of the form
     */
    const FormButtons = {
        ok: {
            controller: OnSubmit
        },
        cancel: {
            controller: FocusOnInput
        }
    };

    const Edit = () => {
        setDetailPage(false);
    };

    /**
     * @description function that is executed when the delete button is pressed
     * @function Delete is a function that launches an alert to confirm the deletion of a COMPANY
     * @param requirementId is a string that contains the id of the COMPANY to be deleted
     */
    const Delete = async () => {
        ConfigAlert.title = Defaults.titles.Delete;
        ConfigAlert.message = deleteMessage;
        ConfigAlert.okButtonText = Defaults.buttons.Ok.Accept;
        ConfigAlert.okButtonController = {
            func: ConfirmDelete,
            param: requirementId
        };
        ConfigAlert.cancelButtonText = Defaults.buttons.Cancel.Cancel;
        setConfigAlert(ConfigAlert);
    };

    /**
     * @description function that is executed when the okDelete button is pressed
     * @function Delete is a function that deletes a COMPANY
     * @param requirementId is a string that contains the id of the COMPANY to be deleted
     */
    const ConfirmDelete = async (requirementId: string) => {
        const response = await GetData(
            ApiRequirementId(requirementId),
            HTTP_METHODS.DELETE
        );
        const { data, status, message } = response;
        if (status) {
            ConfigToast.text = message;
            ConfigToast.backColor = Colors.Success;
            setConfigToast(ConfigToast);
            navigate(`/panel/data/font/${id}/requirements`);
        } else {
            ConfigToast.text = data.message;
            ConfigToast.backColor = Colors.Error;
            ConfigToast.title = 'Error';
            setConfigToast(ConfigToast);
        }
    };

    /**
     * @description this effect verify if exist id in url params, if exist, set the id in state
     */
    useEffect(() => {
        if (id) {
            setEntityFontId(id);
        }
    }, [id]);

    /**
     * @description this effect check that entityFontId is not empty and if it changes, if not empty, call the function to CallDataFont
     */
    useEffect(() => {
        if (entityFontId !== '') {
            CallDataFont();
        }
    }, [entityFontId]);

    /**
     * @description
     * Function that is executed when the component is mounted, calls the function that returns the datalists for the forms
     */
    useEffect(() => {
        SetData();
    }, [entityFont]);

    useEffect(() => {
        FormCreateRequirement[8].elements = dataList2;
    }, [dataList2]);

    useEffect(() => {
        FormCreateRequirement[9].elements = dataList3;
    }, [dataList3]);

    useEffect(() => {
        FormCreateRequirement[3].elements = dataList;
    }, [dataList]);

    useEffect(() => {
        FormCreateRequirement[7].elements = [...propertyData].sort((a, b) =>
            a.name.localeCompare(b.name)
        );
    }, [propertyData]);

    /* [
                ...propertyData
            ].sort((a, b) => a.name.localeCompare(b.name)) */
    /**
     * @description this effect verify if exist requirementId in url params, if exist, set the requirementId in state, else set readyForm to true
     */
    useEffect(() => {
        setReadyForm(false);
        if (requirementId) {
            setEntityId(requirementId);
        } else {
            setEntity(RequirementFormValues);
            setReadyForm(false);
            setTimeout(() => {
                setReadyForm(true);
            }, 50);
        }
    }, [requirementId]);

    /**
     * @description this effect check that entityId is not empty and if it changes, if not empty, call the function to CallDataEdit
     */
    useEffect(() => {
        if (entityId !== '') {
            CallDataEdit();
            setDetailPage(true);
        }
    }, [entityId]);

    useEffect(() => {
        setUpdates(false);
    }, [entity]);

    return (
        <>
            <AlertElement ref={alertManagerRef} />
            <ToastElement ref={toastManagerRef} />
            <SubNavBar
                type='settings'
                title={title}
                icon='settings/DataIcon.svg'
                kind='settings'
                option='Data'
            ></SubNavBar>
            <div className='settings'>
                <Header
                    title={{ name: title, link: '/panel/data/font' }}
                    icon='DataIconB.svg'
                    subtitle={{
                        name: entityFont?.name ?? '',
                        link: `/panel/data/font/edit/${id}`
                    }}
                    subsubtitle={{
                        name: 'Requerimientos',
                        link: `/panel/data/font/${id}/requirements`
                    }}
                    subsubsubtitle={{
                        name:
                            entity?.name && entity.name.length > 0
                                ? entity.name
                                : createTitle
                    }}
                />
                <div className='settings__grid-container'>
                    <div className='settings__grid-container__item'>
                        <div className='settings__grid-container__item__title subtitle'>
                            <Link
                                className={`settings__grid-container__item__title__section2__item left ${
                                    !showFont ? 'active' : 'not-active'
                                }`}
                                onClick={() => setShowFont(false)}
                                to='/panel/data'
                            >
                                {option1}
                            </Link>
                            <Link
                                className={`settings__grid-container__item__title__section2__item right ${
                                    showFont ? 'active' : 'not-active'
                                }`}
                                onClick={() => setShowFont(true)}
                                to='/panel/data/font'
                            >
                                {option2}
                            </Link>
                        </div>
                        <RequirementsTableComponent refresh={updates} />
                        <div className='settings__grid-container__item__content'>
                            <ButtonBody
                                able='true'
                                kind='button button-body--create'
                                text={newTitle}
                                path={`/panel/data/font/${id}/requirements/create`}
                            ></ButtonBody>
                        </div>
                    </div>
                    <div className='settings__grid-container__item_right'>
                        <div className='settings__grid-container__item_right__title subtitle'>
                            {requirementId ? (
                                <div>
                                    {detailPage ? detailTitle : editTitle}
                                </div>
                            ) : (
                                <div>{createTitle}</div>
                            )}
                        </div>
                        <div className='settings__grid-container__item_right__content'>
                            <>
                                {readyForm && (
                                    <>
                                        <FormBuilder
                                            initialValues={
                                                entity?.name
                                                    ? entity
                                                    : RequirementFormValues
                                            }
                                            buttons={FormButtons}
                                            lang={defaultLang}
                                            formStructure={
                                                FormCreateRequirement
                                            }
                                            focusOn={fieldRef}
                                            config={{
                                                noClearButton: !!entity?.name,
                                                editButton:
                                                    !!entity?.name && detailPage
                                            }}
                                        />
                                    </>
                                )}
                            </>
                            {requirementId && detailPage && (
                                <div className='settings__grid-container__item_right__content__btn'>
                                    <button
                                        className='settings__grid-container__item_right__content__btn__delete-button item-btn subtitle'
                                        onClick={Delete}
                                    >
                                        {deleteTitle}
                                    </button>
                                    <button
                                        className='settings__grid-container__item_right__content__btn__edit-button item-btn subtitle'
                                        onClick={Edit}
                                    >
                                        {editTitle}
                                    </button>
                                </div>
                            )}
                        </div>
                        {LoadingData && <LoaderElement />}
                    </div>
                </div>
            </div>
        </>
    );
};

export default RequirementsCreate;
