import { useEffect, useState } from 'react';
import './qr-item.scss';
import { useNavigate, useParams } from 'react-router';
//  <--COMPONENTS--> //
import FileViewer from 'react-file-viewer';
import PdfViewer from '../../../components/pdfViewer/pdfViewer';
//  <--HOOKS--> //
import { ApiQrLinkByIdentity } from '../../../config/service';
import { useCallApi } from '../../../config/hooks/useCallApi';
import { HTTP_METHODS } from '../../../config/hooks/useCallApi/constants';

const QRItem = () => {
    const { GetData } = useCallApi();
    const { identity } = useParams();
    const navigate = useNavigate();
    const [qrData, setQrData] = useState<{
        fileType?: string;
        destination?: string;
    }>({});

    useEffect(() => {
        const showFile = async data => {
            data.fileType = data.destination.split('.').pop();
            setQrData(data);
        };

        const redirectExternal = (data: { destination: string }) => {
            if (data.destination === 'https://') {
                return null;
            } else if (data.destination.startsWith('https://')) {
                window.location.href = data.destination;
            } else {
                window.location.href = 'https://' + data.destination;
            }
            return null;
        };

        const redirectInternal = data => {
            navigate(data.destination);
        };

        const CallMainData = async () => {
            if (identity !== undefined) {
                const { data, status } = await GetData(
                    ApiQrLinkByIdentity(identity),
                    HTTP_METHODS.GET
                );

                if (status) {
                    if (data.type === 'external-url') {
                        redirectExternal(data);
                    } else if (data.type === 'internal-url') {
                        redirectInternal(data);
                    } else if (data.type) {
                        await showFile(data);
                    }
                } else {
                    navigate('/404');
                }
            }
        };

        CallMainData();
    }, []);

    return (
        <div className='qr-item'>
            <div className='qr-item__content'>
                {qrData?.fileType &&
                    (qrData.fileType === 'pdf' ? (
                        <PdfViewer file={qrData.destination} />
                    ) : (
                        <div className='qr-item__content__data'>
                            <FileViewer
                                fileType={qrData.fileType}
                                filePath={qrData.destination}
                            />
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default QRItem;
