import './details.scss';
import { ReactNode, useEffect, useState } from 'react';
import { Drawer } from 'antd';
interface Props {
    showSection: boolean;
    closeSection?: (isShow: boolean) => void;
    title?: string;
    children?: ReactNode;
    place?: any;
}

const DetailsComponent = ({
    showSection,
    closeSection,
    children,
    title,
    place
}: Props) => {
    const [show, setShow] = useState(false);

    useEffect(() => {
        setShow(showSection);
    }, [showSection]);

    const handleClose = () => {
        setShow(false);
        closeSection?.(false);
    };

    return (
        <div className='details'>
            <Drawer
                placement={place ?? 'right'}
                closable={false}
                onClose={handleClose}
                open={show}
                className='details'
            >
                {children}
            </Drawer>
        </div>
    );
};

export default DetailsComponent;
