import './404.scss';
//  <--COMPONENTS--> //
import { lang } from '../langs';
import { Link } from 'react-router-dom';
import LogoComponent from '../../components/logo/logo';
import ButtonBody from '../../components/button/button';
//  <--REDUX--> //
import { UserStoreInterface } from '../../store/slices/users/user.interface';
import { useAppSelector } from '../../store/hooks';

const Page404 = () => {
    const { preferences }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const defaultLang: string = preferences.lang;
    const {
        [defaultLang as keyof typeof lang]: {
            page404: { title, description, detail, button }
        }
    } = lang;

    return (
        <div className='not-found'>
            <div className='not-found__content'>
                <div className='not-found__content__logo'>
                    <Link to='/panel'>
                        <LogoComponent
                            type='header'
                            image='PRYMA_LOGO_BLANCO.png'
                        />
                    </Link>
                </div>
                <div className='not-found__content__title bigtitle'>
                    {title}
                </div>
                <div className='not-found__content__subtitle title'>
                    {description}
                </div>
                <div className='not-found__content__subtitle title'>
                    {detail}
                </div>
                <div className='not-found__content__button'>
                    <ButtonBody
                        able='true'
                        kind='button button-body--page404'
                        text={button}
                        path='/panel'
                    ></ButtonBody>
                </div>
            </div>
        </div>
    );
};

export default Page404;
