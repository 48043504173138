import { FormBuilderFields } from '../../formBuilder/form-builder.interface';

export const FormCreateCalendarRequest: FormBuilderFields[] = [
    {
        label: 'Inmueble',
        fieldNameId: 'property',
        fieldType: 'select',
        elements: [],
        fieldPlaceholder: 'Seleccione un inmueble',
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            }
        }
    },
    {
        label: 'Tipo de material',
        fieldNameId: 'materialKind',
        fieldType: 'select',
        elements: [],
        fieldPlaceholder: 'Seleccione un tipo de material',
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            }
        }
    },
    {
        label: 'Gestor',
        fieldNameId: 'gestor',
        fieldType: 'select',
        elements: [],
        fieldPlaceholder: 'Seleccione un rol',
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            }
        }
    }
];
