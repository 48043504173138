import DetailsComponent from '../../../components/details/details';
import { MaterialInterface, MaterialKindInterface } from '../../../interface';
import MaterialCreateComponent from '../material/create/create-edit-component';
import { useEffect, useState } from 'react';
import { Container } from '../../../components/styles';

interface Props {
    showDetails: boolean;
    setShowDetails: any;
    detailsTitle: string;
    selectedMaterialKind: MaterialKindInterface | undefined;
    SuccessSaveMaterial: any;
}

const MKD = ({
    showDetails,
    setShowDetails,
    detailsTitle,
    selectedMaterialKind,
    SuccessSaveMaterial
}: Props) => {
    const [selectedMk, setSelectedMk] = useState<MaterialKindInterface>();
    const [materialList, setMaterialList] = useState<
        MaterialInterface[] | undefined
    >([]);
    const [readyForm, setReadyForm] = useState<boolean>(false);

    useEffect(() => {
        if (selectedMaterialKind) {
            setSelectedMk(selectedMaterialKind);
            if (selectedMaterialKind[0]) {
                setMaterialList(selectedMaterialKind[0].materials);
            } else {
                setMaterialList([]);
            }

            setReadyForm(true);
        }
    }, [selectedMaterialKind]);

    return (
        <DetailsComponent
            showSection={showDetails}
            closeSection={setShowDetails}
            title={detailsTitle}
        >
            <Container>
                {materialList?.map((material: MaterialInterface) => {
                    return (
                        <ol key={material.id}>
                            <li className='d-flex justify-content-between align-items-start'>
                                <div className='ms-2 me-auto'>
                                    <div>{material.name}</div>
                                </div>
                            </li>
                        </ol>
                    );
                })}
            </Container>
            {readyForm && (
                <MaterialCreateComponent
                    materialKind={selectedMk}
                    onSuccessSave={SuccessSaveMaterial}
                />
            )}
        </DetailsComponent>
    );
};

export default MKD;
