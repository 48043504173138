import { DataCenterChartsInterface, MaterialInterface } from '../../interface';
import { Globals } from './Constants';

const labelsLength = Globals.ChartCountMilestones;
const flexFilters = ['kindId', 'materialId'];

interface Props {
    data: DataCenterChartsInterface[];
    labels: string[];
    chart: 'bar' | 'line' | 'apiled';
    milestones: string;
    dataSetList: MaterialInterface[];
    dataSetList2?: boolean;
    filter?: any;
    apiledLine?: boolean;
    apiledLineData?: any;
    apiledLineOnFilter?: string[];
    colorsDB?: any;
}

export const FormatData = ({
    data,
    labels,
    chart,
    dataSetList,
    dataSetList2,
    filter,
    milestones,
    apiledLine,
    apiledLineData,
    apiledLineOnFilter,
    colorsDB
}: Props) => {
    if (!data || data.length === 0) {
        // Devolver un objeto vacío o lo que consideres apropiado para tu caso
        return {
            labels: [],
            datasets: []
        };
    }

    if (chart === 'apiled') {
        return ApiledFormat({
            data,
            labels,
            chart,
            dataSetList,
            dataSetList2,
            filter,
            milestones,
            apiledLine,
            apiledLineData,
            apiledLineOnFilter,
            colorsDB
        });
    } else {
        return ApiledFormat({
            data,
            labels,
            chart,
            dataSetList,
            dataSetList2,
            filter,
            milestones,
            colorsDB
        });
    }
};

const ApiledFormat = ({
    data,
    labels,
    chart,
    dataSetList,
    dataSetList2,
    filter,
    milestones,
    apiledLine,
    apiledLineData,
    apiledLineOnFilter,
    colorsDB
}: Props) => {
    const {
        filters: { keys, valuesProperty }
    } = filter;
    /* ASIGNA LOS MATERIALES A LA VARIABLE UNIQUEDS */
    let uniqueDS = dataSetList;
    const existDs = flexFilters.filter(element => keys.includes(element));
    if (existDs.length > 0) {
        const indexValueFilter = keys.indexOf(existDs[0]);
        const idSelectFilter = valuesProperty[indexValueFilter];
        const filterDs = uniqueDS.filter(item => item.id === idSelectFilter);
        uniqueDS = filterDs;
    }
    let finalLabels: string[] = [];
    finalLabels = labels;

    let finalData;
    if (keys.length > 1) {
        const calculation: string[] = [];
        finalLabels.forEach((item, index) => {
            const selected = data
                .filter(obj => obj.date === item)
                .map(filteredObj => filteredObj.total);
            calculation.push(selected[0]);
        });

        let preConfig = {};

        if (chart === 'line') {
            preConfig = {
                pointStyle: 'circle',
                pointRadius: 2,
                pointHoverRadius: 2
            };
        }

        const dynamicData: any = [];
        uniqueDS.forEach((item, index) => {
            let Obj = {
                label: item.name,
                data: calculation,
                backgroundColor: colorsDB[item.name] || 'rgb(162 162 163)',
                borderWidth: 0
            };
            if (chart === 'line') {
                Obj = {
                    ...Obj,
                    ...preConfig
                };
            }
            dynamicData.push(Obj);
        });
        finalData = {
            labels: finalLabels,
            datasets: dynamicData
        };
    } else {
        const dynamicData: any = [];
        let preConfig = {};

        if (chart === 'line') {
            preConfig = {
                pointStyle: 'circle',
                pointRadius: 2,
                pointHoverRadius: 2
            };
        }
        /* RECORRE LOS TIPOS DE MATERIALES */
        // eslint-disable-next-line array-callback-return
        uniqueDS.forEach((e, i) => {
            // eslint-disable-next-line array-callback-return
            const arrDS: string[] = [];
            /* RECORRE EL LISTADO DE FECHAS */
            // eslint-disable-next-line array-callback-return
            finalLabels.forEach(obj => {
                /* OBTIENE Y FILTRA DATOS QUE COINCIDAN CON LAS FECHAS Y EL TIPO DE MATERIAL */
                const fres = data
                    .filter(
                        item => item.date === obj && item.materialId === e.id
                    )
                    .map(filteredObj => filteredObj.total);

                /* ASIGNA LOS DATOS AGRUPADOS POR FECHA Y TIPO DE MATERIAL, LO SUMA Y GUARDA EN UN ARRAY PARA SER MOSTRADO EN EL GRÁFICO */
                if (fres.length > 0) {
                    let sumArray = 0;
                    fres.forEach(number => {
                        sumArray += parseFloat(number);
                    });
                    arrDS.push(sumArray.toString());
                } else {
                    arrDS.push('0');
                }
                if (dataSetList2) {
                    const fresmaterials = data
                        .filter(
                            item => item.date === obj && item.kindId === e.id
                        )
                        .map(filteredObj => filteredObj.total);
                    if (fresmaterials.length > 0) {
                        let sumArray = 0;
                        fresmaterials.forEach(number => {
                            sumArray += parseFloat(number);
                        });
                        arrDS.push(sumArray.toString());
                    } else {
                        arrDS.push('0');
                    }
                }
            });
            /* CREAR UN OBJETO CON LOS DATOS DEL ARRAY PARA MOSTRAR LOS NÚMEROS, ADEMÁS ASIGNA PARÁMETROS PARA EL GRÁFICO */
            let Obj = {
                id: e.id,
                kind: e.kind?.id ?? '',
                label: e.name,
                data: arrDS,
                backgroundColor: colorsDB[e.name] || 'rgb(162 162 163)',
                borderColor: colorsDB[e.name] || 'rgb(162 162 163)',
                borderWidth: 0
            };
            if (chart === 'line') {
                Obj = {
                    ...Obj,
                    ...preConfig
                };
            }
            dynamicData.push(Obj);
        });
        /* AGREGA A LÍNEA DE LA DATA PARA EL ÍNDICE BZERO */
        if (apiledLine && chart === 'apiled') {
            if (apiledLineOnFilter) {
                const existApiledLineOnFilter = keys.filter(element =>
                    apiledLineOnFilter.includes(element)
                );
                if (existApiledLineOnFilter.length > 0) {
                    const addObjApiledLine = {
                        ...apiledLineData,
                        backgroundColor: 'rgb(3 218 197)',
                        borderColor: 'rgb(3 218 197)',
                        borderWidth: 1,
                        type: 'line',
                        yAxisID: 'y1',
                        id: 'Índice BZero',
                        kind: '0'
                    };
                    dynamicData.push(addObjApiledLine);
                }
            }
        }
        finalData = {
            labels: finalLabels,
            datasets: dynamicData
        };
    }
    return finalData;
};

export const FormatLabels = (
    data: string[], // Ahora 'data' es un array de strings 'yyyymm'
    redefine: boolean = false
) => {
    if (!redefine) {
        // Retorna solo los últimos elementos según labelsLength
        return data?.slice(data.length - labelsLength, data.length);
    } else {
        // Si redefine es true, retorna el array completo
        return data;
    }
};

export const ObtainDates = (range: number) => {
    const currentDate = new Date();
    const currentDay = currentDate.getDate();
    const currentMonth = currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear();

    let monthsToSubtract = 0;
    if (currentDay <= 13) {
        monthsToSubtract = 5;
    } else {
        monthsToSubtract = 4;
    }

    const startDate = new Date(currentYear, currentMonth - monthsToSubtract, 1);
    const dateArray: any = [];

    for (let i = 0; i < range; i++) {
        const year = startDate.getFullYear();
        const month = String(startDate.getMonth() + 1).padStart(2, '0');
        const formattedDate = String(year) + month;
        dateArray.push(formattedDate);
        startDate.setMonth(startDate.getMonth() + 1);
    }

    return dateArray;
};

export const FormatBZeroIndexData = data => {
    const datesFilter = ObtainDates(3);
    const dataFilteredDate = data.filter(
        item =>
            item.date === datesFilter[0] ||
            item.date === datesFilter[1] ||
            item.date === datesFilter[2]
    );
    const groupedData = dataFilteredDate.reduce((result, item) => {
        const { propertyId, total, date, propertyUnit } = item;
        if (propertyId !== null) {
            const existingItem = result.find(
                obj => obj.propertyId === propertyId && obj.date === date
            );
            if (!existingItem) {
                result.push({
                    date,
                    propertyId,
                    total: 0,
                    totalTrash: 0,
                    bzeroindex: 0
                });
            }
            const currentItem = result.find(
                obj => obj.propertyId === propertyId && obj.date === date
            );
            currentItem.total =
                parseFloat(currentItem.total) + parseFloat(total);
            if (item.kindId === 'a4688f23-9e63-af00-4ef9-b71a757710c0') {
                currentItem.totalTrash =
                    parseFloat(currentItem.totalTrash) + parseFloat(total);
            }
            currentItem.bzeroindex = (
                (currentItem.totalTrash / currentItem.total) *
                100
            ).toFixed(1);
        } else if (propertyUnit !== null) {
            const existingItem = result.find(
                obj => obj.propertyId === propertyUnit && obj.date === date
            );
            if (!existingItem) {
                result.push({
                    date,
                    propertyId: propertyUnit,
                    total: 0,
                    totalTrash: 0,
                    bzeroindex: 0
                });
            }
            const currentItem = result.find(
                obj => obj.propertyId === propertyUnit && obj.date === date
            );
            currentItem.total =
                parseFloat(currentItem.total) + parseFloat(total);
            if (item.kindId === 'a4688f23-9e63-af00-4ef9-b71a757710c0') {
                currentItem.totalTrash =
                    parseFloat(currentItem.totalTrash) + parseFloat(total);
            }
            currentItem.bzeroindex = (
                (currentItem.totalTrash / currentItem.total) *
                100
            ).toFixed(1);
        }
        return result;
    }, []);

    const dataReal = groupedData.filter(item => item.totalTrash !== 0);

    const aggregatedData = dataReal.reduce((result, item) => {
        const { propertyId, propertyUnit, total, totalTrash } = item;
        const existingItemIndex = result.findIndex(
            obj => obj.propertyId === propertyId
        );
        if (existingItemIndex === -1) {
            // Si no existe un elemento con el mismo propertyId, lo agregamos al resultado
            result.push({
                propertyId,
                propertyUnit,
                total,
                totalTrash
            });
        } else {
            // Si ya existe un elemento con el mismo propertyId, sumamos los valores
            result[existingItemIndex].total =
                parseFloat(result[existingItemIndex].total) + parseFloat(total);
            result[existingItemIndex].totalTrash =
                parseFloat(result[existingItemIndex].totalTrash) +
                parseFloat(totalTrash);
        }
        return result;
    }, []);

    // Calculamos bzeroindex para cada elemento del nuevo arreglo
    aggregatedData.forEach(item => {
        item.bzeroindex = (item.totalTrash / item.total) * 100;
    });

    aggregatedData.sort((a, b) => a.bzeroindex - b.bzeroindex);

    let finalTotal = 0;
    let finalTotalTrash = 0;

    // Recorremos el arreglo y sumamos los valores
    aggregatedData.forEach(item => {
        finalTotal += parseInt(item.total);
        finalTotalTrash += parseInt(item.totalTrash);
    });

    // Calculamos finalBzeroindex dividiendo finalTotal entre finalTotalTrash
    const finalBzeroindex = (finalTotalTrash / finalTotal) * 100;
    const finalBzeroindexRounded = parseFloat(finalBzeroindex.toFixed(2));

    // Creamos el objeto con los atributos finales
    const finalObj = {
        finalTotal,
        finalTotalTrash,
        finalBzeroindexRounded
    };

    return finalObj.finalBzeroindexRounded;
};

export const FormatEcoequivalencesData = datasets => {
    const array: any = [];
    datasets.forEach(item => {
        const data = {
            id: item.id,
            label: item.label,
            total: item.data
                .reduce(
                    (accumulator, currentValue) =>
                        parseFloat(accumulator) + parseFloat(currentValue),
                    0
                )
                .toFixed(0),
            color: item.backgroundColor
        };
        array.push(data);
    });
    const paper = array.find(
        obj => obj.id === '0ea9b622-6e03-8b90-982b-e6dbb64aab5f'
    );
    const carton = array.find(
        obj => obj.id === '68a12215-2298-051d-7324-c36b750821f4'
    );
    const aluminum = array.find(
        obj => obj.id === '00a9ecb5-1383-bcbc-b9bd-4e3948035a36'
    );
    const iron = array.find(
        obj => obj.id === '67e2744f-e62c-1717-350e-a4a67d8b0a84'
    );
    const plastic = array.find(
        obj => obj.id === '1cc267d0-bc88-10db-b3cd-ae3c57ede122'
    );
    const tetrapack = array.find(
        obj => obj.id === '49a844bf-8db8-4c01-db62-9f58ce4f164c'
    );
    const glass = array.find(
        obj => obj.id === '376e5241-b139-6592-2a21-dd7d4e9e6018'
    );
    const organic = array.find(
        obj => obj.id === '8d1c7369-111a-1050-68db-c54405f06de7'
    );
    const electronic = array.find(
        obj => obj.id === '5b281dc3-85f3-a7a5-e320-0723859965c9'
    );
    const newspaper = array.find(
        obj => obj.id === '5df2b576-ee52-ae36-0309-c7b32a05431e'
    );
    const magazines = array.find(
        obj => obj.id === '360ce3ce-70db-7857-8110-5a08516ff1ed'
    );
    const PERigid = array.find(
        obj => obj.id === 'b156bcb8-c8ba-aba4-6457-b2156947b30c'
    );
    const PET = array.find(
        obj => obj.id === '9e24923b-3925-260c-3d10-c1fbb6b0e6dc'
    );
    const PPRigid = array.find(
        obj => obj.id === '79f97056-579e-743b-3320-d832a092d0a2'
    );

    const paperTotal = paper ? parseFloat(paper.total) : 0;
    const cartonTotal = carton ? parseFloat(carton.total) : 0;
    const aluminumTotal = aluminum ? parseFloat(aluminum.total) : 0;
    const ironTotal = iron ? parseFloat(iron.total) : 0;
    const plasticTotal = plastic ? parseFloat(plastic.total) : 0;
    const tetrapackTotal = tetrapack ? parseFloat(tetrapack.total) : 0;
    const glassTotal = glass ? parseFloat(glass.total) : 0;
    const organicTotal = organic ? parseFloat(organic.total) : 0;
    const electronicTotal = electronic ? parseFloat(electronic.total) : 0;
    const newspaperTotal = newspaper ? parseFloat(newspaper.total) : 0;
    const magazinesTotal = magazines ? parseFloat(magazines.total) : 0;
    const PERigidTotal = PERigid ? parseFloat(PERigid.total) : 0;
    const PETTotal = PET ? parseFloat(PET.total) : 0;
    const PPRigidTotal = PPRigid ? parseFloat(PPRigid.total) : 0;

    const totalCO2kg =
        (0.0006758 * organicTotal +
            0.0011161 * electronicTotal +
            0.0100835 * aluminumTotal +
            0.0020418 * ironTotal +
            0.0003267 * glassTotal +
            0.0039906 * paperTotal +
            0.0033121 * tetrapackTotal +
            0.0020526 * newspaperTotal +
            0.0029132 * magazinesTotal +
            0.0008584 * PERigidTotal +
            0.001164 * PETTotal +
            0.0008973 * PPRigidTotal +
            0.0036563 * cartonTotal) *
        1000;

    const totalWater =
        (paperTotal + cartonTotal) * 26.5 +
        aluminumTotal * 0.98 +
        plasticTotal * 39.26 +
        tetrapackTotal * 19.8 +
        ironTotal * 0.27;
    const totalTrees = totalCO2kg / 1000 / 0.06;

    const totalEnergy = (totalCO2kg / 1000 / 7.94041) * 11880;

    const calculatedItems = [
        {
            type: 'trees',
            label: 'Árboles salvados',
            total: totalTrees,
            unit: '',
            eqlabel: ' resmas de papel',
            equivalent: totalTrees * 16.67,
            logo: '/svg-icons/eco-equivalences/trees.svg'
        },
        {
            type: 'co2',
            label: 'CO2 no emitido',
            total: totalCO2kg > 1000 ? totalCO2kg / 1000 : totalCO2kg,
            unit: totalCO2kg > 1000 ? 'ton' : 'kg',
            eqlabel: ' autos sin salir por un día',
            equivalent: totalCO2kg / 1000 / 0.01271518,
            logo: '/svg-icons/eco-equivalences/co2.svg'
        },
        {
            type: 'energy',
            label: 'Energía evitada',
            total: totalEnergy > 1000 ? totalEnergy / 1000 : totalEnergy,
            unit: totalEnergy > 1000 ? 'MWh' : 'kWh',
            eqlabel: ' meses de consumo de un hogar',
            equivalent: totalEnergy / 172.83,
            logo: '/svg-icons/eco-equivalences/energy.svg'
        },
        {
            type: 'water',
            label: 'Agua evitada',
            total: totalWater > 1000 ? totalWater / 1000 : totalWater,
            unit: totalWater > 1000 ? 'm' : 'l',
            eqlabel: ' horas regando',
            equivalent: totalWater / 1200,
            logo: '/svg-icons/eco-equivalences/water.svg'
        }
    ];
    return calculatedItems;
};

export const FormatGarbageData = (results, labels) => {
    const filteredResults = results.filter(item => labels.includes(item.date));
    const supplyMap = new Map();
    filteredResults?.forEach(result => {
        const supplyId = result.supply.id;
        const supplyName = result.supply.name;
        const date = result.date;
        const total = result.total * result.capacity;

        if (!supplyMap.has(supplyId)) {
            supplyMap.set(supplyId, {
                id: supplyId,
                label: supplyName,
                data: Array(labels.length).fill(0) // Inicializar con ceros
            });
        }

        const supplyData = supplyMap.get(supplyId);
        const labelIndex = labels.indexOf(date);
        if (labelIndex !== -1) {
            supplyData.data[labelIndex] =
                parseInt(supplyData.data[labelIndex]) + total;
        }
    });

    const datasets = Array.from(supplyMap?.values()).map((dataset, index) => {
        const opacity = Math.max(1 - index * 0.15, 0);
        const rgbaColor = `rgba(101, 192, 90, ${opacity})`;

        return {
            ...dataset,
            backgroundColor: rgbaColor,
            borderColor: rgbaColor,
            borderWidth: 0
        };
    });
    return datasets;
};

export const GetBzeroIndex = (data: any, totalValues: number, pos?: number) => {
    const lastValue = pos ?? data.labels.length - 1;
    const startIndex = lastValue - totalValues;
    const validStartIndex = Math.max(0, startIndex);
    const kindSpecificDatasets = data.datasets.filter(
        dataset => dataset.kind === 'a4688f23-9e63-af00-4ef9-b71a757710c0'
    );
    let total = 0;
    let totalTrash = 0;
    if (kindSpecificDatasets.length > 0) {
        // Iterar sobre cada posición desde validStartIndex hasta el final
        for (let i = validStartIndex; i < lastValue; i++) {
            const hasValidKindSpecificValue = kindSpecificDatasets.some(
                dataset => dataset.data[i] > 0
            );
            if (hasValidKindSpecificValue) {
                // Sumar los valores válidos del tipo específico
                kindSpecificDatasets.forEach(dataset => {
                    const value = dataset.data[i];
                    if (value > 0) {
                        totalTrash += parseFloat(value);
                    }
                });

                // Sumar los valores del total, excepto del dataset con id 'bzeroindex'
                data.datasets.forEach(dataset => {
                    if (dataset.id !== 'Índice BZero') {
                        const value = dataset.data[i];
                        if (value > 0) {
                            total += parseFloat(value);
                        }
                    }
                });
            }
        }
    }

    let bzeroindex = 0;
    let bzeroindexRounded = 0;

    if (total !== 0) {
        bzeroindex = (totalTrash / total) * 100;
        bzeroindexRounded = parseFloat(bzeroindex.toFixed(2));
    }

    return bzeroindexRounded;
};
