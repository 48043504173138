import './bzeroindexcard.scss';
import { useEffect, useState } from 'react';
import { lang } from '../../langs';
import { useCallApi } from '../../../config/hooks/useCallApi';
import { UserStoreInterface } from '../../../store/slices/users/user.interface';
import { useAppSelector } from '../../../store/hooks';
import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement } from 'chart.js';
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

interface Props {
    index: number;
    certificate?: boolean;
    devicePixelRatio?: number;
}

const BZeroIndexCard = ({ index, certificate, devicePixelRatio }: Props) => {
    const { LoadingData, LoaderElement } = useCallApi();
    const { preferences }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const defaultLang: string = preferences.lang;
    const key = defaultLang;
    const {
        [key as keyof typeof lang]: {
            dataCenterComponents: {
                bzeroIndexCard: {
                    title,
                    subtitle,
                    noContentMessage,
                    detailMessage
                }
            }
        }
    } = lang;

    Chart.register(ArcElement);

    const [dataChart, setDataChart] = useState({
        datasets: [
            {
                data: [index, 100 - index],
                backgroundColor: ['red', 'blue'],
                display: true,
                borderColor: '#fff',
                borderWidth: 8
            }
        ]
    });

    useEffect(() => {
        let color;
        if (index >= 0 && index <= 50) {
            color = ['#03dac5', '#fff'];
        } else if (index > 50 && index <= 75) {
            color = ['#FDDF4A', '#fff'];
        } else if (index > 75) {
            color = ['#CE3E50', '#fff'];
        }

        const chartObj = {
            datasets: [
                {
                    data: [index, 100 - index],
                    backgroundColor: color,
                    display: true,
                    borderColor: '#fff',
                    borderWidth: 8
                }
            ]
        };
        setDataChart(chartObj);
    }, [index]);

    return (
        <div className='bzeroindex-card'>
            {!certificate && (
                <>
                    <div className='bzeroindex-card__titulo-card title'>
                        {title}
                    </div>
                    <div className='bzeroindex-card__subtitulo-card subtitle'>
                        {subtitle}
                        <Tooltip
                            title={
                                <span className='main_menu__grid-container__tooltip'>
                                    {detailMessage}
                                </span>
                            }
                            color='#fff'
                        >
                            <InfoCircleOutlined
                                className='bzeroindex-card__subtitulo-card__icon'
                                rev={''}
                            />
                        </Tooltip>
                    </div>
                </>
            )}
            <div
                className={`bzeroindex-card__content ${
                    certificate ? 'index-certificate' : ''
                }`}
            >
                {/* {dataChart ? (
                 */}
                {index ? (
                    <div className={`bzeroindex-card__content__chart`}>
                        <Doughnut
                            data={dataChart}
                            options={{
                                plugins: {
                                    legend: {
                                        display: false
                                    },
                                    tooltip: {
                                        enabled: true,
                                        backgroundColor: 'rgb(255, 255, 255)',
                                        titleColor: 'rgb(162 162 163)', // Color del texto del título del tooltip
                                        bodyColor: 'rgb(162 162 163)', // Color del texto del cuerpo del tooltip
                                        footerColor: 'rgb(162 162 163)',
                                        padding: 6,
                                        boxPadding: 3,
                                        usePointStyle: true
                                    }
                                },
                                rotation: -90,
                                circumference: 180,
                                cutout: '75%',
                                maintainAspectRatio: true,
                                responsive: true,
                                devicePixelRatio: devicePixelRatio ?? 1
                            }}
                        />
                        <div
                            className={`bzeroindex-card__content__chart__number ${
                                certificate ? 'number-certificate' : 'bigtitle'
                            }`}
                        >
                            {index.toLocaleString('es-CL', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 1
                            })}
                            %
                        </div>
                        <div
                            className={`bzeroindex-card__content__chart__inf subsubtitle ${
                                certificate ? 'mini-number-certificate' : ''
                            }`}
                        >
                            0%
                        </div>
                        <div
                            className={`bzeroindex-card__content__chart__sup subsubtitle ${
                                certificate ? 'mini-number-certificate' : ''
                            }`}
                        >
                            100%
                        </div>
                    </div>
                ) : (
                    <div
                        className={`bzeroindex-card__content__message subtitle'
                        ${certificate ? 'mini-number-certificate' : ''}`}
                    >
                        {noContentMessage}
                    </div>
                )}
            </div>
            {LoadingData && <LoaderElement />}
        </div>
    );
};

export default BZeroIndexCard;
