import '../../settings.scss';
import './reports-settings.scss';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
//  <--COMPONENTS--> //
import SubNavBar from '../../../../components/layout/main/subnavbar/subnavbar';
import Header from '../../../../components/header/header';
import TableData from '../../../../components/data-table/data-table';
/* import ChartFormated from '../../../../components/dataCenterComponents/monthlyEvolutionCard/components/chartFormated'; */
//  <--HOOKS--> //
import { useToast } from '../../../../config/hooks/useToast';
import { useAlert } from '../../../../config/hooks/useAlert';
import { useCallApi } from '../../../../config/hooks/useCallApi';
import { HTTP_METHODS } from '../../../../config/hooks/useCallApi/constants';
//  <--INTERFACE--> //
import {
    PropertyFormCreateInterface,
    PropertyFormValues,
    /* RequirementInterface, */
    PropertyInterface,
    /* PropertyUnitInterface, */
    MaterialInterface
} from '../../../../interface';
//  <--REDUX--> //
import { UserStoreInterface } from '../../../../store/slices/users/user.interface';
import { useAppSelector } from '../../../../store/hooks';
//  <--SERVICES--> //
import {
    ApiPropertyId,
    ApiPropertyUnitId,
    ApiPropertyByUser,
    ApiPropertyUnitByUser
} from '../../../../config/service';
//  <--OTHERS--> //
import { lang } from '../../../langs';
import '../../../../scss/global/global.scss';

/* interface MaterialList {
    data_font: string;
    material: string;
    name: string;
    property: string;
} */

interface RequirementItem {
    id: string;
    material: string;
}

interface FontItem {
    dataRequirements: RequirementItem[];
    id: string;
    name: string;
    destination: string;
    data_typology: string;
}

const PropertyReports = () => {
    const { GetData, LoadingData, LoaderElement } = useCallApi();
    const {
        ConfigToast,
        setConfigToast,
        ToastElement,
        toastManagerRef,
        Colors
    } = useToast();
    const { preferences }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const defaultLang: string = preferences.lang;
    const { AlertElement, alertManagerRef } = useAlert({ defaultLang });
    const { id, propertyUnitId } = useParams();
    const {
        [defaultLang as keyof typeof lang]: {
            pageProperty: { title, configTitle }
        }
    } = lang;
    const [entityId, setEntityId] = useState<string>('');
    const [entity, setEntity] = useState<PropertyFormCreateInterface>();
    const [materials, setMaterials] = useState<MaterialInterface[]>([]);
    const [fonts, setFonts] = useState<FontItem[]>([]);
    const [property, setProperty] = useState<PropertyInterface>();
    const [propertyUnit, setPropertyUnit] = useState<PropertyInterface>();

    /**
     * @description CallDataEdit function, call the api to get the data of the entity and set the data in state
     */
    const CallDataEdit = async (type: string) => {
        if (type === 'unit') {
            const response = await GetData(
                ApiPropertyUnitId(entityId),
                HTTP_METHODS.GET
            );
            const { data, status } = response;
            setEntity(data);
            if (!status) {
                ConfigToast.text = data.message;
                ConfigToast.backColor = Colors.Error;
                ConfigToast.title = 'Error';
                setConfigToast(ConfigToast);
            }
        } else {
            const response = await GetData(
                ApiPropertyId(entityId),
                HTTP_METHODS.GET
            );
            const { data, status } = response;
            setEntity(data);
            if (!status) {
                ConfigToast.text = data.message;
                ConfigToast.backColor = Colors.Error;
                ConfigToast.title = 'Error';
                setConfigToast(ConfigToast);
            }
        }
    };

    const CallData = async () => {
        if (propertyUnitId) {
            const response = await GetData(
                ApiPropertyUnitByUser(propertyUnitId),
                HTTP_METHODS.GET
            );
            const { data } = response;
            const filteredMaterials = data[0].kindsMaterialsAvailable.filter(
                material => material !== null
            );
            setMaterials(filteredMaterials);
            const externalFonts = data[0].dataFonts.filter(
                font => font.destination === 'Gestor'
            );
            const internalFonts = data[0].dataFonts.filter(
                font => font.destination !== 'Gestor'
            );
            setFonts(externalFonts.concat(internalFonts));
            setPropertyUnit(data[0]);
        } else if (id) {
            const response = await GetData(
                ApiPropertyByUser(id),
                HTTP_METHODS.GET
            );
            const { data } = response;
            const filteredMaterials = data[0].kindsMaterialsAvailable.filter(
                material => material !== null
            );
            setMaterials(filteredMaterials);
            const externalFonts = data[0].dataFonts.filter(
                font => font.destination === 'Gestor'
            );
            const internalFonts = data[0].dataFonts.filter(
                font => font.destination !== 'Gestor'
            );
            setFonts(externalFonts.concat(internalFonts));
            setProperty(data[0]);
        }
    };

    /**
     * @description this effect verify if exist id in url params, if exist, set the id in state, else set readyForm to true
     */
    useEffect(() => {
        if (propertyUnitId) {
            setEntityId(propertyUnitId);
        } else if (id) {
            setEntityId(id);
        } else {
            setEntity(PropertyFormValues);
        }
    }, [propertyUnitId, id]);

    /**
     * @description this effect check that entityId is not empty and if it changes, if not empty, call the function to CallDataEdit
     */
    useEffect(() => {
        if (entityId !== '') {
            CallData();
            if (propertyUnitId) {
                CallDataEdit('unit');
            } else {
                CallDataEdit('property');
            }
        }
    }, [entityId]);

    return (
        <>
            <ToastElement ref={toastManagerRef} />
            <AlertElement ref={alertManagerRef} />
            <SubNavBar
                type='settings'
                title={title}
                icon='settings/propertyIconB.svg'
                kind='settings'
                option='Properties'
            ></SubNavBar>
            <div className='settings'>
                <Header
                    title={{ name: title, link: '/panel/properties' }}
                    icon='PropertyIconB.svg'
                    subtitle={{
                        name: entity?.name ?? '',
                        link: `/panel/properties/edit/${id}`
                    }}
                    subsubtitle={{ name: configTitle }}
                />
                <div className='reports-settings'>
                    <div className='reports-settings__titulo-card title'>
                        Selección de fuentes y materiales
                    </div>
                    <div className='reports-settings__subtitulo-card subtitle'>
                        Para cada material seleccione las fuentes de datos que
                        aplican en sus reportes
                    </div>
                    {property && (
                        <TableData
                            property={property}
                            headersx={materials}
                            headersy={fonts}
                        />
                    )}
                    {propertyUnit && (
                        <TableData
                            propertyUnit={propertyUnit}
                            headersx={materials}
                            headersy={fonts}
                        />
                    )}
                    {LoadingData && <LoaderElement />}
                    <div className='reports-settings__legend'>
                        <div className='reports-settings__legend__item'>
                            <div className='reports-settings__legend__item__secondary-light'></div>
                            <div className='subtitle'>Campo seleccionable</div>
                        </div>
                        <div className='reports-settings__legend__item'>
                            <div className='reports-settings__legend__item__secondary'></div>
                            <div className='subtitle'>Campo seleccionado</div>
                        </div>
                    </div>
                    {/* <ChartFormated /> */}
                    {/* <div className='reports-settings__titulo-card subtitle'>
                        Previsualización gráfico de evolución integrada
                    </div> */}
                </div>
            </div>
        </>
    );
};

export default PropertyReports;
