import styled from 'styled-components';

export const Wrapper = styled('div')`
    border-radius: 3px !important;
    padding: 5px;
`;

export const WrapperButtons = styled('div')`
    border-radius: 3px !important;
    padding: 5px;
    display: block;
    background-color: #ffffff;
`;

export const Container = styled('div')`
    border-radius: 3px !important;
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    margin-left: auto;
    margin-right: auto;
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    width: 100%;
`;
