import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
// <--COMPONENTS--> //
import Table from '../../components/table-editable/table';
import SubNavBar from '../../components/layout/main/subnavbar/subnavbar';
import Header from '../../components/header/header';
// <--REDUX--> //
import { useAppSelector } from '../../store/hooks';
import { UserStoreInterface } from '../../store/slices/users/user.interface';
//  <--HOOKS--> //
import { useToast } from '../../config/hooks/useToast';
import { useAlert } from '../../config/hooks/useAlert';
import { useCallApi } from '../../config/hooks/useCallApi';
import { HTTP_METHODS } from '../../config/hooks/useCallApi/constants';
// <--INTERFACE--> //
import { lang } from '../langs';
import { DefaultValuesHeaders } from '../../components/table-editable/data/columns_table';
import { TableEditableTypesValues } from '../../components/table-editable/interface/table.interface';
import {
    ApiManagerDefaultTreatment,
    ApiManagerDefaultTreatmentId,
    ApiManagerDefaultTreatmentByGestor,
    ApiGestorId,
    ApiMaterial,
    ApiTreatment,
    ApiFinalDisposal
} from '../../config/service';
import {
    GestorFormCreateInterface,
    GestorFormValues,
    MaterialInterface,
    TreatmentInterface,
    FinalDisposalUnitInterface
} from '../../interface';

const DefaultValues = () => {
    const {
        ConfigToast,
        setConfigToast,
        ToastElement,
        toastManagerRef,
        Colors
    } = useToast();
    const { GetData, LoadingData, LoaderElement } = useCallApi();
    const { preferences }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const defaultLang: string = preferences.lang;
    const { AlertElement, alertManagerRef } = useAlert({ defaultLang });
    const key = defaultLang;
    const {
        [key as keyof typeof lang]: {
            sinader: {
                defaultValues: { title, saveButton }
            }
        }
    } = lang;
    const [tblReady, setTblReady] = useState<boolean>(false);
    const [defaultValuesList, setDefaultValuesList] = useState();
    const { id } = useParams();
    const [entityId, setEntityId] = useState<string>('');
    const [entity, setEntity] = useState<GestorFormCreateInterface>();
    const [materials, setMaterials] = useState<MaterialInterface[]>([]);
    const [treatments, setTreatments] = useState<TreatmentInterface[]>([]);
    const [finalDisposalUnits, setFinalDisposalUnits] = useState<
        FinalDisposalUnitInterface[]
    >([]);
    const [refreshTable, setRefreshTable] = useState<number>(0);
    const [onSaveChange, setOnSaveChange] = useState<boolean>(false);

    /**
     * @description this effect verify if exist id in url params, if exist, set the id in state, else set readyForm to true
     */
    useEffect(() => {
        if (id) {
            setEntityId(id);
        } else {
            setEntity(GestorFormValues);
        }
    }, [id]);

    /**
     * @description CallDataEdit function, call the api to get the data of the entity and set the data in state
     */
    const CallDataEdit = async () => {
        const response = await GetData(ApiGestorId(entityId), HTTP_METHODS.GET);
        const { data } = response;
        setEntity(data);
    };

    /**
     * @description this effect check that entityId is not empty and if it changes, if not empty, call the function to CallDataEdit
     */
    useEffect(() => {
        if (entityId !== '') {
            CallDataEdit();
        }
    }, [entityId]);

    const Refresh = () => {
        setRefreshTable(refreshTable + 1);
    };

    /**
     * @description function that is executed when the save button is pressed
     * @function Save is a function that launches an alert to confirm the save of a item
     * @param id is a string that contains the id of the item to be saved
     */
    const OnSave = async registers => {
        registers.map(async item => {
            if (
                item.materialId !== '' &&
                item.treatmentId !== '' &&
                item.finalDisposalUnitId !== ''
            ) {
                const data = {
                    active: item.active,
                    material: item.materialId,
                    gestor: id,
                    treatment: item.treatmentId,
                    final_disposal_unit: item.finalDisposalUnitId,
                    property: item.property ?? null
                };
                let response;
                if (item.isNew) {
                    response = await GetData(
                        ApiManagerDefaultTreatment,
                        HTTP_METHODS.POST,
                        data
                    );
                } else {
                    response = await GetData(
                        ApiManagerDefaultTreatmentId(item.id),
                        HTTP_METHODS.PATCH,
                        data
                    );
                }
                const { status, message } = response;
                if (status) {
                    ConfigToast.text = message;
                    ConfigToast.backColor = Colors.Success;
                    setConfigToast(ConfigToast);
                    /* setTimeout(() => {
                        CallMainData(1, tableData.regXpage, false, true);
                    }, 1000); */
                    /* setOnSaveChange(false); */
                }
                setOnSaveChange(false);
            }
        });
    };

    const SaveChanges = () => {
        setOnSaveChange(true);
    };

    /**
     * @description
     * @function CallData is a function that returns a promise with the default-values list
     */
    const CallData = async (refresh = false) => {
        if (id) {
            const response = await GetData(
                ApiManagerDefaultTreatmentByGestor(id),
                HTTP_METHODS.GET
            );
            const { data, status, message } = response;
            setDefaultValuesList(data);
            if (!status) {
                ConfigToast.text = message;
                ConfigToast.backColor = Colors.Error;
                ConfigToast.title = 'Error';
                setConfigToast(ConfigToast);
            }
            setTblReady(true);
            if (refresh) {
                Refresh();
            }
        }
    };

    const CallMaterials = async () => {
        const response = await GetData(ApiMaterial, HTTP_METHODS.GET);
        const materials = [...response.data].sort((a, b) =>
            a.name.localeCompare(b.name)
        );
        setMaterials(materials);
    };

    const CallTreatments = async () => {
        const response = await GetData(ApiTreatment, HTTP_METHODS.GET);
        const treatments = [...response.data].sort((a, b) =>
            a.name.localeCompare(b.name)
        );
        setTreatments(treatments);
    };

    const CallFinalDisposalUnits = async () => {
        const response = await GetData(ApiFinalDisposal, HTTP_METHODS.GET);
        const finaldisposals = [...response.data].sort((a, b) =>
            a.name.localeCompare(b.name)
        );
        const finaldisposalunits = finaldisposals.flatMap(
            item => item.finalDisposalUnits || []
        );
        setFinalDisposalUnits(finaldisposalunits);
    };

    /**
     * @description
     * Function that is executed when the component is mounted, calls the function that returns the default-values list
     */
    useEffect(() => {
        CallData();
    }, []);

    useEffect(() => {
        CallMaterials();
        CallTreatments();
        CallFinalDisposalUnits();
    }, []);

    return (
        <>
            <ToastElement ref={toastManagerRef} />
            <AlertElement ref={alertManagerRef} />
            <SubNavBar
                type='settings'
                title={title}
                icon='settings/GestorsIcon.svg'
                kind='settings'
                option='Gestors'
            ></SubNavBar>
            <div className='settings'>
                <Header
                    title={{ name: 'Gestores' }}
                    icon='GestorsIconB.svg'
                    subtitle={{
                        name: id ? entity?.name ?? 'Gestor X' : '',
                        link: `/panel/company/edit/${id}`
                    }}
                    subsubtitle={{ name: title }}
                />
                <div className='settings__grid-container__item__title title'>
                    {title}
                </div>
                <div className='settings__table-container'>
                    {tblReady && (
                        <Table
                            data={defaultValuesList}
                            headers={DefaultValuesHeaders}
                            saveData={OnSave}
                            type={TableEditableTypesValues.inRow}
                            highlight={null}
                            treatments={treatments}
                            materials={materials}
                            finalDisposalUnits={finalDisposalUnits}
                            onRefresh={refreshTable}
                            onSaveChange={onSaveChange}
                        />
                    )}
                    {LoadingData && <LoaderElement />}
                    <div className='settings__table-container__btns'>
                        <button
                            type='button'
                            className='button button-body--save-sinader subtitle'
                            onClick={SaveChanges}
                        >
                            {saveButton}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DefaultValues;
