import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import DetailsIcon from '@material-ui/icons/Notes';
//  <--HOOKS--> //
import { useToast } from '../../config/hooks/useToast';
import { useAlert } from '../../config/hooks/useAlert';
import { useCallApi } from '../../config/hooks/useCallApi';
import { HTTP_METHODS } from '../../config/hooks/useCallApi/constants';
//  <--COMPONENTS--> //
import { Wrapper, WrapperButtons } from '../../components/styles';
import TableComponent from '../../components/table';
import { ProgramTable } from '../../components/table/data/columns_table';
// import ProgramDetail from './detail-program';
import SubNavBar from '../../components/layout/main/subnavbar/subnavbar';
//  <--SERVICES--> //
import {
    ApiProgram,
    ApiProgramId,
    ApiProgramIdMaterials
} from '../../config/service';
//  <--INTERFACE--> //
import { ProgramInterface } from '../../interface';
import { constantsTable } from './constants';
import { lang } from '../langs';
//  <--REDUX--> //
import { UserStoreInterface } from '../../store/slices/users/user.interface';
import { useAppSelector } from '../../store/hooks';

const Program = () => {
    const {
        ConfigToast,
        setConfigToast,
        ToastElement,
        toastManagerRef,
        Colors
    } = useToast();
    const { preferences }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const defaultLang: string = preferences.lang;
    const {
        AlertElement,
        alertManagerRef,
        ConfigAlert,
        setConfigAlert,
        Defaults
    } = useAlert({ defaultLang });
    const navigate = useNavigate();
    const { GetData, LoadingData, LoaderElement } = useCallApi();
    const {
        [defaultLang as keyof typeof lang]: {
            pageProgram: { title, buttonNew, deleteMessage }
        }
    } = lang;
    const { keysFilter } = constantsTable;
    const [dataList, setDataList] = useState<ProgramInterface[]>([]);
    // const [selectedProgram, setSelectedProgram] = useState<ProgramInterface>();
    // const [showDetails, setShowDetails] = useState<boolean>(false);

    /**
     * @description
     * @function CallData is a function that returns a promise with the Program list
     */
    const CallData = async () => {
        const response = await GetData(ApiProgram, HTTP_METHODS.GET);
        const { data, status } = response;
        setDataList(data);
        if (!status) {
            ConfigToast.text = data.message;
            ConfigToast.backColor = Colors.Error;
            ConfigToast.title = 'Error';
            setConfigToast(ConfigToast);
        }
    };

    /**
     * @description
     * Function that is executed when the component is mounted, calls the function that returns the Program list
     */
    useEffect(() => {
        CallData();
    }, []);

    /**
     * @description
     * @function Edit is a function that redirects to the PROGRAM edition page
     * @param {string} id is a string that contains the id of the PROGRAM to be deleted
     */
    const Edit = (id: string) => {
        navigate(`./edit/${id}`);
    };

    /**
     * @description function that is executed when the delete button is pressed
     * @function Delete is a function that launches an alert to confirm the deletion of a PROGRAM
     * @param id is a string that contains the id of the PROGRAM to be deleted
     */
    const Delete = async (id: string) => {
        ConfigAlert.title = Defaults.titles.Delete;
        ConfigAlert.message = deleteMessage;
        ConfigAlert.okButtonText = Defaults.buttons.Ok.Accept;
        ConfigAlert.okButtonController = { func: ConfirmDelete, param: id };
        ConfigAlert.cancelButtonText = Defaults.buttons.Cancel.Cancel;
        setConfigAlert(ConfigAlert);
    };

    /**
     * @description function that is executed when the okDelete button is pressed
     * @function Delete is a function that deletes a PROGRAM
     * @param id is a string that contains the id of the PROGRAM to be deleted
     */
    const ConfirmDelete = async (id: string) => {
        const response = await GetData(ApiProgramId(id), HTTP_METHODS.DELETE);
        const { data, status, message } = response;
        if (status) {
            ConfigToast.text = message;
            ConfigToast.backColor = Colors.Success;
            CallData();
            setConfigToast(ConfigToast);
        } else {
            ConfigToast.text = data.message;
            ConfigToast.backColor = Colors.Error;
            ConfigToast.title = 'Error';
            setConfigToast(ConfigToast);
        }
    };

    /**
     * @description function that calls the material data selected
     * @function CallProgramData is a function that calls the material data selected
     * @param id is a string that contains the id of the material to be consulted
     */
    const CallProgramData = async (id: string) => {
        const response = await GetData(
            ApiProgramIdMaterials(id),
            HTTP_METHODS.GET
        );
        const { data, status } = response;
        // setSelectedProgram(data);
        if (!status) {
            ConfigToast.text = data.message;
            ConfigToast.backColor = Colors.Error;
            ConfigToast.title = 'Error';
            setConfigToast(ConfigToast);
        }
    };

    /**
     * @description function that is executed when the detail button is pressed
     * @function MaterialsData is a function that opens the detail modal and calls the function that returns the PROGRAM data
     * @param id is a string that contains the id of the PROGRAM to be consulted
     */
    const MaterialsData = async (id: string) => {
        // setShowDetails(true);
        CallProgramData(id);
    };

    /**
     * @description function that is executed when saves the material data related to the MATKI
     * @function SuccessSave is a function that saves the material data related to the MATKI
     * @param status is a boolean that indicates if the operation was successful
     */
    // const SuccessSave = (status: boolean) => {
    //     if (status && selectedProgram) {
    //         console.log(selectedProgram);
    //     }
    // };

    return (
        <>
            <ToastElement ref={toastManagerRef} />
            <AlertElement ref={alertManagerRef} />
            <SubNavBar
                type='settings'
                title={title}
                icon='settings/ProgramIcon.svg'
                kind='settings'
                option='Program'
            ></SubNavBar>
            <Wrapper>
                <WrapperButtons className='app-d-flex__right'>
                    <Link to={'./create'} className='btn bzero_btn_primary'>
                        {buttonNew}
                    </Link>
                </WrapperButtons>
                <TableComponent
                    data={dataList}
                    headers={ProgramTable}
                    showFilters={true}
                    keysFilter={keysFilter}
                    showPagination={true}
                    actionsButtons={{ onEdit: Edit, onDelete: Delete }}
                    addsActions={[
                        {
                            action: MaterialsData,
                            title: 'Ver materiales',
                            icon: <DetailsIcon />
                        }
                    ]}
                />
            </Wrapper>
            {LoadingData && <LoaderElement />}
            {/* {showDetails && (
                <ProgramDetail
                    showDetails={showDetails}
                    setShowDetails={setShowDetails}
                    detailsTitle={detailsTitle}
                    selectedEntity={selectedProgram}
                    SuccessSave={SuccessSave}
                />
            )} */}
        </>
    );
};

export default Program;
