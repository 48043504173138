export interface UserInterface {
    id: string;
    name: string;
    lastName: string;
    email: string;
    phone: string;
    rut: string;
    activationToken: string;
    resetPasswordToken: string;
    state: number;
    createdAt: string;
    updatedAt: string;
}

export interface UserRequestResetPassword {
    email: string;
}

export const UserRequestResetPasswordFormValues: UserRequestResetPassword = {
    email: ''
};

export interface UserRecoverPasswordFormInterface {
    password: string;
    passwordConfirm: string;
}

export const UserRecoverPasswordFormValues: UserRecoverPasswordFormInterface = {
    password: '',
    passwordConfirm: ''
};

export interface UserChangePasswordFormInterface {
    lastPassword: string;
    newPassword: string;
    newPasswordConfirm: string;
}

export const UserChangePasswordFormValues: UserChangePasswordFormInterface = {
    lastPassword: '',
    newPassword: '',
    newPasswordConfirm: ''
};

export interface UserUpdateFormInterface {
    name: string;
    lastName: string;
    rut: string;
    phone: string;
}

export const UserUpdateFormValues: UserUpdateFormInterface = {
    name: '',
    lastName: '',
    rut: '',
    phone: ''
};

export interface UserFormCreateInterface {
    name: string;
    lastName: string;
    rut: string;
    phone: string;
}

export const UserFormValues: UserFormCreateInterface = {
    name: '',
    lastName: '',
    rut: '',
    phone: ''
};
