import { Button } from 'antd';
import CheckIcon from '@material-ui/icons/Check';
//  <--COMPONENTS--> //
import { Wrapper, Container } from '../../components/styles';
//  <--REDUX--> //
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import { UserStoreInterface } from '../../store/slices/users/user.interface';
import { setDeffaultProfile } from '../../store/slices/users';
import { SaveStorage } from '../../config/utils/Storage';
import { lang } from '../langs';

const ProfileSelection = () => {
    const dispatch = useAppDispatch();
    const { userData } = useAppSelector(state => state.users);
    const { roles } = userData;
    const { defaultProfile, preferences }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const defaultLang: string = preferences.lang;
    const {
        [defaultLang as keyof typeof lang]: {
            pageProfileSelection: { title, buttonText }
        }
    } = lang;

    const SetAsDeffault = (element: number) => {
        SaveStorage(element, process.env.REACT_APP_PUBLIC_DEFFAULT_ROLE);
        dispatch(setDeffaultProfile(element));
        window.location.reload();
    };

    return (
        <Container>
            <Wrapper>
                <h1 className='page__title'>{title}</h1>
                {roles.map((item, index) => {
                    return (
                        <div key={item.id} className='app-d-flex__between'>
                            <p> {item.name} </p>
                            {defaultProfile === index ? (
                                <CheckIcon />
                            ) : (
                                <Button
                                    onClick={() => {
                                        SetAsDeffault(index);
                                    }}
                                    className='bzero_btn_success'
                                >
                                    {buttonText}
                                </Button>
                            )}
                        </div>
                    );
                })}
            </Wrapper>
        </Container>
    );
};

export default ProfileSelection;
