import '../../settings.scss';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
//  <--COMPONENTS--> //
import SubNavBar from '../../../../components/layout/main/subnavbar/subnavbar';
import Header from '../../../../components/header/header';
import { Switch, ConfigProvider, Tooltip } from 'antd';
/* import { ConfigProvider, Tree } from 'antd'; */
import {
    CheckOutlined,
    CloseOutlined,
    EyeOutlined,
    CaretDownOutlined,
    CaretUpOutlined,
    BorderOutlined
} from '@ant-design/icons';
/* import ButtonBody from '../../../../components/button/button'; */
//  <--HOOKS--> //
import { useToast } from '../../../../config/hooks/useToast';
import { useAlert } from '../../../../config/hooks/useAlert';
import { useCallApi } from '../../../../config/hooks/useCallApi';
import { HTTP_METHODS } from '../../../../config/hooks/useCallApi/constants';
//  <--INTERFACE--> //
import {
    ClientFormValues,
    ClientFormCreateInterface,
    PropertyInterface,
    ClientBusinessInterface
} from '../../../../interface';
//  <--REDUX--> //
import { UserStoreInterface } from '../../../../store/slices/users/user.interface';
import { useAppSelector } from '../../../../store/hooks';
//  <--SERVICES--> //
import {
    ApiClientId,
    ApiProperty,
    ApiClientBusiness,
    ApiClientBusinessId
} from '../../../../config/service';
//  <--OTHERS--> //
import { lang } from '../../../langs';
import '../../../../scss/global/global.scss';

interface ChildInterface {
    key: string;
    label: string;
    state: string;
    clientBusiness: null | ClientBusinessInterface;
    modified: number;
}

interface ItemInterface {
    key: string;
    label: string;
    state: string;
    clientBusiness: null | ClientBusinessInterface;
    showChildren: boolean;
    children: ChildInterface[];
    show: boolean;
    modified: number;
}

const ClientProperties = () => {
    const { GetData, LoadingData, LoaderElement } = useCallApi();
    const {
        ConfigToast,
        setConfigToast,
        ToastElement,
        toastManagerRef,
        Colors
    } = useToast();
    const { preferences }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const defaultLang: string = preferences.lang;
    const {
        AlertElement,
        alertManagerRef /* ,
        ConfigAlert,
        setConfigAlert,
        Defaults */
    } = useAlert({ defaultLang });
    const { id } = useParams();
    const {
        [defaultLang as keyof typeof lang]: {
            pageClient: {
                title,
                adminTitle,
                cancelBtn,
                saveBtn,
                instructionsTitle,
                previewTitle,
                previewSubtitle,
                legendTitle,
                checkLegend,
                crossLegend,
                eyeLegend
            }
        }
    } = lang;
    const [entityId, setEntityId] = useState<string>('');
    const [entity, setEntity] = useState<ClientFormCreateInterface>();
    const [properties, setProperties] = useState<PropertyInterface[]>([]);
    const [filteredProperties, setFilteredProperties] = useState<
        PropertyInterface[]
    >([]);
    const [clientBusiness, setClientBusiness] = useState<
        ClientBusinessInterface[]
    >([]);
    const [items, setItems] = useState<ItemInterface[]>([]);
    const [searchInput, setSearchInput] = useState<string>('');
    const [previewItems, setPreviewItems] = useState<any[]>([]);
    const [showReadOnlys, setShowReadOnlys] = useState<boolean>(false);

    /**
     * @description this effect verify if exist id in url params, if exist, set the id in state, else set readyForm to true
     */
    useEffect(() => {
        if (id) {
            setEntityId(id);
        } else {
            setEntity(ClientFormValues);
        }
    }, [id]);

    /**
     * @description CallDataEdit function, call the api to get the data of the entity and set the data in state
     */
    const CallDataEdit = async () => {
        const response = await GetData(ApiClientId(entityId), HTTP_METHODS.GET);
        const { data, status } = response;
        setClientBusiness(data.clientBusiness);
        setEntity(data);
        if (!status) {
            ConfigToast.text = data.message;
            ConfigToast.backColor = Colors.Error;
            ConfigToast.title = 'Error';
            setConfigToast(ConfigToast);
        }
    };

    const CallProperties = async () => {
        const response = await GetData(ApiProperty, HTTP_METHODS.GET);
        const { data } = response;
        setProperties(
            [...data]
                .sort((a, b) => a.name.localeCompare(b.name)) // Ordenar propiedades por 'name'
                .map(property => ({
                    ...property,
                    propertyUnits: [...property.propertyUnits].sort((a, b) =>
                        a.name.localeCompare(b.name)
                    ) // Ordenar 'propertyUnits' por 'name'
                }))
        );
        setFilteredProperties(
            [...data].sort((a, b) => a.name.localeCompare(b.name))
        );
    };

    const SetTree = () => {
        const tree: ItemInterface[] = properties.map(property => {
            const matchedProperty = items.find(
                item => item.key === property.id
            );
            return {
                label: property.name,
                key: property.id,
                state: matchedProperty ? matchedProperty.state : 'Inactive',
                clientBusiness: null,
                showChildren: false,
                children: property.propertyUnits
                    ?.filter(unit => unit.state === 1)
                    .map(unit => {
                        let matchedUnit: ChildInterface | undefined;
                        if (matchedProperty?.children) {
                            matchedUnit = matchedProperty.children.find(
                                child => child.key === unit.id
                            );
                        }
                        return {
                            label: unit.name,
                            key: unit.id,
                            state: matchedUnit ? matchedUnit.state : 'Inactive',
                            clientBusiness: null,
                            modified: 0
                        };
                    }),
                show: true,
                modified: 0
            };
        });
        clientBusiness.forEach(business => {
            const clientProperty = business.property;
            const clientPropertyUnit = business.propertyUnit;

            const propertyIndex = tree.findIndex(
                property => property.key === clientProperty.id
            );

            if (propertyIndex !== -1) {
                // Encontro el property en tree
                if (clientProperty && !clientPropertyUnit) {
                    // Tiene property pero no propertyUnit
                    tree[propertyIndex].clientBusiness = business;
                    tree[propertyIndex].state = business.active;
                }

                if (clientPropertyUnit) {
                    const childIndex = tree[propertyIndex].children.findIndex(
                        child => child.key === clientPropertyUnit.id
                    );
                    if (childIndex !== -1) {
                        // Encontro el child en children del property
                        tree[propertyIndex].children[
                            childIndex
                        ].clientBusiness = business;
                        tree[propertyIndex].children[childIndex].state =
                            business.active;
                    }
                }
            }
        });
        setItems(tree);
    };

    /**
     * @description this effect check that entityId is not empty and if it changes, if not empty, call the function to CallDataEdit
     */
    useEffect(() => {
        if (entityId !== '') {
            CallDataEdit();
        }
    }, [entityId]);

    useEffect(() => {
        CallProperties();
    }, []);

    useEffect(() => {
        SetTree();
    }, [filteredProperties, clientBusiness]);

    useEffect(() => {
        const newPreviewItems: any[] = [];

        items.forEach(item => {
            const isActive = item.state === 'Active';
            const isReadOnly = item.state === 'ReadOnly';

            if (isActive || isReadOnly) {
                const newItem: any = {
                    label: item.label,
                    key: item.key,
                    state: item.state,
                    children:
                        item.children?.filter(
                            child =>
                                child.state === 'Active' ||
                                child.state === 'ReadOnly'
                        ) || null,
                    showChildren: false
                };

                newPreviewItems.push(newItem);
            } else if (item.state === 'Inactive') {
                item.children?.forEach((child: any) => {
                    if (
                        child.state === 'Active' ||
                        child.state === 'ReadOnly'
                    ) {
                        newPreviewItems.push({
                            label: child.label,
                            key: child.key,
                            state: child.state,
                            children: [],
                            showChildren: false
                        });
                    }
                });
            }
        });
        setPreviewItems(newPreviewItems);
    }, [items]);

    const ChangeState = (key: string, type: string) => {
        if (type === 'item') {
            setItems(prevItems =>
                prevItems.map(item => {
                    switch (item.state) {
                        case 'Inactive':
                            return item.key === key
                                ? {
                                      ...item,
                                      state: 'Active',
                                      modified: item.modified + 1
                                  }
                                : item;
                        case 'Active':
                            return item.key === key
                                ? {
                                      ...item,
                                      state: 'ReadOnly',
                                      modified: item.modified + 1
                                  }
                                : item;
                        case 'ReadOnly':
                            return item.key === key
                                ? {
                                      ...item,
                                      state: 'Inactive',
                                      modified: item.modified + 1
                                  }
                                : item;
                        default:
                            return item;
                    }
                })
            );
        } else if (type === 'child') {
            setItems(prevItems =>
                prevItems.map(item => {
                    if (item.children && item.children.length > 0) {
                        const updatedChildren = item.children.map(child => {
                            switch (child.state) {
                                case 'Inactive':
                                    return child.key === key
                                        ? {
                                              ...child,
                                              state: 'Active',
                                              modified: item.modified + 1
                                          }
                                        : child;
                                case 'Active':
                                    return child.key === key
                                        ? {
                                              ...child,
                                              state: 'ReadOnly',
                                              modified: item.modified + 1
                                          }
                                        : child;
                                case 'ReadOnly':
                                    return child.key === key
                                        ? {
                                              ...child,
                                              state: 'Inactive',
                                              modified: item.modified + 1
                                          }
                                        : child;
                                default:
                                    return child;
                            }
                        });
                        return { ...item, children: updatedChildren };
                    }
                    return item;
                })
            );
        }
    };

    function formatDate(date) {
        const year = date.getFullYear();
        const month = (parseInt(date.getMonth()) + 1)
            .toString()
            .padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const SaveChanges = async () => {
        const currentDate = new Date();
        const endDate = new Date(currentDate);
        endDate.setFullYear(currentDate.getFullYear() + 1);
        const startDateFormatted = formatDate(currentDate);
        const endDateFormatted = formatDate(endDate);

        // Filtrar los items con modified múltiplo de 3 o que tengan hijos con modified múltiplo de 3
        const filteredItems = items.filter(item => {
            // Revisar si el item o alguno de sus children tiene modified múltiplo de 3
            const itemHasValidModified = item.modified % 3 !== 0;
            const childrenHasValidModified = item.children?.some(
                child => child.modified % 3 !== 0
            );

            // Filtrar items que tengan modified múltiplo de 3 o hijos que lo tengan
            return itemHasValidModified || childrenHasValidModified;
        });

        // Ahora recorremos solo los elementos filtrados
        filteredItems.forEach(async item => {
            // Primero procesamos el item si su modified es múltiplo de 3
            if (item.modified > 0 && item.modified % 3 !== 0) {
                if (item.clientBusiness) {
                    item.clientBusiness.active = item.state;
                    const response = await GetData(
                        ApiClientBusinessId(item.clientBusiness.id),
                        HTTP_METHODS.PATCH,
                        item.clientBusiness
                    );
                    const { status, data } = response;
                    if (!status) {
                        ConfigToast.text = data.message;
                        ConfigToast.backColor = Colors.Error;
                        ConfigToast.title = 'Error';
                        setConfigToast(ConfigToast);
                    } else {
                        // Si status es true, modificar el item.modified a 0
                        item.modified = 0;
                    }
                } else if (
                    item.state === 'Active' ||
                    item.state === 'ReadOnly'
                ) {
                    const newClientBusiness = {
                        startDate: startDateFormatted,
                        endDate: endDateFormatted,
                        active: item.state,
                        client: entityId,
                        property: item.key,
                        propertyUnit: null,
                        gestor: null,
                        finalDisposal: null
                    };
                    const response = await GetData(
                        ApiClientBusiness,
                        HTTP_METHODS.POST,
                        newClientBusiness
                    );
                    const { status, data } = response;
                    if (!status) {
                        ConfigToast.text = data.message;
                        ConfigToast.backColor = Colors.Error;
                        ConfigToast.title = 'Error';
                        setConfigToast(ConfigToast);
                    } else {
                        // Si status es true, modificar el item.modified a 0 y asignar el clientBusiness creado
                        item.modified = 0;
                        item.clientBusiness = data; // Asignar el clientBusiness devuelto
                    }
                }
            }

            // Ahora procesamos los children si su modified es múltiplo de 3
            if (item.children && item.children.length > 0) {
                const filteredChildren = item.children.filter(
                    child => child.modified > 0 && child.modified % 3 !== 0
                );
                filteredChildren.forEach(async child => {
                    if (child.clientBusiness) {
                        child.clientBusiness.active = child.state;
                        const response = await GetData(
                            ApiClientBusinessId(child.clientBusiness.id),
                            HTTP_METHODS.PATCH,
                            child.clientBusiness
                        );
                        const { status, data } = response;
                        if (!status) {
                            ConfigToast.text = data.message;
                            ConfigToast.backColor = Colors.Error;
                            ConfigToast.title = 'Error';
                            setConfigToast(ConfigToast);
                        } else {
                            // Si status es true, modificar el child.modified a 0
                            child.modified = 0;
                        }
                    } else if (
                        child.state === 'Active' ||
                        child.state === 'ReadOnly'
                    ) {
                        const newClientBusiness = {
                            startDate: startDateFormatted,
                            endDate: endDateFormatted,
                            active: child.state,
                            client: entityId,
                            property: item.key,
                            propertyUnit: child.key,
                            gestor: null,
                            finalDisposal: null
                        };
                        const response = await GetData(
                            ApiClientBusiness,
                            HTTP_METHODS.POST,
                            newClientBusiness
                        );
                        const { status, data } = response;
                        if (!status) {
                            ConfigToast.text = data.message;
                            ConfigToast.backColor = Colors.Error;
                            ConfigToast.title = 'Error';
                            setConfigToast(ConfigToast);
                        } else {
                            // Si status es true, modificar el child.modified a 0 y asignar el clientBusiness creado
                            child.modified = 0;
                            child.clientBusiness = data; // Asignar el clientBusiness devuelto
                        }
                    }
                });
            }
        });
    };

    const handleChange = e => {
        e.preventDefault();
        const value = e.target.value;
        setSearchInput(value);
        if (value.length >= 1) {
            const filtered = properties.filter(property => {
                return property.name
                    .toLowerCase()
                    .includes(value.toLowerCase());
            });
            const filteredIds = filtered.map(property => property.id);
            setItems(prevItems =>
                prevItems.map(item => ({
                    ...item,
                    show: filteredIds.includes(item.key)
                }))
            );
        } else {
            setItems(prevItems =>
                prevItems.map(item => ({
                    ...item,
                    show: true
                }))
            );
        }
    };

    return (
        <>
            <ToastElement ref={toastManagerRef} />
            <AlertElement ref={alertManagerRef} />
            <SubNavBar
                type='settings'
                title={title}
                icon='settings/ClientIcon.svg'
                kind='settings'
                option='Client'
            ></SubNavBar>
            <div className='settings'>
                <Header
                    title={{ name: title, link: '/panel/clients' }}
                    icon='ClientsIconB.svg'
                    subtitle={{
                        name: entity?.name ?? '',
                        link: `/panel/clients/edit/${id}`
                    }}
                    subsubtitle={{ name: 'Inmuebles' }}
                />
                <div className='settings__grid-container'>
                    <div className='settings__grid-container__item'>
                        <div className='settings__grid-container__item__title title'>
                            {adminTitle}
                        </div>
                        <div className='settings__grid-container__item__title subtitle'>
                            {instructionsTitle}
                        </div>
                        <div
                            className='settings__grid-container__item__list'
                            style={{ backgroundColor: '#fff' }}
                        >
                            <div className='settings__grid-container__item__list__search'>
                                <input
                                    className='settings__grid-container__item__list__search__searchbar subtitle'
                                    type='text'
                                    placeholder='Buscar'
                                    value={searchInput}
                                    onChange={handleChange}
                                ></input>
                            </div>
                            {items.map(
                                item =>
                                    item.show && (
                                        <div
                                            className='settings__grid-container__item__list__element subtitle'
                                            key={item.key}
                                        >
                                            <div
                                                className='settings__grid-container__item__list__element__data subtitle'
                                                key={item.key}
                                            >
                                                <button
                                                    onClick={() =>
                                                        ChangeState(
                                                            item.key,
                                                            'item'
                                                        )
                                                    }
                                                    className='settings__grid-container__item__list__element__data__btn'
                                                >
                                                    {item.state ===
                                                    'Inactive' ? (
                                                        <CloseOutlined
                                                            rev={''}
                                                        />
                                                    ) : item.state ===
                                                      'Active' ? (
                                                        <CheckOutlined
                                                            rev={''}
                                                        />
                                                    ) : (
                                                        <EyeOutlined rev={''} />
                                                    )}
                                                </button>
                                                <div className='settings__grid-container__item__list__element__data__text'>
                                                    {item.label}
                                                </div>
                                                {item.children &&
                                                    item.children.length >
                                                        0 && (
                                                        <button
                                                            className='settings__grid-container__item__list__element__data__dropdown'
                                                            onClick={() =>
                                                                setItems(
                                                                    prevItems =>
                                                                        prevItems.map(
                                                                            prevItem =>
                                                                                prevItem.key ===
                                                                                item.key
                                                                                    ? {
                                                                                          ...prevItem,
                                                                                          showChildren:
                                                                                              !prevItem.showChildren
                                                                                      }
                                                                                    : prevItem
                                                                        )
                                                                )
                                                            }
                                                        >
                                                            {item.showChildren ? (
                                                                <CaretUpOutlined
                                                                    rev={''}
                                                                />
                                                            ) : (
                                                                <CaretDownOutlined
                                                                    rev={''}
                                                                />
                                                            )}
                                                        </button>
                                                    )}
                                            </div>
                                            {item.showChildren &&
                                                item.children &&
                                                item.children.length > 0 &&
                                                item.children.map(child => (
                                                    <div
                                                        className='settings__grid-container__item__list__element__data subtitle'
                                                        style={{
                                                            marginLeft: '5%',
                                                            width: '95%'
                                                        }}
                                                        key={child.key}
                                                    >
                                                        <button
                                                            onClick={() =>
                                                                ChangeState(
                                                                    child.key,
                                                                    'child'
                                                                )
                                                            }
                                                        >
                                                            {child.state ===
                                                            'Inactive' ? (
                                                                <CloseOutlined
                                                                    rev={''}
                                                                />
                                                            ) : child.state ===
                                                              'Active' ? (
                                                                <CheckOutlined
                                                                    rev={''}
                                                                />
                                                            ) : (
                                                                <EyeOutlined
                                                                    rev={''}
                                                                />
                                                            )}
                                                        </button>
                                                        <div className='settings__grid-container__item__list__element__data__text'>
                                                            {child.label}
                                                        </div>
                                                    </div>
                                                ))}
                                        </div>
                                    )
                            )}
                        </div>
                        <div className='settings__grid-container__item__btns subtitle'>
                            <button
                                className='settings__grid-container__item__btns__item button'
                                onClick={SetTree}
                            >
                                {cancelBtn}
                            </button>
                            <button
                                className='settings__grid-container__item__btns__item button'
                                onClick={SaveChanges}
                            >
                                {saveBtn}
                            </button>
                        </div>
                    </div>
                    <div
                        className='settings__grid-container__item_right'
                        style={{ padding: '0% 5%' }}
                    >
                        <div className='settings__grid-container__item_right__title title'>
                            {previewTitle}
                        </div>
                        <div className='settings__grid-container__item_right__title subtitle'>
                            <div className='settings__grid-container__item_right__title__text'>
                                {previewSubtitle}
                            </div>
                            <ConfigProvider
                                theme={{
                                    components: {
                                        Switch: {
                                            colorPrimary: '#2994e6',
                                            colorBorder: '#2994e6',
                                            colorTextTertiary: '#a2a2a3'
                                        }
                                    }
                                }}
                            >
                                <Tooltip
                                    title={
                                        <span className='main_menu__grid-container__tooltip'>
                                            Haz click para mostrar/ocultar
                                            aquellos de solo visualización
                                        </span>
                                    }
                                    color='#fff'
                                >
                                    <Switch
                                        className='profile-body__edit-grid-container__item__edit-info-container__not__option__toggle'
                                        size='small'
                                        defaultChecked
                                        onChange={() =>
                                            setShowReadOnlys(!showReadOnlys)
                                        }
                                    />
                                </Tooltip>
                            </ConfigProvider>
                        </div>
                        <div className='settings__grid-container__item_right__preview'>
                            {previewItems.map(item =>
                                item.state !== 'ReadOnly' || !showReadOnlys ? (
                                    <div
                                        className='settings__grid-container__item__list__element subtitle'
                                        key={item.key}
                                        style={{
                                            borderBottom: '0.2px solid #2994E6'
                                        }}
                                    >
                                        <div
                                            className={`settings__grid-container__item__list__element__${
                                                item.state === 'ReadOnly'
                                                    ? 'readOnly'
                                                    : 'data'
                                            } subtitle`}
                                            key={item.key}
                                            style={{ color: '#2994E6' }}
                                        >
                                            {item.state === 'ReadOnly' ? (
                                                <EyeOutlined rev={''} />
                                            ) : (
                                                <BorderOutlined rev={''} />
                                            )}
                                            <div
                                                className={`settings__grid-container__item__list__element__${
                                                    item.state === 'ReadOnly'
                                                        ? 'readOnly'
                                                        : 'data'
                                                }__text`}
                                            >
                                                {item.label}
                                            </div>
                                            {item.children &&
                                                item.children.length > 0 && (
                                                    <button
                                                        className={`settings__grid-container__item__list__element__${
                                                            item.state ===
                                                            'ReadOnly'
                                                                ? 'readOnly'
                                                                : 'data'
                                                        }__dropdown`}
                                                        onClick={() =>
                                                            setPreviewItems(
                                                                prevItems =>
                                                                    prevItems.map(
                                                                        prevItem =>
                                                                            prevItem.key ===
                                                                            item.key
                                                                                ? {
                                                                                      ...prevItem,
                                                                                      showChildren:
                                                                                          !prevItem.showChildren
                                                                                  }
                                                                                : prevItem
                                                                    )
                                                            )
                                                        }
                                                    >
                                                        {item.showChildren ? (
                                                            <CaretUpOutlined
                                                                rev={''}
                                                            />
                                                        ) : (
                                                            <CaretDownOutlined
                                                                rev={''}
                                                            />
                                                        )}
                                                    </button>
                                                )}
                                        </div>
                                        {item.showChildren &&
                                            item.children &&
                                            item.children.length > 0 &&
                                            item.children.map(
                                                (child, index) => (
                                                    <div
                                                        className={`settings__grid-container__item__list__element__${
                                                            child.state ===
                                                            'ReadOnly'
                                                                ? 'readOnly'
                                                                : 'data'
                                                        } subtitle`}
                                                        style={{
                                                            marginLeft: '5%',
                                                            color: '#2994E6',
                                                            width: '95%'
                                                        }}
                                                        key={child.key}
                                                    >
                                                        {child.state ===
                                                        'ReadOnly' ? (
                                                            <EyeOutlined
                                                                rev={''}
                                                            />
                                                        ) : (
                                                            <BorderOutlined
                                                                rev={''}
                                                            />
                                                        )}
                                                        <div
                                                            className={`settings__grid-container__item__list__element__${
                                                                child.state ===
                                                                'ReadOnly'
                                                                    ? 'readOnly'
                                                                    : 'data'
                                                            }__text`}
                                                        >
                                                            {child.label}
                                                        </div>
                                                    </div>
                                                )
                                            )}
                                    </div>
                                ) : (
                                    item.children &&
                                    item.children.length > 0 &&
                                    item.children.map(
                                        child =>
                                            child.state !== 'ReadOnly' && (
                                                <div
                                                    className='settings__grid-container__item__list__element subtitle'
                                                    key={child.key}
                                                    style={{
                                                        borderBottom:
                                                            '0.2px solid #2994E6'
                                                    }}
                                                >
                                                    <div
                                                        className={`settings__grid-container__item__list__element__data subtitle`}
                                                        style={{
                                                            color: '#2994E6'
                                                        }}
                                                    >
                                                        <BorderOutlined
                                                            rev={''}
                                                        />
                                                        <div
                                                            className={`settings__grid-container__item__list__element__data__text`}
                                                        >
                                                            {child.label}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                    )
                                )
                            )}
                        </div>
                        <div className='settings__grid-container__item_right__title title'>
                            {legendTitle}
                        </div>
                        <div className='settings__grid-container__item_right__legend'>
                            <div className='settings__grid-container__item_right__legend__item'>
                                <CloseOutlined rev={''} />
                                <div className='settings__grid-container__item_right__legend__item__text subtitle'>
                                    {crossLegend}
                                </div>
                            </div>
                            <div className='settings__grid-container__item_right__legend__item'>
                                <CheckOutlined rev={''} />
                                <div className='settings__grid-container__item_right__legend__item__text subtitle'>
                                    {checkLegend}
                                </div>
                            </div>
                            <div className='settings__grid-container__item_right__legend__item'>
                                <EyeOutlined rev={''} />
                                <div className='settings__grid-container__item_right__legend__item__text subtitle'>
                                    {eyeLegend}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {LoadingData && <LoaderElement />}
            </div>
        </>
    );
};

export default ClientProperties;
