import './bagCard.scss';
import ButtonBody from '../../../button/button';
import { lang } from '../../../langs';
import { UserStoreInterface } from '../../../../store/slices/users/user.interface';
import { useAppSelector } from '../../../../store/hooks';

interface Props {
    logo: string;
    name: string;
    ud: string;
    percentage: number;
}

const BagCard = ({ logo, name, ud, percentage }: Props) => {
    const { preferences }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const defaultLang: string = preferences.lang;
    const key = defaultLang;
    const {
        [key as keyof typeof lang]: {
            controlPanelComponents: {
                garbageControl: {
                    bagCard: { stockText, repositionButton }
                }
            }
        }
    } = lang;
    return (
        <div className='bagcard'>
            <div className='bagcard__header'></div>
            <div className='bagcard__body'>
                <img className='bagcard__body__logo' src={logo} />
                {percentage < 20 ? (
                    <img
                        className='bagcard__body__alert'
                        src='/svg-icons/AlertIcon.svg'
                    />
                ) : (
                    <></>
                )}
            </div>
            <div className='bagcard__content'>
                <div className='bagcard__content__name'>{name}</div>
                <div className='bagcard__content__kg'>{ud}</div>
                <div className='bagcard__content__ver'>
                    {percentage}% {stockText}
                </div>
            </div>
            {percentage > 20 ? (
                <></>
            ) : (
                <ButtonBody
                    able='true'
                    kind='button button-body--reposition'
                    text={`+ ${repositionButton}`}
                ></ButtonBody>
            )}
        </div>
    );
};

export default BagCard;
