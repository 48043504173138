import { useEffect, useState } from 'react';

interface Search {
    search: string;
}

interface State {
    result?: unknown;
    dataForm?: Search;
    setDataForm?: unknown;
}

export default function useFilters(
    keys: string[] = [],
    data = [],
    page = 1,
    callback: any = null
) {
    const formSearch: Search = {
        search: ''
    };

    const [dataForm, setDataForm] = useState(formSearch);
    const [result, setResult] = useState<State>({
        dataForm: formSearch,
        setDataForm,
        result: data
    });

    /**
     * In this section the data is used and the fields that are sent as the keys parameter are extracted,
     * unique values obtained and the data is sent so that it can be filtered and a comboBox or an inputText is created,
     * the logic is to extract the data Depending on the keys of the object, you can also extract data from objects with nested arrays.
     */
    const FilterData = () => {
        const filteredOb: any = [];
        if (dataForm.search !== undefined) {
            const text = dataForm.search;
            const { length } = keys;
            const arrFilters: number[] = [];
            for (let i = 0; i < length; i += 1) {
                arrFilters.push(i);
            }
            data.forEach((fil: any) => {
                arrFilters.forEach(el => {
                    const field: string = keys[el];
                    if (fil[field]) {
                        if (
                            fil[field]
                                .toLowerCase()
                                .indexOf(text.toLowerCase()) > -1
                        ) {
                            filteredOb.push(fil);
                        }
                    }
                });
            });
            const arrayUniqueByKey = Array.from(
                new Set(filteredOb.map(item => item))
            );
            if (callback !== null) {
                callback(page, arrayUniqueByKey);
            }

            setResult({ result: filteredOb, dataForm, setDataForm });
            return { page, filteredOb };
        }
        if (callback !== null) {
            callback(page, filteredOb);
        }
        return { page };
    };

    useEffect(() => {
        if (dataForm.search !== '' || dataForm.search !== null) {
            FilterData();
        }
    }, [dataForm]);

    return { ...result, FilterData };
}
