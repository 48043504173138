import { useEffect, useState } from 'react';
//  <--HOOKS--> //
import { useToast } from '../../../../config/hooks/useToast';
import { useToastHook } from '../../../../store/slices/toast/toast.hook';
import { useAlert } from '../../../../config/hooks/useAlert';
import { useCallApi } from '../../../../config/hooks/useCallApi';
import { HTTP_METHODS } from '../../../../config/hooks/useCallApi/constants';
//  <--COMPONENTS--> //
import TableComponent from '../../../../components/table';
import { RoleTable } from '../../../../components/table/data/columns_table';
//  <--SERVICES--> //
import { ApiUserRole } from '../../../../config/service';
//  <--INTERFACE--> //
import { RoleInterface } from '../../../../interface';
import { constantsTable } from '../constants';
//  <--REDUX--> //
import { UserStoreInterface } from '../../../../store/slices/users/user.interface';
import { useAppSelector } from '../../../../store/hooks';

interface Props {
    refresh?: boolean;
}

const RoleTableComponent = ({ refresh }: Props) => {
    const { ToastConfig } = useToastHook();
    const { Colors } = useToast();
    const { preferences }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const defaultLang: string = preferences.lang;
    const { AlertElement, alertManagerRef } = useAlert({ defaultLang });
    const { GetData, LoadingData, LoaderElement } = useCallApi();
    const [dataList, setDataList] = useState<RoleInterface[]>([]);
    const { keysFilter } = constantsTable;

    /**
     * @description
     * @function CallData is a function that returns a promise with the ROLE list
     */
    const CallData = async () => {
        const response = await GetData(ApiUserRole, HTTP_METHODS.GET);
        const { data, status } = response;
        setDataList(data);
        if (!status) {
            ToastConfig({
                message: data.message,
                color: Colors.Error
            });
        }
    };

    /**
     * @description
     * Function that is executed when the component is mounted, calls the function that returns the ROLE list
     */
    useEffect(() => {
        CallData();
    }, [refresh]);

    return (
        <>
            <AlertElement ref={alertManagerRef} />
            <TableComponent
                data={dataList}
                headers={RoleTable}
                showFilters={true}
                keysFilter={keysFilter}
                showPagination={true}
            />
            {LoadingData && <LoaderElement />}
        </>
    );
};

export default RoleTableComponent;
