import '../sinader.scss';
// <--COMPONENTS--> //
// <--REDUX--> //
import { useAppSelector } from '../../../store/hooks';
import { UserStoreInterface } from '../../../store/slices/users/user.interface';
// <--INTERFACE--> //
import { lang } from '../../langs';

const Legend = () => {
    const { preferences }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const defaultLang: string = preferences.lang;
    const key = defaultLang;
    const {
        [key as keyof typeof lang]: {
            sinader: {
                autocompleteArray: {
                    legend: { primaryLight, secondaryLight, primary, secondary }
                }
            }
        }
    } = lang;

    return (
        <div className='sinader__legend'>
            <div className='sinader__legend__item'>
                <div className='sinader__legend__item__primary-light'></div>
                <div className='subtitle'>{primaryLight}</div>
            </div>
            <div className='sinader__legend__item'>
                <div className='sinader__legend__item__primary'></div>
                <div className='subtitle'>{primary}</div>
            </div>
            <div className='sinader__legend__item'>
                <div className='sinader__legend__item__secondary-light'></div>
                <div className='subtitle'>{secondaryLight}</div>
            </div>
            <div className='sinader__legend__item'>
                <div className='sinader__legend__item__secondary'></div>
                <div className='subtitle'>{secondary}</div>
            </div>
        </div>
    );
};

export default Legend;
