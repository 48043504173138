import './styles/my-profile.scss';
import ButtonBody from '../../components/button/button';
import SubNavBar from '../../components/layout/main/subnavbar/subnavbar';
//  <--REDUX--> //
import { useAppSelector } from '../../store/hooks';
import { UserStoreInterface } from '../../store/slices/users/user.interface';
import { lang } from '../langs';
import { ROUTES } from '../../config/routes/routesList';

const MyProfile = () => {
    const { preferences, userData }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const defaultLang: string = preferences.lang;
    const key = defaultLang;
    const {
        [key as keyof typeof lang]: {
            pageMyProfile: {
                profile: {
                    title,
                    /* termsAndConditions,
                    privacyPolicy,
                    exportUserData,
                    clearMyData, */
                    editButton,
                    changePassButton
                    /* languageButton */
                }
            }
        }
    } = lang;

    return (
        <>
            <SubNavBar
                type='settings'
                title={title}
                icon='PersonIconB.svg'
            ></SubNavBar>
            <div className='profile-body__summary'>
                <div className='profile-body__summary__user'>
                    <div className='profile-body__summary__user__img-container'>
                        <img
                            className='profile-body__summary__user__img-container__img'
                            src='/svg-icons/ProfileIcon.svg'
                        />
                    </div>
                    <div className='profile-body__summary__user__info-container'>
                        <div className='profile-body__summary__user__info-container__name title'>
                            {userData?.name} {userData?.lastName}
                        </div>
                        <div className='profile-body__summary__user__info-container__rol title'>
                            {userData?.roles[0].name}
                        </div>
                        <div className='profile-body__summary__user__info-container__mail title'>
                            {userData?.email}
                        </div>
                        <div className='profile-body__summary__user__info-container__phone title'>
                            {userData?.phone}
                        </div>
                    </div>
                    <div className='profile-body__summary__others'>
                        {/* <a className='profile-body__summary__others__link'>
                            {termsAndConditions}
                        </a>
                        <a className='profile-body__summary__others__link'>
                            {privacyPolicy}
                        </a>
                        <a className='profile-body__summary__others__link'>
                            {exportUserData}
                        </a>
                        <a className='profile-body__summary__others__link'>
                            {clearMyData}
                        </a> */}
                    </div>
                </div>
                {/* <ButtonBody
                    able='true'
                    kind='button button-body--language'
                    text={languageButton}
                    profile='#edit-grid-container'
                ></ButtonBody> */}
                <ButtonBody
                    able='true'
                    kind='button button-body--edit-profile'
                    text={changePassButton}
                    path={ROUTES.changePass}
                    profile='#edit-grid-container'
                ></ButtonBody>
                <ButtonBody
                    able='true'
                    kind='button button-body--change-pass'
                    text={editButton}
                    path={ROUTES.editProfile}
                    profile='#edit-grid-container'
                ></ButtonBody>
            </div>
        </>
    );
};

export default MyProfile;
