export const ERRORS = {
    ERR_NETWORK: 'Ha ocurrido un error de conexión'
};

export const REST_ERRORS = {
    101: { error: 'SWITCHING_PROTOCOLS', esMessage: '' },
    102: { error: 'PROCESSING', esMessage: '' },
    103: { error: 'EARLYHINTS', esMessage: '' },
    200: { error: 'OK', esMessage: 'Solicitud exitosa' },
    201: { error: 'CREATED', esMessage: 'Registro creado con éxito' },
    202: { error: 'ACCEPTED', esMessage: '' },
    203: { error: 'NON_AUTHORITATIVE_INFORMATION', esMessage: '' },
    204: { error: 'NO_CONTENT', esMessage: '' },
    205: { error: 'RESET_CONTENT', esMessage: '' },
    206: { error: 'PARTIAL_CONTENT', esMessage: '' },
    300: { error: 'AMBIGUOUS', esMessage: '' },
    301: { error: 'MOVED_PERMANENTLY', esMessage: '' },
    302: { error: 'FOUND', esMessage: '' },
    303: { error: 'SEE_OTHER', esMessage: '' },
    304: { error: 'NOT_MODIFIED', esMessage: '' },
    307: { error: 'TEMPORARY_REDIRECT', esMessage: '' },
    308: { error: 'PERMANENT_REDIRECT', esMessage: '' },
    400: { error: 'BAD_REQUEST', esMessage: 'Error al solicitar el recurso' },
    401: {
        error: 'UNAUTHORIZED',
        esMessage: 'Es necesario autenticar para acceder a este recurso'
    },
    402: { error: 'PAYMENT_REQUIRED', esMessage: 'El pago es requerido' },
    403: {
        error: 'FORBIDDEN',
        esMessage:
            'No posee los permisos necesarios para acceder a este recurso'
    },
    404: { error: 'NOT_FOUND', esMessage: 'Recurso no encontrado' },
    405: { error: 'METHOD_NOT_ALLOWED', esMessage: '' },
    406: { error: 'NOT_ACCEPTABLE', esMessage: '' },
    407: { error: 'PROXY_AUTHENTICATION_REQUIRED', esMessage: '' },
    408: { error: 'REQUEST_TIMEOUT', esMessage: '' },
    409: { error: 'CONFLICT', esMessage: '' },
    410: { error: 'GONE', esMessage: '' },
    411: { error: 'LENGTH_REQUIRED', esMessage: '' },
    412: { error: 'PRECONDITION_FAILED', esMessage: '' },
    413: { error: 'PAYLOAD_TOO_LARGE', esMessage: '' },
    414: { error: 'URI_TOO_LONG', esMessage: '' },
    415: { error: 'UNSUPPORTED_MEDIA_TYPE', esMessage: '' },
    416: { error: 'REQUESTED_RANGE_NOT_SATISFIABLE', esMessage: '' },
    417: { error: 'EXPECTATION_FAILED', esMessage: '' },
    418: { error: 'I_AM_A_TEAPOT', esMessage: '' },
    421: { error: 'MISDIRECTED', esMessage: '' },
    422: { error: 'UNPROCESSABLE_ENTITY', esMessage: '' },
    424: { error: 'FAILED_DEPENDENCY', esMessage: '' },
    428: { error: 'PRECONDITION_REQUIRED', esMessage: '' },
    429: { error: 'TOO_MANY_REQUESTS', esMessage: '' },
    500: {
        error: 'INTERNAL_SERVER_ERROR',
        esMessage: 'Error interno en el servidor'
    },
    501: { error: 'NOT_IMPLEMENTED', esMessage: '' },
    502: { error: 'BAD_GATEWAY', esMessage: 'Puerta de enlace sin respuesta' },
    503: {
        error: 'SERVICE_UNAVAILABLE',
        esMessage: 'El servidor no está listo para manejar su solicitud'
    },
    504: { error: 'GATEWAY_TIMEOUT', esMessage: '' },
    505: { error: 'HTTP_VERSION_NOT_SUPPORTED', esMessage: '' }
};
