export const indexesPosibilities = [
    'date' /* ,
    'propertyId',
    'materialId',
    'propertyUnit'
];

export const indexesPosibilities2 = ['date' /* , 'propertyUnit', 'supply' */
];

export const initValues = {
    labels: [],
    datasets: []
};

export const bzeroIndex = {
    label: 'Índice BZero',
    data: [
        '1757',
        '1867',
        '1677',
        '1517',
        '1607',
        '1587',
        '757',
        '1489',
        '1517',
        '1837',
        '1757',
        '1867',
        '1677',
        '1517',
        '1607',
        '1587',
        '757',
        '1489',
        '1517',
        '1837'
    ]
};
