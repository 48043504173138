import './garbageControl.scss';
import BagCard from './bagCard/bagCard';
import { lang } from '../../langs';
import { UserStoreInterface } from '../../../store/slices/users/user.interface';
import { useAppSelector } from '../../../store/hooks';
import { Tooltip } from 'antd';
import ButtonBody from '../../button/button';

const GarbageControlCard = () => {
    const { preferences }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const defaultLang: string = preferences.lang;
    const key = defaultLang;
    const {
        [key as keyof typeof lang]: {
            controlPanelComponents: {
                garbageControl: {
                    title,
                    downloadButton,
                    automateButton,
                    bagsText
                }
            }
        }
    } = lang;
    return (
        <div className='garbage-card'>
            <Tooltip
                title={
                    <span className='main_menu__grid-container__tooltip'>
                        PRÓXIMAMENTE
                    </span>
                }
                color='#fff'
            >
                <div className='garbage-card__title disabled'>
                    {title}
                    <ButtonBody
                        able='true'
                        kind='button button-body--download'
                        text={downloadButton}
                    ></ButtonBody>
                    <ButtonBody
                        able='true'
                        kind='button button-body--download'
                        text={automateButton}
                    ></ButtonBody>
                </div>
                <div className='garbage-card__grid-container disabled'>
                    <BagCard
                        logo='/svg-icons/BagIcon.svg'
                        name={`${bagsText} 30L`}
                        ud=' 90 ud.'
                        percentage={42}
                    />
                    <BagCard
                        logo='/svg-icons/BagIcon.svg'
                        name={`${bagsText} 80L`}
                        ud=' 90 ud.'
                        percentage={42}
                    />
                    <BagCard
                        logo='/svg-icons/BagIcon.svg'
                        name={`${bagsText} 140L`}
                        ud=' 12 ud.'
                        percentage={8}
                    />
                    <BagCard
                        logo='/svg-icons/BagIcon.svg'
                        name={`${bagsText} 220L`}
                        ud=' 90 ud.'
                        percentage={42}
                    />
                </div>
                <div className='disabled'>
                    <ButtonBody
                        able='true'
                        kind='button button-body--viewevol'
                        text='Ver evolución'
                    ></ButtonBody>
                </div>
            </Tooltip>
        </div>
    );
};

export default GarbageControlCard;
