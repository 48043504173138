import './ecoequivalencesCard.scss';
import EcoequivalenceItem from './ecoequivalenceItem/ecoequivalenceItem';
import { useState, useEffect } from 'react';
import { lang } from '../../langs';
import { UserStoreInterface } from '../../../store/slices/users/user.interface';
import { useAppSelector } from '../../../store/hooks';
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

interface Props {
    rangeCertificate?: number[];
    certificate?: boolean;
    data?: any[];
}

interface EcoequivalenceItemInterface {
    label: string;
    total: number;
    eqlabel: string;
    equivalent: number;
    unit: string;
    logo: string;
    type: string;
}

const EcoequivalencesCard = ({
    certificate,
    data,
    rangeCertificate
}: Props) => {
    const { preferences }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const defaultLang: string = preferences.lang;
    const key = defaultLang;
    const {
        [key as keyof typeof lang]: {
            dataCenterComponents: {
                ecoequivalencesCard: { title, subtitle, detailMessage },
                recolectionCard: {
                    months,
                    lastMonth,
                    lastQuarter,
                    historic
                    /* selectedRange */
                }
            }
        }
    } = lang;
    const [items, setItems] = useState<EcoequivalenceItemInterface[]>([]);
    const [period, setPeriod] = useState<number>(2);
    const [month, setMonth] = useState('');
    const [year, setYear] = useState(0);

    useEffect(() => {
        const actualDate = new Date();
        const actualDay = actualDate.getDate();
        const dateCard = new Date(actualDate); // Copia de la fecha actual
        if (actualDay > 13) {
            dateCard.setMonth(actualDate.getMonth() - 1);
        } else {
            dateCard.setMonth(actualDate.getMonth() - 2);
        }
        const numberMonth = dateCard.getMonth();
        const yearResult = dateCard.getFullYear();
        const monthResult = months[numberMonth];
        setMonth(monthResult);
        setYear(yearResult);
    }, []);

    useEffect(() => {
        if (!data || data.length === 0) return;

        const getRange = (dataLength: number): number[] => {
            const actualDate = new Date();
            const actualDay = actualDate.getDate();

            if (rangeCertificate) {
                // Usar rangeCertificate si está definido
                return [0, rangeCertificate[1]];
            }

            if (period === 0) {
                // Si el periodo es 0, tomar el último o penúltimo valor según el día del mes
                return actualDay > 10
                    ? [dataLength - 2, dataLength - 1] // Último valor
                    : [dataLength - 3, dataLength - 2]; // Penúltimo valor
            }

            if (period === 1) {
                // Si el periodo es 1, obtener los últimos 3 o los penúltimos 3 valores según el día del mes
                return actualDay > 10
                    ? [dataLength - 3, dataLength - 1] // Últimos 3 valores
                    : [dataLength - 4, dataLength - 2]; // Penúltimos 3 valores
            }

            // Si no hay un periodo definido, devolver todo el rango
            return [0, dataLength - 1];
        };

        const processDataItem = (item: any, range: number[]): any => {
            const totalInRange = item.total.slice(range[0], range[1] + 1);
            const equivalentInRange = item.equivalent.slice(
                range[0],
                range[1] + 1
            );

            const newTotal = totalInRange.reduce(
                (acc: number, curr: string) => acc + parseFloat(curr),
                0
            );
            const newEquivalent = equivalentInRange.reduce(
                (acc: number, curr: string) => acc + parseFloat(curr),
                0
            );

            const getItemDetails = (type: string) => {
                switch (type) {
                    case 'trees':
                        return {
                            label: 'Árboles salvados',
                            unit: '',
                            eqlabel: ' resmas de papel',
                            logo: '/svg-icons/eco-equivalences/trees.svg'
                        };
                    case 'co2':
                        return {
                            label: 'CO2 no emitido',
                            unit: newTotal > 1000 ? 'ton' : 'kg',
                            eqlabel: ' autos sin salir por un día',
                            logo: '/svg-icons/eco-equivalences/co2.svg'
                        };
                    case 'energy':
                        return {
                            label: 'Energía evitada',
                            unit: newTotal > 1000 ? 'MWh' : 'kWh',
                            eqlabel: ' meses de consumo de un hogar',
                            logo: '/svg-icons/eco-equivalences/energy.svg'
                        };
                    case 'water':
                        return {
                            label: 'Agua evitada',
                            unit: newTotal > 1000 ? 'm³' : 'l',
                            eqlabel: ' horas regando',
                            logo: '/svg-icons/eco-equivalences/water.svg'
                        };
                    default:
                        return {};
                }
            };

            const itemDetails = getItemDetails(item.type);

            return {
                ...item,
                total:
                    item.type === 'co2' ||
                    item.type === 'energy' ||
                    item.type === 'water'
                        ? newTotal > 1000
                            ? newTotal / 1000
                            : newTotal
                        : newTotal,
                equivalent: newEquivalent,
                ...itemDetails
            };
        };

        const dataLength = data?.[0]?.total?.length || 0;
        const range = getRange(dataLength);

        const dataSummaryComplete = data.map(item =>
            processDataItem(item, range)
        );
        setItems(dataSummaryComplete);
    }, [data, period, rangeCertificate]);

    function getLastQuarter(month, months) {
        const monthIndex = months.indexOf(month);
        if (monthIndex === -1) {
            return 'No válido';
        }
        const lastQuarter =
            monthIndex - 2 < 0
                ? parseInt(months.length) + (monthIndex - 2)
                : monthIndex - 2;
        return `${months[lastQuarter]} -${` `}${month}`;
    }

    return (
        <div className='ecoequivalences-card'>
            {!certificate && (
                <>
                    <div className='ecoequivalences-card__titulo-card title'>
                        {title}
                    </div>
                    <div className='ecoequivalences-card__subtitulo-card subtitle'>
                        <div className='ecoequivalences-card__subtitulo-card__subtitle subtitle'>
                            {subtitle}:{' '}
                            {period === 0
                                ? month
                                : period === 1
                                ? getLastQuarter(month, months)
                                : historic}{' '}
                            {(period === 0 || period === 1) && year}
                            <Tooltip
                                title={
                                    <span className='main_menu__grid-container__tooltip'>
                                        {detailMessage}
                                    </span>
                                }
                                color='#fff'
                            >
                                <InfoCircleOutlined
                                    className='ecoequivalences-card__subtitulo-card__subtitle__icon'
                                    rev={''}
                                />
                            </Tooltip>
                        </div>
                        <div className='ecoequivalences-card__subtitulo-card__date subtitle'>
                            <select
                                className='ecoequivalences-card__subtitulo-card__date__select subtitle'
                                onChange={e =>
                                    setPeriod(e.target.selectedIndex)
                                }
                                defaultValue={2}
                            >
                                <option className='subtitle' value={0}>
                                    {lastMonth}
                                </option>
                                <option className='subtitle' value={1}>
                                    {lastQuarter}
                                </option>
                                <option className='subtitle' value={2}>
                                    {historic}
                                </option>
                                {/* <option className='subtitle'>{selectedRange}</option> */}
                            </select>
                        </div>
                    </div>
                </>
            )}
            <div
                className={
                    certificate
                        ? 'ecoequivalences-card__line-container'
                        : 'ecoequivalences-card__grid-container'
                }
            >
                {items.map((item, index) => {
                    return (
                        <EcoequivalenceItem
                            key={item.type}
                            logo={item.logo}
                            name={item.label}
                            kg={`${item.total} `}
                            eqlabel={item.eqlabel}
                            eq={`${item.equivalent}`}
                            unit={item.unit}
                            type={item.type}
                            certificate={certificate}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default EcoequivalencesCard;
