import './button.scss';
import { useNavigate } from 'react-router';

interface Props {
    kind: string;
    text: string;
    able?: string;
    path?: string;
    profile?: string;
    /*     route?: string; */
}

const ButtonBody = ({
    kind,
    text,
    able,
    path,
    profile /* , route */
}: Props) => {
    const navigate = useNavigate();
    const Redirect = () => {
        if (path) {
            navigate(path);
        } else {
            navigate(-1);
        }
    };

    return (
        <>
            {profile ? (
                <button className={kind} onClick={Redirect}>
                    <a className='link' href={profile}>
                        {text}
                    </a>
                </button>
            ) : (
                <button className={kind} onClick={Redirect}>
                    {text}
                </button>
            )}
        </>
    );
};

export default ButtonBody;
