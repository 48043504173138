import '../../settings.scss';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Link, useNavigate } from 'react-router-dom';
//  <--COMPONENTS--> //
import SubNavBar from '../../../../components/layout/main/subnavbar/subnavbar';
import Header from '../../../../components/header/header';
import MaterialCreateComponent from './create-edit-component';
import MaterialsTableComponent from '../components/materials-table';
import ButtonBody from '../../../../components/button/button';
//  <--HOOKS--> //
import { useCallApi } from '../../../../config/hooks/useCallApi';
import { HTTP_METHODS } from '../../../../config/hooks/useCallApi/constants';
import { useToast } from '../../../../config/hooks/useToast';
import { useAlert } from '../../../../config/hooks/useAlert';
//  <--REDUX--> //
import { useAppSelector } from '../../../../store/hooks';
import { UserStoreInterface } from '../../../../store/slices/users/user.interface';
//  <--INTERFACE--> //
import { MaterialKindInterface } from '../../../../interface';
//  <--SERVICES--> //
import { ApiMaterialKind, ApiMaterialId } from '../../../../config/service';
//  <--OTHERS--> //
import { lang } from '../../../langs';
import '../../../../scss/global/global.scss';

const MaterialCreate = () => {
    const { GetData, LoadingData, LoaderElement } = useCallApi();
    const {
        ConfigToast,
        setConfigToast,
        ToastElement,
        toastManagerRef,
        Colors
    } = useToast();
    const { id } = useParams();
    const { preferences }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const defaultLang: string = preferences.lang;
    const {
        [defaultLang as keyof typeof lang]: {
            pageMaterial: {
                title,
                createTitle,
                adminTitle,
                option1,
                option2,
                editTitle,
                detailTitle,
                deleteMessage,
                deleteTitle,
                newTitle
            }
        }
    } = lang;
    const {
        AlertElement,
        alertManagerRef,
        ConfigAlert,
        setConfigAlert,
        Defaults
    } = useAlert({ defaultLang });
    const [materialKindList, setMaterialKindList] = useState<
        MaterialKindInterface[]
    >([]);
    const [manageBy, setManageBy] = useState('Materials');
    const [detailPage, setDetailPage] = useState<boolean>(true);
    const navigate = useNavigate();

    const Edit = () => {
        setDetailPage(false);
    };

    /**
     * @description function that is executed when the delete button is pressed
     * @function Delete is a function that launches an alert to confirm the deletion of a COMPANY
     * @param id is a string that contains the id of the COMPANY to be deleted
     */
    const Delete = async () => {
        ConfigAlert.title = Defaults.titles.Delete;
        ConfigAlert.message = deleteMessage;
        ConfigAlert.okButtonText = Defaults.buttons.Ok.Accept;
        ConfigAlert.okButtonController = { func: ConfirmDelete, param: id };
        ConfigAlert.cancelButtonText = Defaults.buttons.Cancel.Cancel;
        setConfigAlert(ConfigAlert);
    };

    /**
     * @description function that is executed when the okDelete button is pressed
     * @function Delete is a function that deletes a COMPANY
     * @param id is a string that contains the id of the COMPANY to be deleted
     */
    const ConfirmDelete = async (id: string) => {
        const response = await GetData(ApiMaterialId(id), HTTP_METHODS.DELETE);
        const { data, status, message } = response;
        if (status) {
            ConfigToast.text = message;
            ConfigToast.backColor = Colors.Success;
            CallData();
            setConfigToast(ConfigToast);
            navigate('/panel/materials');
        } else {
            ConfigToast.text = data.message;
            ConfigToast.backColor = Colors.Error;
            ConfigToast.title = 'Error';
            setConfigToast(ConfigToast);
        }
    };

    /**
     * @description
     * @function CallData is a function that returns a promise with the MaterialKind list
     */
    const CallData = async () => {
        const response = await GetData(ApiMaterialKind, HTTP_METHODS.GET);
        const { data, status } = response;
        setMaterialKindList(data);
        if (!status) {
            ConfigToast.text = data.message;
            ConfigToast.backColor = Colors.Error;
            ConfigToast.title = 'Error';
            setConfigToast(ConfigToast);
        }
    };

    /**
     * @description
     * Function that is executed when the component is mounted, calls the function that returns the MaterialKind list
     */
    useEffect(() => {
        CallData();
    }, []);

    useEffect(() => {
        setDetailPage(true);
    }, [id]);

    return (
        <>
            <ToastElement ref={toastManagerRef} />
            <AlertElement ref={alertManagerRef} />
            <SubNavBar
                type='settings'
                title={title}
                icon='settings/materialsIcon.svg'
                kind='settings'
                option='Materials'
            ></SubNavBar>
            <div className='settings'>
                <Header
                    title={{ name: title, link: '/panel/materials' }}
                    icon='MaterialsIconB.svg'
                    subtitle={{ name: id ? editTitle : createTitle }}
                />
                <div className='settings__grid-container'>
                    <div className='settings__grid-container__item'>
                        <div className='settings__grid-container__item__title'>
                            <div className='settings__grid-container__item__title__section1 title'>
                                {adminTitle}{' '}
                            </div>
                            <div className='settings__grid-container__item__title__section2 title'>
                                <Link
                                    className={`settings__grid-container__item__title__section2__item left ${
                                        manageBy === 'Materials'
                                            ? 'active'
                                            : 'not-active'
                                    }`}
                                    onClick={() => setManageBy('Materials')}
                                    to='/panel/materials'
                                >
                                    {option1}
                                </Link>
                                <Link
                                    className={`settings__grid-container__item__title__section2__item right ${
                                        manageBy !== 'Materials'
                                            ? 'active'
                                            : 'not-active'
                                    }`}
                                    onClick={() =>
                                        setManageBy('Materials-Type')
                                    }
                                    to='/panel/material-kind'
                                >
                                    {option2}
                                </Link>
                            </div>
                        </div>
                        <MaterialsTableComponent /* refresh={updates} */ />
                        <div className='settings__grid-container__item__content'>
                            <ButtonBody
                                able='true'
                                kind='button button-body--create'
                                text={newTitle}
                                path='/panel/materials/create'
                            ></ButtonBody>
                        </div>
                    </div>
                    <div className='settings__grid-container__item_right'>
                        <div className='settings__grid-container__item_right__title title'>
                            {id ? (
                                <div>
                                    {detailPage ? detailTitle : editTitle}
                                </div>
                            ) : (
                                <div>{createTitle}</div>
                            )}
                        </div>
                        <div className='settings__grid-container__item_right__content'>
                            <MaterialCreateComponent
                                materialKindList={materialKindList}
                                detailPage={detailPage}
                            />
                            {id && detailPage && (
                                <div className='settings__grid-container__item_right__content__btn'>
                                    <button
                                        className='settings__grid-container__item_right__content__btn__delete-button item-btn  subtitle'
                                        onClick={Delete}
                                    >
                                        {deleteTitle}
                                    </button>
                                    <button
                                        className='settings__grid-container__item_right__content__btn__edit-button item-btn  subtitle'
                                        onClick={Edit}
                                    >
                                        {editTitle}
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                {LoadingData && <LoaderElement />}
            </div>
        </>
    );
};

export default MaterialCreate;
