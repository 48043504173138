import { FormBuilderFields } from '../../../../components/formBuilder/form-builder.interface';

export const FormCreateProperty: FormBuilderFields[] = [
    {
        label: 'Nombre',
        fieldNameId: 'name',
        fieldType: 'text',
        fieldPlaceholder: 'Ingrese el nombre',
        isFocusInClean: true,
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            },
            min: {
                length: 3,
                state: true,
                message: 'Mínimo 3 carateres'
            },
            max: {
                length: 40,
                state: true,
                message: 'Máximo 40 caracteres'
            }
        }
    },
    {
        label: 'Address',
        fieldNameId: 'address',
        fieldType: 'text',
        fieldPlaceholder: 'Ingrese la dirección',
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            },
            min: {
                length: 3,
                state: true,
                message: 'Mínimo 3 carateres'
            },
            max: {
                length: 70,
                state: true,
                message: 'Máximo 40 caracteres'
            }
        }
    },
    {
        label: 'Rut (sin puntos y sin guión)',
        fieldNameId: 'rut',
        fieldType: 'text',
        fieldPlaceholder: 'Ingrese el rut',
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            }
        }
    },
    {
        label: 'Contacto',
        fieldNameId: 'contactName',
        fieldType: 'text',
        fieldPlaceholder: 'Ingrese el contacto',
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            },
            min: {
                length: 3,
                state: true,
                message: 'Mínimo 3 carateres'
            },
            max: {
                length: 40,
                state: true,
                message: 'Máximo 40 caracteres'
            }
        }
    },
    {
        label: 'Correo electrónico',
        fieldNameId: 'contactEmail',
        fieldType: 'email',
        fieldPlaceholder: 'Ingrese el correo',
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            },
            min: {
                length: 3,
                state: true,
                message: 'Mínimo 3 carateres'
            },
            max: {
                length: 50,
                state: true,
                message: 'Máximo 50 caracteres'
            },
            email: {
                state: true,
                message: 'Ingrese un email válido'
            }
        }
    },
    {
        label: 'Teléfono de Contacto',
        fieldNameId: 'contactPhone',
        fieldType: 'text',
        fieldPlaceholder: 'Ingrese el teléfono',
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            },
            min: {
                length: 3,
                state: true,
                message: 'Mínimo 3 carateres'
            },
            max: {
                length: 40,
                state: true,
                message: 'Máximo 40 caracteres'
            }
        }
    }
];
