import { createSlice } from '@reduxjs/toolkit';
import { PropertyUnitDefault } from './initialValues';

export const propertyUnitSlice = createSlice({
    name: 'propertyUnit',
    initialState: PropertyUnitDefault,
    reducers: {
        setSelectedUnits: (state, action) => {
            const data = action.payload;
            state.selectedUnits = data;
        }
    }
});

export const { setSelectedUnits } = propertyUnitSlice.actions;

export default propertyUnitSlice.reducer;
