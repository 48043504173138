import { createSlice } from '@reduxjs/toolkit';
import { PropertyDefault } from './initialValues';

export const propertySlice = createSlice({
    name: 'property',
    initialState: PropertyDefault,
    reducers: {
        setPropertyData: (state, action) => {
            const data = action.payload;
            state.propertyData = data;
        },
        setSelected: (state, action) => {
            const data = action.payload;
            state.selected = data;
        }
    }
});

export const { setPropertyData, setSelected } = propertySlice.actions;

export default propertySlice.reducer;
