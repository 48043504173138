//  <--COMPONENTS--> //
import { Wrapper } from '../../components/styles';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { ApiPbiToken } from '../../config/service';
import { HTTP_METHODS } from '../../config/hooks/useCallApi/constants';
import { useCallApi } from '../../config/hooks/useCallApi';
import SubNavBar from '../../components/layout/main/subnavbar/subnavbar';
//  <--REDUX--> //
import { useAppSelector } from '../../store/hooks';
import { UserStoreInterface } from '../../store/slices/users/user.interface';
import Loading from '../../components/loading/loading';
import { lang } from '../langs';

const BzeroReports = () => {
    const { userData, preferences }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const defaultLang: string = preferences.lang;
    const key = defaultLang;
    const {
        [key as keyof typeof lang]: {
            pageBzeroReports: { title }
        }
    } = lang;

    const { GetData } = useCallApi();
    const [embedConfigTestMI, setEmbedConfigTestMI] = useState({});
    const [embedConfigTestME, setEmbedConfigTestME] = useState({});
    const [ready, setReady] = useState(false);
    const [deffaultTab, SetDefaultTab] = useState<string>('ME');

    useEffect(() => {
        CallData();
    }, []);

    const CallData = async () => {
        const response = await GetData(ApiPbiToken, HTTP_METHODS.POST);
        const tokenLoginAzure = response.data.accessToken;

        axios.defaults.headers.common = {
            Authorization: `Bearer ${tokenLoginAzure}`
        };
        const config = {
            headers: {
                'Access-Control-Allow-Origin': 'htpp://localhost:3000/',
                'Access-Control-Allow-Methods':
                    'GET,PUT,POST,DELETE,PATCH,OPTIONS'
            }
        };

        const bodyInt = {
            accessLevel: 'View',
            identities: [
                {
                    username: userData.email,
                    //  username: "200",
                    roles: ['BA'],
                    datasets: [process.env.REACT_APP_PUBLIC_PBIBZ_DATASETID_INT]
                }
            ]
        };
        const resInt = await axios.post(
            `https://api.powerbi.com/v1.0/myorg/groups/${process.env.REACT_APP_PUBLIC_PBIBZ_GROUPID}/reports/${process.env.REACT_APP_PUBLIC_PBIBZ_REPORTID_INT}/GenerateToken`,
            bodyInt,
            config
        );
        const TOKEN_INT = resInt.data.token;
        const resUrlInt = await axios.get(
            `https://api.powerbi.com/v1.0/myorg/groups/${process.env.REACT_APP_PUBLIC_PBIBZ_GROUPID}/reports/${process.env.REACT_APP_PUBLIC_PBIBZ_REPORTID_INT}`
        );
        const embedUrlInt = resUrlInt.data.embedUrl;
        const configPbiInt = {
            type: 'report', //  Supported types: report, dashboard, tile, visual and qna
            id: process.env.REACT_APP_PUBLIC_PBIBZ_REPORTID_INT,
            embedUrl: embedUrlInt,
            accessToken: TOKEN_INT,
            tokenType: models.TokenType.Embed,
            settings: {
                panes: {
                    filters: {
                        expanded: false,
                        visible: false
                    },
                    pageNavigation: {
                        visible: false
                    }
                }
            }
        };
        setEmbedConfigTestMI(configPbiInt);

        const bodyExt = {
            accessLevel: 'View',
            identities: [
                {
                    username: userData.email,
                    //  username: "200",
                    roles: ['BA'],
                    datasets: [process.env.REACT_APP_PUBLIC_PBIBZ_DATASETID_EXT]
                }
            ]
        };
        const resExt = await axios.post(
            `https://api.powerbi.com/v1.0/myorg/groups/${process.env.REACT_APP_PUBLIC_PBIBZ_GROUPID}/reports/${process.env.REACT_APP_PUBLIC_PBIBZ_REPORTID_EXT}/GenerateToken`,
            bodyExt,
            config
        );
        const TOKEN_EXT = resExt.data.token;
        const resUrlExt = await axios.get(
            `https://api.powerbi.com/v1.0/myorg/groups/${process.env.REACT_APP_PUBLIC_PBIBZ_GROUPID}/reports/${process.env.REACT_APP_PUBLIC_PBIBZ_REPORTID_EXT}`
        );
        const embedUrlExt = resUrlExt.data.embedUrl;
        const configPbiExt = {
            type: 'report', //  Supported types: report, dashboard, tile, visual and qna
            id: process.env.REACT_APP_PUBLIC_PBIBZ_REPORTID_EXT,
            embedUrl: embedUrlExt,
            accessToken: TOKEN_EXT,
            tokenType: models.TokenType.Embed,
            settings: {
                panes: {
                    filters: {
                        expanded: false,
                        visible: false
                    },
                    pageNavigation: {
                        visible: false
                    }
                }
            }
        };
        setEmbedConfigTestME(configPbiExt);

        setReady(true);
    };

    const HandleSelectNav = eventKey => {
        SetDefaultTab(eventKey);
    };

    return (
        <>
            <SubNavBar
                type='settings'
                title={title}
                icon='settings/ReportsIcon.svg'
                kind='settings'
                option='Reportes Bzero'
            ></SubNavBar>
            <Wrapper>
                <div style={{ marginTop: '90px' }}>
                    <ul
                        style={{
                            margin: 0,
                            padding: 0,
                            display: 'flex',
                            justifyContent: 'center',
                            width: '100%'
                        }}
                    >
                        <li className={deffaultTab === 'ME' ? 'active' : ''}>
                            <button
                                style={{
                                    color: 'blue',
                                    border: '1px solid blue',
                                    padding: '10px',
                                    margin: '2px',
                                    cursor: 'pointer'
                                }}
                                onClick={() => {
                                    HandleSelectNav('ME');
                                }}
                            >
                                Medición externa
                            </button>
                        </li>
                        <li className={deffaultTab === 'MI' ? 'active' : ''}>
                            <button
                                style={{
                                    color: 'blue',
                                    border: '1px solid blue',
                                    padding: '10px',
                                    margin: '2px',
                                    cursor: 'pointer'
                                }}
                                onClick={() => {
                                    HandleSelectNav('MI');
                                }}
                            >
                                Medición interna
                            </button>
                        </li>
                    </ul>
                </div>
                <div className='pbi-wrapper'>
                    {ready ? (
                        <PowerBIEmbed
                            embedConfig={
                                deffaultTab === 'MI'
                                    ? embedConfigTestMI
                                    : embedConfigTestME
                            }
                            cssClassName={'PowerbiContainer'}
                        />
                    ) : (
                        <Loading />
                    )}
                </div>
            </Wrapper>
        </>
    );
};

export default BzeroReports;
