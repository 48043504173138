import {
    /* EditableTypesValues, */
    HeadersInterface
} from '../interface/table.interface';
import type { ColumnsType } from 'antd/es/table';
import { MonthlyClosureInterface } from '../../../interface';
export const tableData = {
    regXpage: 15,
    listCountPages: [100, 200, 300, 400, 500],
    emptyData: 'No data to display',
    filesPageTxt: 'Records per page',
    filesPageTxtConnector: 'Of',
    regsPageTxt: 'Records'
};
/**
 * This is simple object passed to render data in table component
 * and render table headers
 */

export const DefaultValuesHeaders: HeadersInterface[] = [
    {
        dataField: 'materialName',
        text: 'Material',
        relatedDatafield: 'materialId'
    },
    /* {
        dataField: ['gestor.name'],
        text: 'Gestor'
    }, */
    /* {
        dataField: ['treatment.management.name'],
        text: 'Gestión'
    }, */
    {
        dataField: 'treatmentName',
        text: 'Tratamiento',
        relatedDatafield: 'treatmentId',
        isEditable: true
    },
    {
        dataField: 'finalDisposalUnitName',
        text: 'Disposición Final',
        relatedDatafield: 'finalDisposalUnitId',
        isEditable: true
    }
];

export const columnsAntd: ColumnsType<MonthlyClosureInterface> = [
    {
        dataIndex: 'date',
        title: 'Date',
        key: 'date'
    },
    {
        dataIndex: 'propertyName',
        title: 'Property',
        key: 'date'
    },
    {
        dataIndex: 'gestorName',
        title: 'Gestor',
        key: 'gestorName'
    },
    {
        dataIndex: 'truckPatent',
        title: 'Patent',
        key: 'truckPatent'
    },
    {
        dataIndex: 'material',
        title: 'Material',
        key: 'material'
    },
    {
        dataIndex: 'finalDisposal',
        title: 'FinalDisposal',
        key: 'finalDisposal'
    },
    {
        dataIndex: 'finalDUName',
        title: 'Establishment',
        key: 'finalDUName'
    },
    {
        dataIndex: 'treatmentName',
        title: 'Treatment',
        key: 'treatmentName'
    },
    {
        dataIndex: 'weight',
        title: 'Weight',
        key: 'weight'
    }
];
