import { GestorInterface } from './gestor.interface';
import { PropertyInterface } from './property.interface';
import { PropertyUnitInterface } from './property-unit.interface';
import { MaterialInterface } from './material.interface';
import { ConfigReportDetailInterface } from './config-reports.interface';

export interface TypologyInterface {
    id: string;
    name: string;
    transferResidue: boolean;
    origin: string;
    destination: string;
    state: number;
    createdAt: string;
    updatedAt: string;
}

export interface TypologyFormCreateInterface {
    name: string;
    transferResidue: string;
    origin: string;
    destination: string;
}

export const TypologyFormValues: TypologyFormCreateInterface = {
    name: '',
    transferResidue: '',
    origin: '',
    destination: ''
};

export interface FontInterface {
    id: string;
    type: string;
    identifier: string;
    parameters: string; // null
    columns: string; // null
    name: string;
    property: PropertyInterface;
    propertyUnit: PropertyUnitInterface;
    material: MaterialInterface;
    gestor: GestorInterface;
    dataTypology: TypologyInterface;
    state: number;
    createdAt: string;
    updatedAt: string;
}

export interface FontFormCreateInterface {
    name: string;
    type: string;
    identifier: string;
    parameters: string;
    columns: string;
    material: string | null;
    propertyUnit: string | null;
    property: string | null;
}

export const FontFormValues: FontFormCreateInterface = {
    name: '',
    type: '',
    identifier: '',
    parameters: '',
    columns: '',
    material: null,
    propertyUnit: null,
    property: null
};

export interface RequirementInterface {
    id: string;
    periodicityDays: number;
    alarmType: number;
    name: string;
    dataFont: FontInterface;
    property: PropertyInterface;
    propertyUnit: string;
    material: MaterialInterface;
    gestor: GestorInterface;
    state: number;
    createdAt: string;
    updatedAt: string;
    configReportDetails: ConfigReportDetailInterface[];
}

export interface RequirementFormCreateInterface {
    periodicityDays: number;
    alarmType: number[];
    name: string;
    dataFont: string;
    property: string | null;
    propertyUnit: string | null;
    material: string | null;
    gestor: string | null;
    unitMeasurement: string | null;
}

export const RequirementFormValues: RequirementFormCreateInterface = {
    periodicityDays: 0,
    alarmType: [],
    name: '',
    dataFont: '',
    property: null,
    propertyUnit: null,
    material: null,
    gestor: null,
    unitMeasurement: null
};
