import './rightsection.scss';
import '../../main/main-private-style.scss';
import { UserStoreInterface } from '../../../../store/slices/users/user.interface';
import { useAppSelector } from '../../../../store/hooks';
import { Link } from 'react-router-dom';
import Menu from './menu/index';
import { lang } from '../../../langs';
import { useEffect, useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { esES } from '@mui/x-date-pickers/locales';
import 'dayjs/locale/es';

const spanishLocale =
    esES.components.MuiLocalizationProvider.defaultProps.localeText;

const RightSection = () => {
    const { preferences }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const defaultLang: string = preferences.lang;
    const key = defaultLang;
    const {
        [key as keyof typeof lang]: {
            layout: {
                main: {
                    rightsection: { /* remindersTitle, */ months, days }
                }
            }
        }
    } = lang;
    const [date, setDate] = useState('');

    const formatDate = () => {
        const today = new Date();
        const dayOfWeek = today.getDay();
        const dayOfMonth = today.getDate();
        const month = today.getMonth();
        const formattedDate = `${days[dayOfWeek]}, ${dayOfMonth} ${months[month]}`;
        return formattedDate;
    };

    useEffect(() => {
        const dateFormated = formatDate();
        setDate(dateFormated);
    }, []);

    return (
        <div className='right-container'>
            <Menu />
            <div className='right-container__main-wrapper'>
                <div className='right-container__main-wrapper__calendar-container'>
                    <div className='right-container__main-wrapper__calendar-container__header'>
                        <Link
                            to='/panel/calendar'
                            className='right-container__main-wrapper__calendar-container__header__date'
                        >
                            {date}
                        </Link>
                        <div className='right-container__main-wrapper__calendar-container__header__icon'>
                            <Link to='/panel' key='2'>
                                <img
                                    className='right-container__main-wrapper__calendar-container__header__icon__img'
                                    src={`/svg-icons/AlertIconW.svg`}
                                />
                            </Link>
                        </div>
                    </div>
                    <div className='right-container__main-wrapper__calendar-container__calendar'>
                        <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale='es'
                            localeText={spanishLocale}
                        >
                            <DateCalendar
                                showDaysOutsideCurrentMonth
                                fixedWeekNumber={2}
                            />
                        </LocalizationProvider>
                    </div>
                </div>
            </div>

            <div className='right-container__messages'>
                {/* <p className='messages__title'> {remindersTitle}</p>
                <div className='messages__content'>
                    <span className='messages__content__logo'>
                        <img src='/svg-icons/MessagesIcon.svg' />
                    </span>
                    <span className='messages__content__text'>
                        Mensaje mensaje mensaje
                    </span>
                    <hr className='messages__content__line'></hr>
                </div>
                <div className='messages__content'>
                    <span className='messages__content__logo'>
                        <img src='/svg-icons/MessagesIcon.svg' />
                    </span>
                    <span className='messages__content__text'>
                        Mensaje mensaje mensaje
                    </span>
                    <hr className='messages__content__line'></hr>
                </div>
                <div className='messages__content'>
                    <span className='messages__content__logo'>
                        <img src='/svg-icons/MessagesIcon.svg' />
                    </span>
                    <span className='messages__content__text'>
                        Mensaje mensaje mensaje
                    </span>
                    <hr className='messages__content__line'></hr>
                </div>
                <div className='messages__content'>
                    <span className='messages__content__logo'>
                        <img src='/svg-icons/MessagesIcon.svg' />
                    </span>
                    <span className='messages__content__text'>
                        Mensaje mensaje mensaje
                    </span>
                    <hr className='messages__content__line'></hr>
                </div> */}
            </div>
        </div>
    );
};

export default RightSection;
