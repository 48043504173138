import { MaterialInterface } from './material.interface';

export interface ProgramInterface {
    id: string;
    name: string;
    description: string;
    state: number;
    createdAt: string;
    updatedAt: string;
    materials?: MaterialInterface[];
}

export interface ProgramFormCreateInterface {
    name: string;
    description: string;
}

export const ProgramFormValues: ProgramFormCreateInterface = {
    name: '',
    description: ''
};
