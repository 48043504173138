import './logo.scss';

interface Props {
    type?: 'deffault' | 'header' | 'menu' | 'footer';
    image?:
        | 'PRYMA_LOGO_VERDE.svg'
        | 'PRYMA_LOGO_BLANCO.png'
        | 'PRYMA_LOGO_LINEAS'
        | 'BZERO_LOGO_BLANCO.svg';
}

const LogoComponent = ({ type, image }: Props) => {
    return (
        <figure className='logo_figure'>
            <img
                src={`/logos/${image}`}
                alt='Logo'
                className={`logo_figure__img logo_figure__img__${type}`}
            />
        </figure>
    );
};

export default LogoComponent;
