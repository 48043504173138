import '../../settings.scss';
import { useParams } from 'react-router';
import { useEffect, useState } from 'react';
//  <--COMPONENTS--> //
import { useCallApi } from '../../../../config/hooks/useCallApi';
import { HTTP_METHODS } from '../../../../config/hooks/useCallApi/constants';
import SubNavBar from '../../../../components/layout/main/subnavbar/subnavbar';
import GestorUsersTableComponent from './gestor-users-table';
import Header from '../../../../components/header/header';
import { lang } from '../../../langs';
import ButtonBody from '../../../../components/button/button';
//  <--REDUX--> //
import { UserStoreInterface } from '../../../../store/slices/users/user.interface';
import { useAppSelector } from '../../../../store/hooks';
//  <--INTERFACE--> //
import {
    GestorFormCreateInterface,
    GestorFormValues
} from '../../../../interface';
//  <--SERVICES--> //
import { ApiGestorId } from '../../../../config/service';

const GestorUsers = () => {
    const { preferences }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const defaultLang: string = preferences.lang;
    const {
        [defaultLang as keyof typeof lang]: {
            pageGestor: { title },
            pageGestorUser: {
                gestorUsersTitle,
                adminTitle,
                newTitle,
                createTitle
            }
        }
    } = lang;
    const { id } = useParams();
    const { GetData } = useCallApi();
    const [entityId, setEntityId] = useState<string>('');
    const [entity, setEntity] = useState<GestorFormCreateInterface>();

    /**
     * @description this effect verify if exist id in url params, if exist, set the id in state, else set readyForm to true
     */
    useEffect(() => {
        if (id) {
            setEntityId(id);
        } else {
            setEntity(GestorFormValues);
        }
    }, [id]);

    /**
     * @description CallDataEdit function, call the api to get the data of the entity and set the data in state
     */
    const CallDataEdit = async () => {
        const response = await GetData(ApiGestorId(entityId), HTTP_METHODS.GET);
        const { data } = response;
        setEntity(data);
    };

    /**
     * @description this effect check that entityId is not empty and if it changes, if not empty, call the function to CallDataEdit
     */
    useEffect(() => {
        if (entityId !== '') {
            CallDataEdit();
        }
    }, [entityId]);

    return (
        <>
            <SubNavBar
                type='settings'
                title={title}
                icon='settings/GestorsIcon.svg'
                kind='settings'
                option='Gestors'
            ></SubNavBar>
            <div className='settings'>
                <Header
                    title={{ name: title, link: '/panel/company' }}
                    icon='GestorsIconB.svg'
                    subtitle={{
                        name: id ? entity?.name ?? createTitle : '',
                        link: `/panel/company/edit/${id}`
                    }}
                    subsubtitle={{ name: gestorUsersTitle }}
                />
                <div className='settings__grid-container'>
                    <div className='settings__grid-container__item'>
                        <div className='settings__grid-container__item__title title'>
                            {adminTitle}
                        </div>
                        <GestorUsersTableComponent />
                        <div className='settings__grid-container__item__content'>
                            <ButtonBody
                                able='true'
                                kind='button button-body--create'
                                text={newTitle}
                                path='/panel/company/create'
                            ></ButtonBody>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default GestorUsers;
