export const ApiTypology = `${process.env.REACT_APP_PUBLIC_SERVICE_URL}/data-tipology`;
export const ApiTypologyId = (id: string) =>
    `${process.env.REACT_APP_PUBLIC_SERVICE_URL}/data-tipology/${id}`;

export const ApiFont = `${process.env.REACT_APP_PUBLIC_SERVICE_URL}/data-font`;
export const ApiFontId = (id: string) =>
    `${process.env.REACT_APP_PUBLIC_SERVICE_URL}/data-font/${id}`;
export const ApiFontRequirements = (id: string) =>
    `${process.env.REACT_APP_PUBLIC_SERVICE_URL}/data-font/${id}/requirements`;
export const ApiFontCronExtractGsheet = (identifier: string) =>
    `${process.env.REACT_APP_PUBLIC_SERVICE_URL}/data-font/cron-extract-gsheet/${identifier}`;

export const ApiRequirement = `${process.env.REACT_APP_PUBLIC_SERVICE_URL}/data-requirement`;
export const ApiRequirementId = (id: string) =>
    `${process.env.REACT_APP_PUBLIC_SERVICE_URL}/data-requirement/${id}`;
export const ApiRequirementCertificates = `${process.env.REACT_APP_PUBLIC_SERVICE_URL}/data-requirement/certificates`;
