export interface SupplyInterface {
    id: string;
    name: string;
    description: string;
    unitSupply: string;
    capacity: number;
    unitCapacity: string;
    equivalence: number | null;
    unitEquivalence: string;
    state: number;
    createdAt: string;
    updatedAt: string;
}

export interface SupplyFormCreateInterface {
    name: string;
    description: string;
    unitSupply: string;
    capacity: number;
    unitCapacity: string;
    equivalence: number | null;
    unitEquivalence: string;
}

export const SupplyFormValues: SupplyFormCreateInterface = {
    name: '',
    description: '',
    unitSupply: '',
    capacity: 0,
    unitCapacity: '',
    equivalence: 0,
    unitEquivalence: ''
};
