import { RequirementInterface } from './data.interface';

export interface ConfigReportInterface {
    id: string;
    description: string;
    state: number;
    createdAt: string;
    updatedAt: string;
    configReportDetails: ConfigReportDetailInterface[];
}

export interface ConfigReportDetailInterface {
    id: string;
    active: boolean;
    dataRequirement: RequirementInterface;
    configReport: ConfigReportInterface;
    state: number;
    createdAt: string;
    updatedAt: string;
}

export interface ConfigReportFormCreateInterface {
    description: string;
    property: string | null;
    propertyUnit: string | null;
    gestor: string | null;
    user: string;
    details: ConfigReportDetailInterface[];
}

export const ConfigReportFormValues: ConfigReportFormCreateInterface = {
    description: 'xxx',
    property: null,
    propertyUnit: null,
    gestor: null,
    user: '',
    details: []
};
