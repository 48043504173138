import { useEffect, useState, useRef } from 'react';
import './leftsection.scss';
import { ConfigProvider, Switch, Tooltip, Checkbox } from 'antd';
import { useToast } from '../../../../config/hooks/useToast';
import useWindowDimensions from '../../../../config/hooks/useWindowDimentions';
//  <--REDUX--> //
import { setSelected } from '../../../../store/slices/property';
import { setSelectedUnits } from '../../../../store/slices/property-unit';
import { useAppSelector, useAppDispatch } from '../../../../store/hooks';
import { UserStoreInterface } from '../../../../store/slices/users/user.interface';
import { PropertyStoreInterface } from '../../../../store/slices/property/property.interface';
import { UnitStoreInterface } from '../../../../store/slices/unit/unit.interface';
import {
    ClientInterface,
    PropertyInterface,
    PropertyUnitInterface
} from '../../../../interface';
import LogoComponent from '../../../logo/logo';
import { Link } from 'react-router-dom';
import { lang } from '../../../langs';
import {
    EyeOutlined,
    CaretDownOutlined,
    CaretUpOutlined,
    CloseOutlined,
    SearchOutlined,
    FilterOutlined,
    EditOutlined
} from '@ant-design/icons';
import { ClientStoreInterface } from 'src/store/slices/client/client.interface';

interface Props {
    onClose?: any;
}

const LeftSection = ({ onClose }: Props) => {
    const { preferences }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const { ToastElement, toastManagerRef } = useToast();
    const defaultLang: string = preferences.lang;
    const key = defaultLang;
    const {
        [key as keyof typeof lang]: {
            layout: {
                main: {
                    leftsection: { welcomeMsg, listTitle }
                }
            }
        }
    } = lang;
    const dispatch = useAppDispatch();
    const { userData }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const { propertyData }: PropertyStoreInterface = useAppSelector(
        state => state.property
    );
    const { unitData }: UnitStoreInterface = useAppSelector(
        state => state.unit
    );
    const { clientData }: ClientStoreInterface = useAppSelector(
        state => state.client
    );
    const [filteredProperties, setFilteredProperties] = useState<
        PropertyInterface[] | any[]
    >([]);
    const [searchInput, setSearchInput] = useState<string>('');
    const [selectedProperties, setSelectedProperties] = useState<
        PropertyInterface[]
    >([]);
    const [selectedPropertyUnits, setSelectedPropertyUnits] = useState<
        PropertyUnitInterface[]
    >([]);
    const [filtersTreeData, setFiltersTreeData] = useState<any>();
    const { width } = useWindowDimensions();
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [showReadOnlys, setShowReadOnlys] = useState<boolean>(false);
    const [checkedKeys, setCheckedKeys] = useState<string[]>([]);
    const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
    const [readOnlySelected, setReadOnlySelected] = useState<boolean>(false);
    const [showFilters, setShowFilters] = useState<boolean>(false);
    const [personalizedFilters, setPersonalizedFilters] =
        useState<boolean>(false);
    const filterButtonRef = useRef<HTMLButtonElement>(null);
    const filterDivRef = useRef<HTMLDivElement>(null);
    const showFilter = false;
    const [clients, setClients] = useState<any[]>([]);
    const [filteredClients, setFilteredClients] = useState<
        ClientInterface[] | any[]
    >([]);

    useEffect(() => {
        setIsMobile(width <= 768);
    }, [width]);

    useEffect(() => {
        if (showFilters && filterButtonRef.current && filterDivRef.current) {
            const buttonRect = filterButtonRef.current.getBoundingClientRect();
            filterDivRef.current.style.top = `${
                buttonRect.bottom + window.scrollY
            }px`;
            filterDivRef.current.style.left = `${
                buttonRect.left + window.scrollX - 25
            }px`;
        }
    }, [showFilters]);

    useEffect(() => {
        if (propertyData?.length > 0) {
            const updatedProperties = [...propertyData].map(property => ({
                ...property,
                propertyUnits: property?.propertyUnits
                    ?.slice()
                    .sort((a, b) => a.name.localeCompare(b.name))
            }));
            const uniqueProperties = Array.from(
                new Set(updatedProperties.map(obj => JSON.stringify(obj)))
            ).map(str => JSON.parse(str) as PropertyInterface);
            const mergedProperties = [...uniqueProperties, ...unitData];
            setFilteredProperties(
                [...mergedProperties].sort((a, b) =>
                    a.name.localeCompare(b.name)
                )
            );
        }
    }, [propertyData, unitData]);

    useEffect(() => {
        const tree = filteredProperties?.map(property => ({
            title: property.name,
            key: property.id,
            children: property.propertyUnits
                ?.filter(unit => unit.state === '1' || unit.state === 1)
                .map(unit => ({
                    title: unit.name,
                    key: unit.id,
                    type: 'unit'
                })),
            state: property.active,
            showChildren:
                property.showChildren || property.active === 'ReadOnly',
            partiallyChecked: false,
            type: 'property'
        }));
        /* setTreeData(tree); */
        /* reorderTree(tree); */
        setFiltersTreeData(tree);
    }, [filteredProperties]);

    useEffect(() => {
        if (clientData.length > 0) {
            setFilteredClients(clientData);
        }
    }, [clientData]);

    useEffect(() => {
        const tree = filteredClients?.map(client => ({
            title: client.name, // Nombre del cliente
            key: client.id, // ID del cliente
            items: [
                // Combinar las propiedades y unidades del cliente
                ...client.properties.map(property => ({
                    title: property.name,
                    key: property.id,
                    children: property.units
                        ?.filter(unit => unit.state === '1' || unit.state === 1)
                        .map(unit => ({
                            title: unit.name,
                            key: unit.id,
                            type: 'unit'
                        })),
                    state: property.active,
                    showChildren:
                        property.showChildren || property.active === 'ReadOnly',
                    partiallyChecked: false,
                    type: 'property'
                })),
                // Agregar las unidades al nivel del cliente (si es necesario)
                ...client.units.map(unit => ({
                    title: unit.name,
                    key: unit.id,
                    type: 'unit',
                    state: unit.state,
                    partiallyChecked: false,
                    showChildren: unit.showChildren
                }))
            ],
            partiallyChecked: false,
            type: 'client',
            showChildren: client.showChildren
        }));
        reorderTree(tree);
        /* setClients(tree); */ // Deberia ser reordertree
    }, [filteredClients]);

    const reorderTree = data => {
        if (!selectedProperties || !selectedPropertyUnits || !data) return;

        const selectedPropertiesIds = selectedProperties.map(prop => prop.id);
        const selectedPropertyUnitsIds = selectedPropertyUnits.map(
            unit => unit.id
        );

        // Recorre cada cliente
        const updatedTreeData = data?.map(client => {
            const updatedProperties = client.items?.map(property => {
                // Verifica si la propiedad tiene unidades seleccionadas
                const hasPartialCheckedChild = property.children?.some(unit =>
                    selectedPropertyUnitsIds.includes(unit.key)
                );

                // Reordena las unidades de la propiedad
                const reorderedUnits = property.children?.sort((a, b) => {
                    const aIsSelected = selectedPropertyUnitsIds.includes(
                        a.key
                    );
                    const bIsSelected = selectedPropertyUnitsIds.includes(
                        b.key
                    );

                    if (aIsSelected && !bIsSelected) return -1;
                    if (!aIsSelected && bIsSelected) return 1;
                    return a.title.localeCompare(b.title);
                });

                // Retorna la propiedad con las unidades reordenadas y la marca `partiallyChecked` si aplica
                return {
                    ...property,
                    children: reorderedUnits,
                    partiallyChecked: hasPartialCheckedChild || false
                };
            });

            // Reordena las propiedades: seleccionadas y `partiallyChecked` primero, luego el resto alfabéticamente
            const reorderedProperties = updatedProperties?.sort((a, b) => {
                const aIsSelected =
                    selectedPropertiesIds.includes(a.key) || a.partiallyChecked;
                const bIsSelected =
                    selectedPropertiesIds.includes(b.key) || b.partiallyChecked;

                if (aIsSelected && !bIsSelected) return -1;
                if (!aIsSelected && bIsSelected) return 1;
                return a.title.localeCompare(b.title);
            });

            // Retorna el cliente con las propiedades reordenadas
            return {
                ...client,
                items: reorderedProperties
            };
        });

        setClients(updatedTreeData);
    };

    useEffect(() => {
        dispatch(setSelected(selectedProperties));
        reorderTree(clients);
    }, [selectedProperties]);

    useEffect(() => {
        dispatch(setSelectedUnits(selectedPropertyUnits));
        reorderTree(clients);
    }, [selectedPropertyUnits]);

    /* const selectAll = () => {
        if(propertyData.length === checkedKeys.length) {
            setCheckedKeys([]);
            setSelectedProperties([]);
        } else {
            setSelectedProperties(propertyData);
            setCheckedKeys(propertyData.map(property => property.id));
        }
    } */

    const onCheck = element => {
        const key = element.key ?? element.id;
        const index = checkedKeys.indexOf(key);
        let updatedCheckedKeys;
        if (index !== -1) {
            updatedCheckedKeys = checkedKeys.filter(k => k !== key);
        } else {
            if (element.state === 'ReadOnly' || readOnlySelected) {
                updatedCheckedKeys = [key];
                setReadOnlySelected(!readOnlySelected);
            } else {
                updatedCheckedKeys = checkedKeys.concat(key);
            }
        }
        // Deseleccionar units si se selecciona un property
        if (element.type === 'property') {
            const propertyUnitsToDeselect =
                propertyData
                    .find(property => property.id === key)
                    ?.propertyUnits?.map(unit => unit.id) ?? [];
            updatedCheckedKeys = updatedCheckedKeys.filter(
                k => !propertyUnitsToDeselect.includes(k)
            );
        }
        // Deseleccionar property si se selecciona un unit
        if (element.type === 'unit') {
            const parentProperty = propertyData.find(property =>
                (property.propertyUnits || []).some(unit => unit.id === key)
            );
            if (parentProperty) {
                updatedCheckedKeys = updatedCheckedKeys.filter(
                    k => k !== parentProperty.id
                );
            }
        }
        setCheckedKeys(updatedCheckedKeys);
        const properties = propertyData.filter(property =>
            updatedCheckedKeys.includes(property.id)
        );
        const propertyUnits = propertyData.flatMap(property =>
            (property?.propertyUnits || []).filter(unit =>
                updatedCheckedKeys.includes(unit.id)
            )
        );
        const propertyUnits2 = unitData.filter(unit =>
            updatedCheckedKeys.includes(unit.id)
        );
        const mergedPropertyUnits = propertyUnits.concat(propertyUnits2);
        setSelectedProperties(properties);
        setSelectedPropertyUnits(mergedPropertyUnits);
    };

    const onSelect = element => {
        const key = element.key ?? element.id;
        const index = selectedKeys.indexOf(key);
        let updatedSelectedKeys;
        if (index !== -1) {
            updatedSelectedKeys = selectedKeys.filter(k => k !== key);
        } else {
            if (element.state === 'ReadOnly' || readOnlySelected) {
                updatedSelectedKeys = [key];
            } else {
                updatedSelectedKeys = selectedKeys.concat(key);
            }
        }
        setSelectedKeys(updatedSelectedKeys);
    };

    const handleChange = e => {
        e.preventDefault();
        const value = e.target.value.toLowerCase();
        setSearchInput(value);

        if (value.length >= 1) {
            const filtered = clientData
                .map(client => {
                    // Filtrar unidades del cliente basadas en el nombre de la unidad
                    const filteredClientUnits =
                        client.units?.filter(unit =>
                            unit.name.toLowerCase().includes(value)
                        ) || [];

                    // Filtrar propiedades del cliente basadas en el nombre de la propiedad o de las unidades
                    const filteredProperties = client.properties
                        .map(property => {
                            // Filtrar unidades de la propiedad basadas en el nombre de la unidad
                            const filteredUnits =
                                property.units?.filter(unit =>
                                    unit.name.toLowerCase().includes(value)
                                ) || [];

                            // Verificar si el nombre de la propiedad coincide o si tiene unidades coincidentes
                            const propertyMatches =
                                property.name.toLowerCase().includes(value) ||
                                filteredUnits.length > 0;

                            // Retornar la propiedad con unidades coincidentes y `showChildren: true` si es relevante
                            return propertyMatches
                                ? {
                                      ...property,
                                      units: filteredUnits,
                                      showChildren: filteredUnits.length > 0
                                  }
                                : null;
                        })
                        .filter(Boolean); // Eliminar propiedades que no coinciden

                    // Verificar si el cliente coincide, tiene unidades coincidentes o propiedades coincidentes
                    const clientMatches =
                        client.name.toLowerCase().includes(value) ||
                        filteredClientUnits.length > 0 ||
                        filteredProperties.length > 0;

                    // Retornar el cliente con unidades y propiedades coincidentes
                    return clientMatches
                        ? {
                              ...client,
                              units: filteredClientUnits,
                              properties: filteredProperties,
                              showChildren:
                                  filteredClientUnits.length > 0 ||
                                  filteredProperties.length > 0
                          }
                        : null;
                })
                .filter(Boolean); // Eliminar clientes que no coinciden

            // Ordenar y guardar el resultado filtrado
            setFilteredClients(
                filtered.sort((a: any, b: any) => a.name.localeCompare(b.name))
            );
        } else {
            setFilteredClients(clientData);
        }
    };

    const CloseLeftSection = () => {
        onClose();
    };

    return (
        <>
            {' '}
            <ToastElement ref={toastManagerRef} />
            <div className='left-header'>
                <Link to='/panel' className='left-header__logo'>
                    <img
                        id='bannerImg'
                        className={`left-header__logo__img ${
                            userData?.clientData &&
                            userData.clientData.length > 0 &&
                            userData?.clientData[0].client?.image &&
                            'logo-contain'
                        }`}
                        src={`${
                            userData?.clientData &&
                            userData.clientData.length > 0 &&
                            userData?.clientData[0].client?.image
                                ? userData.clientData[0].client.image
                                : '/logos/client_logo.png'
                        }`}
                    />
                </Link>
            </div>
            <div className='misinmuebles'>
                <div className='misinmuebles__user'>
                    <span className='misinmuebles__user__name subtitle'>
                        {welcomeMsg} {userData?.name} {userData?.lastName}
                    </span>
                    <span className='misinmuebles__user__role subtitle'>
                        {userData?.roles[0].name}
                    </span>
                </div>
                <div className='misinmuebles__titleContainer'>
                    <p className='misinmuebles__titleContainer__title title'>
                        {' '}
                        {listTitle}
                    </p>
                    {userData.roles.some(role =>
                        role.name.includes('General')
                    ) && (
                        <div className='misinmuebles__titleContainer__switch'>
                            <ConfigProvider
                                theme={{
                                    components: {
                                        Switch: {
                                            colorPrimary: '#a2a2a3',
                                            colorTextTertiary: '#a2a2a3',
                                            colorText: '#C4C3C5',
                                            colorTextQuaternary: '#C4C3C5',
                                            colorPrimaryHover: '#a2a2a3'
                                        }
                                    }
                                }}
                            >
                                <Tooltip
                                    title={
                                        <span className='main_menu__grid-container__tooltip'>
                                            Haz click para mostrar/ocultar
                                            aquellos de solo visualización
                                        </span>
                                    }
                                    color='#fff'
                                >
                                    <Switch
                                        className='profile-body__edit-grid-container__item__edit-info-container__not__option__toggle'
                                        size='small'
                                        onChange={() =>
                                            setShowReadOnlys(!showReadOnlys)
                                        }
                                    />
                                </Tooltip>
                            </ConfigProvider>
                        </div>
                    )}
                </div>
                <div className='misinmuebles__listcontainer'>
                    <div className='misinmuebles__listcontainer__list'>
                        <div className='misinmuebles__listcontainer__list__search'>
                            <input
                                className='misinmuebles__listcontainer__list__search__searchbar subtitle'
                                type='text'
                                placeholder='Buscar'
                                value={searchInput}
                                onChange={handleChange}
                            ></input>
                            <span className='misinmuebles__listcontainer__list__search__icon'>
                                {showFilter && (
                                    <button
                                        className='misinmuebles__listcontainer__list__search__icon__img'
                                        ref={filterButtonRef}
                                        onClick={() => setShowFilters(true)}
                                    >
                                        <FilterOutlined rev={''} />
                                    </button>
                                )}
                                <SearchOutlined
                                    className='misinmuebles__listcontainer__list__search__icon__img'
                                    rev={''}
                                />
                            </span>
                        </div>
                        <div className='misinmuebles__listcontainer__list__inmuebleslist'>
                            {clientData?.length === 1
                                ? clients?.[0]?.items?.map(item =>
                                      item.state !== 'ReadOnly' ||
                                      showReadOnlys ? (
                                          <div
                                              className='settings__grid-container__item__list__element subtitle'
                                              key={item.key}
                                          >
                                              <div
                                                  className={`settings__grid-container__item__list__element__${
                                                      item.state === 'ReadOnly'
                                                          ? 'readOnly'
                                                          : 'data'
                                                  } subtitle`}
                                                  style={{ color: '#2994E6' }}
                                              >
                                                  <ConfigProvider
                                                      theme={{
                                                          components: {
                                                              Checkbox: {
                                                                  colorPrimary: `${
                                                                      item.state ===
                                                                      'ReadOnly'
                                                                          ? '#C4C3C5'
                                                                          : '#2994e6'
                                                                  }`,
                                                                  colorBorder: `${
                                                                      item.state ===
                                                                      'ReadOnly'
                                                                          ? '#C4C3C5'
                                                                          : '#2994e6'
                                                                  }`,
                                                                  colorPrimaryHover: `${
                                                                      item.state ===
                                                                      'ReadOnly'
                                                                          ? '#C4C3C5'
                                                                          : '#2994e6'
                                                                  }`
                                                              }
                                                          }
                                                      }}
                                                  >
                                                      <Checkbox
                                                          className={`settings__grid-container__item__list__element__${
                                                              item.state ===
                                                              'ReadOnly'
                                                                  ? 'readOnly'
                                                                  : 'data'
                                                          }__checkbox`}
                                                          onClick={() =>
                                                              onCheck(item)
                                                          }
                                                          checked={checkedKeys.includes(
                                                              item.key
                                                          )}
                                                          indeterminate={
                                                              item.partiallyChecked &&
                                                              !checkedKeys.includes(
                                                                  item.key
                                                              )
                                                          }
                                                      />
                                                  </ConfigProvider>
                                                  <div
                                                      className={`settings__grid-container__item__list__element__${
                                                          item.state ===
                                                          'ReadOnly'
                                                              ? 'readOnly'
                                                              : 'data'
                                                      }__text`}
                                                  >
                                                      {item.title}
                                                  </div>
                                                  {item.state ===
                                                      'ReadOnly' && (
                                                      <span
                                                          className={`settings__grid-container__item__list__element__readOnly__logo`}
                                                      >
                                                          <EyeOutlined
                                                              rev={''}
                                                          />
                                                      </span>
                                                  )}
                                                  {item.children &&
                                                      item.children.length >
                                                          0 && (
                                                          <button
                                                              className={`settings__grid-container__item__list__element__${
                                                                  item.state ===
                                                                  'ReadOnly'
                                                                      ? 'readOnly'
                                                                      : 'data'
                                                              }__dropdown`}
                                                              onClick={() =>
                                                                  setClients(
                                                                      prevClients =>
                                                                          prevClients.map(
                                                                              client => {
                                                                                  // Buscamos el cliente que contiene el item actual
                                                                                  return {
                                                                                      ...client,
                                                                                      items: client.items.map(
                                                                                          clientItem =>
                                                                                              clientItem.key ===
                                                                                              item.key
                                                                                                  ? {
                                                                                                        ...clientItem,
                                                                                                        showChildren:
                                                                                                            !clientItem.showChildren
                                                                                                    }
                                                                                                  : clientItem
                                                                                      )
                                                                                  };
                                                                              }
                                                                          )
                                                                  )
                                                              }
                                                          >
                                                              {item.showChildren ? (
                                                                  <CaretUpOutlined
                                                                      rev={''}
                                                                  />
                                                              ) : (
                                                                  <CaretDownOutlined
                                                                      rev={''}
                                                                  />
                                                              )}
                                                          </button>
                                                      )}
                                              </div>
                                              {item.showChildren &&
                                                  item.children &&
                                                  item.children.length > 0 &&
                                                  item.children.map(
                                                      (child, index) => (
                                                          <div
                                                              className={`settings__grid-container__item__list__element__${
                                                                  child.state ===
                                                                  'ReadOnly'
                                                                      ? 'readOnly'
                                                                      : 'data'
                                                              } subtitle`}
                                                              style={{
                                                                  marginLeft:
                                                                      '10%',
                                                                  color: '#2994E6',
                                                                  width: '95%'
                                                              }}
                                                              key={child.key}
                                                          >
                                                              <ConfigProvider
                                                                  theme={{
                                                                      components:
                                                                          {
                                                                              Checkbox:
                                                                                  {
                                                                                      colorPrimary:
                                                                                          '#2994e6',
                                                                                      colorBorder:
                                                                                          '#2994e6',
                                                                                      colorText:
                                                                                          '#2994e6'
                                                                                  }
                                                                          }
                                                                  }}
                                                              >
                                                                  <Checkbox
                                                                      className={`settings__grid-container__item__list__element__${
                                                                          item.state ===
                                                                          'ReadOnly'
                                                                              ? 'readOnly'
                                                                              : 'data'
                                                                      }__checkbox`}
                                                                      onClick={() =>
                                                                          onCheck(
                                                                              child
                                                                          )
                                                                      }
                                                                      checked={checkedKeys.includes(
                                                                          child.key
                                                                      )}
                                                                  />
                                                              </ConfigProvider>
                                                              <div
                                                                  className={`settings__grid-container__item__list__element__${
                                                                      child.state ===
                                                                      'ReadOnly'
                                                                          ? 'readOnly'
                                                                          : 'data'
                                                                  }__text`}
                                                              >
                                                                  {child.title}
                                                              </div>
                                                              {child.state ===
                                                                  'ReadOnly' && (
                                                                  <EyeOutlined
                                                                      rev={''}
                                                                  />
                                                              )}
                                                          </div>
                                                      )
                                                  )}
                                          </div>
                                      ) : (
                                          item.children &&
                                          item.children.length > 0 &&
                                          item.children.map(child =>
                                              child.state !== 'ReadOnly' ? (
                                                  <div
                                                      className='settings__grid-container__item__list__element subtitle'
                                                      key={child.key}
                                                  >
                                                      <div
                                                          className={`settings__grid-container__item__list__element__data subtitle`}
                                                          style={{
                                                              color: '#2994E6'
                                                          }}
                                                      >
                                                          <ConfigProvider
                                                              theme={{
                                                                  components: {
                                                                      Checkbox:
                                                                          {
                                                                              colorPrimary:
                                                                                  '#2994e6',
                                                                              colorBorder:
                                                                                  '#2994e6',
                                                                              colorText:
                                                                                  '#2994e6'
                                                                          }
                                                                  }
                                                              }}
                                                          >
                                                              <Checkbox
                                                                  className={`settings__grid-container__item__list__element__${
                                                                      item.state ===
                                                                      'ReadOnly'
                                                                          ? 'readOnly'
                                                                          : 'data'
                                                                  }__checkbox`}
                                                                  onClick={() =>
                                                                      onCheck(
                                                                          child
                                                                      )
                                                                  }
                                                                  checked={checkedKeys.includes(
                                                                      child.key
                                                                  )}
                                                              />
                                                          </ConfigProvider>
                                                          <div
                                                              className={`settings__grid-container__item__list__element__data__text`}
                                                          >
                                                              {child.title}
                                                          </div>
                                                      </div>
                                                  </div>
                                              ) : null
                                          )
                                      )
                                  )
                                : clients.map((client, index) =>
                                      client.showChildren ? (
                                          <div
                                              key={client.key || index} // Asegúrate de que cada elemento tenga un "key" único
                                              className='misinmuebles__listcontainer__list__inmuebleslist__client'
                                          >
                                              <div className='misinmuebles__listcontainer__list__inmuebleslist__client__name'>
                                                  {client.title}
                                                  <CaretUpOutlined
                                                      rev={''}
                                                      onClick={() =>
                                                          setClients(
                                                              prevClients =>
                                                                  prevClients.map(
                                                                      prevClient => {
                                                                          return prevClient.key ===
                                                                              client.key
                                                                              ? {
                                                                                    ...prevClient,
                                                                                    showChildren:
                                                                                        !prevClient.showChildren
                                                                                }
                                                                              : prevClient;
                                                                      }
                                                                  )
                                                          )
                                                      }
                                                  />
                                              </div>
                                              <div className='misinmuebles__listcontainer__list__inmuebleslist__client__properties'>
                                                  {client.items?.map(item =>
                                                      item.state !==
                                                          'ReadOnly' ||
                                                      showReadOnlys ? (
                                                          <div
                                                              className='settings__grid-container__item__list__element subtitle'
                                                              key={item.key}
                                                          >
                                                              <div
                                                                  className={`settings__grid-container__item__list__element__${
                                                                      item.state ===
                                                                      'ReadOnly'
                                                                          ? 'readOnly'
                                                                          : 'data'
                                                                  } subtitle`}
                                                                  key={item.key}
                                                                  style={{
                                                                      color: '#2994E6'
                                                                  }}
                                                              >
                                                                  <ConfigProvider
                                                                      theme={{
                                                                          components:
                                                                              {
                                                                                  Checkbox:
                                                                                      {
                                                                                          colorPrimary: `${
                                                                                              item.state ===
                                                                                              'ReadOnly'
                                                                                                  ? '#C4C3C5'
                                                                                                  : '#2994e6'
                                                                                          }`,
                                                                                          colorBorder: `${
                                                                                              item.state ===
                                                                                              'ReadOnly'
                                                                                                  ? '#C4C3C5'
                                                                                                  : '#2994e6'
                                                                                          }`,
                                                                                          colorPrimaryHover: `${
                                                                                              item.state ===
                                                                                              'ReadOnly'
                                                                                                  ? '#C4C3C5'
                                                                                                  : '#2994e6'
                                                                                          }`
                                                                                      }
                                                                              }
                                                                      }}
                                                                  >
                                                                      <Checkbox
                                                                          className={`settings__grid-container__item__list__element__${
                                                                              item.state ===
                                                                              'ReadOnly'
                                                                                  ? 'readOnly'
                                                                                  : 'data'
                                                                          }__checkbox`}
                                                                          onClick={() =>
                                                                              onCheck(
                                                                                  item
                                                                              )
                                                                          }
                                                                          checked={checkedKeys.includes(
                                                                              item.key
                                                                          )}
                                                                          indeterminate={
                                                                              item.partiallyChecked &&
                                                                              !checkedKeys.includes(
                                                                                  item.key
                                                                              )
                                                                          }
                                                                      />
                                                                  </ConfigProvider>
                                                                  <div
                                                                      className={`settings__grid-container__item__list__element__${
                                                                          item.state ===
                                                                          'ReadOnly'
                                                                              ? 'readOnly'
                                                                              : 'data'
                                                                      }__text`}
                                                                  >
                                                                      {
                                                                          item.title
                                                                      }
                                                                  </div>
                                                                  {item.state ===
                                                                      'ReadOnly' && (
                                                                      <span
                                                                          className={`settings__grid-container__item__list__element__readOnly__logo`}
                                                                      >
                                                                          <EyeOutlined
                                                                              rev={
                                                                                  ''
                                                                              }
                                                                          />
                                                                      </span>
                                                                  )}
                                                                  {item.children &&
                                                                      item
                                                                          .children
                                                                          .length >
                                                                          0 && (
                                                                          <button
                                                                              className={`settings__grid-container__item__list__element__${
                                                                                  item.state ===
                                                                                  'ReadOnly'
                                                                                      ? 'readOnly'
                                                                                      : 'data'
                                                                              }__dropdown`}
                                                                              onClick={() =>
                                                                                  setClients(
                                                                                      prevClients =>
                                                                                          prevClients.map(
                                                                                              client => {
                                                                                                  // Buscamos el cliente que contiene el item actual
                                                                                                  return {
                                                                                                      ...client,
                                                                                                      items: client.items.map(
                                                                                                          clientItem =>
                                                                                                              clientItem.key ===
                                                                                                              item.key
                                                                                                                  ? {
                                                                                                                        ...clientItem,
                                                                                                                        showChildren:
                                                                                                                            !clientItem.showChildren
                                                                                                                    }
                                                                                                                  : clientItem
                                                                                                      )
                                                                                                  };
                                                                                              }
                                                                                          )
                                                                                  )
                                                                              }
                                                                          >
                                                                              {item.showChildren ? (
                                                                                  <CaretUpOutlined
                                                                                      rev={
                                                                                          ''
                                                                                      }
                                                                                  />
                                                                              ) : (
                                                                                  <CaretDownOutlined
                                                                                      rev={
                                                                                          ''
                                                                                      }
                                                                                  />
                                                                              )}
                                                                          </button>
                                                                      )}
                                                              </div>
                                                              {item.showChildren &&
                                                                  item.children &&
                                                                  item.children
                                                                      .length >
                                                                      0 &&
                                                                  item.children.map(
                                                                      (
                                                                          child,
                                                                          index
                                                                      ) => (
                                                                          <div
                                                                              className={`settings__grid-container__item__list__element__${
                                                                                  child.state ===
                                                                                  'ReadOnly'
                                                                                      ? 'readOnly'
                                                                                      : 'data'
                                                                              } subtitle`}
                                                                              style={{
                                                                                  marginLeft:
                                                                                      '10%',
                                                                                  color: '#2994E6',
                                                                                  width: '95%'
                                                                              }}
                                                                              key={
                                                                                  child.key
                                                                              }
                                                                          >
                                                                              <ConfigProvider
                                                                                  theme={{
                                                                                      components:
                                                                                          {
                                                                                              Checkbox:
                                                                                                  {
                                                                                                      colorPrimary:
                                                                                                          '#2994e6',
                                                                                                      colorBorder:
                                                                                                          '#2994e6',
                                                                                                      colorText:
                                                                                                          '#2994e6'
                                                                                                  }
                                                                                          }
                                                                                  }}
                                                                              >
                                                                                  <Checkbox
                                                                                      className={`settings__grid-container__item__list__element__${
                                                                                          item.state ===
                                                                                          'ReadOnly'
                                                                                              ? 'readOnly'
                                                                                              : 'data'
                                                                                      }__checkbox`}
                                                                                      onClick={() =>
                                                                                          onCheck(
                                                                                              child
                                                                                          )
                                                                                      }
                                                                                      checked={checkedKeys.includes(
                                                                                          child.key
                                                                                      )}
                                                                                  />
                                                                              </ConfigProvider>
                                                                              <div
                                                                                  className={`settings__grid-container__item__list__element__${
                                                                                      child.state ===
                                                                                      'ReadOnly'
                                                                                          ? 'readOnly'
                                                                                          : 'data'
                                                                                  }__text`}
                                                                              >
                                                                                  {
                                                                                      child.title
                                                                                  }
                                                                              </div>
                                                                              {child.state ===
                                                                                  'ReadOnly' && (
                                                                                  <EyeOutlined
                                                                                      rev={
                                                                                          ''
                                                                                      }
                                                                                  />
                                                                              )}
                                                                          </div>
                                                                      )
                                                                  )}
                                                          </div>
                                                      ) : (
                                                          item.children &&
                                                          item.children.length >
                                                              0 &&
                                                          item.children.map(
                                                              child =>
                                                                  child.state !==
                                                                  'ReadOnly' ? (
                                                                      <div
                                                                          className='settings__grid-container__item__list__element subtitle'
                                                                          key={
                                                                              child.key
                                                                          }
                                                                      >
                                                                          <div
                                                                              className={`settings__grid-container__item__list__element__data subtitle`}
                                                                              style={{
                                                                                  color: '#2994E6'
                                                                              }}
                                                                          >
                                                                              <ConfigProvider
                                                                                  theme={{
                                                                                      components:
                                                                                          {
                                                                                              Checkbox:
                                                                                                  {
                                                                                                      colorPrimary:
                                                                                                          '#2994e6',
                                                                                                      colorBorder:
                                                                                                          '#2994e6',
                                                                                                      colorText:
                                                                                                          '#2994e6'
                                                                                                  }
                                                                                          }
                                                                                  }}
                                                                              >
                                                                                  <Checkbox
                                                                                      className={`settings__grid-container__item__list__element__${
                                                                                          item.state ===
                                                                                          'ReadOnly'
                                                                                              ? 'readOnly'
                                                                                              : 'data'
                                                                                      }__checkbox`}
                                                                                      onClick={() =>
                                                                                          onCheck(
                                                                                              child
                                                                                          )
                                                                                      }
                                                                                      checked={checkedKeys.includes(
                                                                                          child.key
                                                                                      )}
                                                                                  />
                                                                              </ConfigProvider>
                                                                              <div
                                                                                  className={`settings__grid-container__item__list__element__data__text`}
                                                                              >
                                                                                  {
                                                                                      child.title
                                                                                  }
                                                                              </div>
                                                                          </div>
                                                                      </div>
                                                                  ) : null
                                                          )
                                                      )
                                                  )}
                                              </div>
                                              <div className='misinmuebles__listcontainer__list__inmuebleslist__client__footer'></div>
                                          </div>
                                      ) : (
                                          <div
                                              className='misinmuebles__listcontainer__list__inmuebleslist__clientclose'
                                              key={client.key || index}
                                          >
                                              <div className='misinmuebles__listcontainer__list__inmuebleslist__clientclose__name'>
                                                  {client.title}
                                                  <CaretDownOutlined
                                                      rev={''}
                                                      onClick={() =>
                                                          setClients(
                                                              prevClients =>
                                                                  prevClients.map(
                                                                      prevClient => {
                                                                          return prevClient.key ===
                                                                              client.key
                                                                              ? {
                                                                                    ...prevClient,
                                                                                    showChildren:
                                                                                        !prevClient.showChildren
                                                                                }
                                                                              : prevClient;
                                                                      }
                                                                  )
                                                          )
                                                      }
                                                  />
                                              </div>
                                          </div>
                                      )
                                  )}
                        </div>
                    </div>
                </div>
                <div className='misinmuebles__logo-container'>
                    {!isMobile ? (
                        <div className='misinmuebles__logo-container__logo-pryma'>
                            <Link to='/panel'>
                                <LogoComponent
                                    type='header'
                                    image='PRYMA_LOGO_VERDE.svg'
                                />
                            </Link>
                        </div>
                    ) : (
                        <div className='misinmuebles__logo-container__button'>
                            <button
                                className='button button-body--left-section'
                                onClick={CloseLeftSection}
                            >
                                Continuar
                            </button>
                        </div>
                    )}
                </div>
            </div>
            {showFilters && (
                <div className='property-filters' ref={filterDivRef}>
                    <button
                        className='property-filters__header title'
                        onClick={() => setShowFilters(false)}
                    >
                        <CloseOutlined rev={''} />
                    </button>
                    {!personalizedFilters ? (
                        <>
                            <div className='property-filters__title title'>
                                Configuración de búsqueda personalizada
                            </div>
                            <div className='property-filters__subtitle subtitle'>
                                Selecciona rápidamente tus Inmuebles/Unidades
                                utilizando estos botones personalizados.
                            </div>
                            <div className='property-filters__section subtitle'>
                                <div className='property-filters__section__title subtitle'>
                                    Filtros predeterminados
                                </div>
                                <div className='property-filters__section__content subtitle'>
                                    <button className='property-filters__section__content__item'>
                                        Todos los inmuebles
                                    </button>
                                    <button className='property-filters__section__content__item'>
                                        Todas las unidades
                                    </button>
                                    <button className='property-filters__section__content__item'>
                                        Todo
                                    </button>
                                </div>
                            </div>
                            <div className='property-filters__section subtitle'>
                                <div className='property-filters__section__title subtitle'>
                                    Filtros personalizados
                                    <button
                                        className='property-filters__section__title__icon'
                                        onClick={() =>
                                            setPersonalizedFilters(true)
                                        }
                                    >
                                        <EditOutlined rev={''} />
                                    </button>
                                </div>
                                <div className='property-filters__section__content subtitle'>
                                    <button className='property-filters__section__content__item'>
                                        Todos los inmuebles
                                    </button>
                                    <button className='property-filters__section__content__item'>
                                        Todas las unidades
                                    </button>
                                    <button className='property-filters__section__content__item'>
                                        Todo
                                    </button>
                                </div>
                            </div>
                            <div className='property-filters__footer subtitle'>
                                <div className='property-filters__footer__text'>
                                    Guarda la selección actual de tus
                                    Inmuebles/Unidades presionando el +
                                </div>
                                <button className='property-filters__footer__button bigtitle'>
                                    +
                                </button>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className='property-filters__title title'>
                                Nuevo filtro personalizado
                            </div>
                            <div className='property-filters__subtitle subtitle'>
                                Guarda tu selección actual rellenando los datos
                                y haciendo click en Guardar.
                            </div>
                            <div className='property-filters__section subtitle'>
                                <div className='property-filters__section__title subtitle'>
                                    Nombre del filtro
                                </div>
                                <input
                                    className='property-filters__section__input subsubtitle'
                                    placeholder='Ingresa el nombre del filtro aquí'
                                ></input>
                            </div>
                            <div className='property-filters__section subtitle'>
                                <div className='property-filters__section__title subtitle'>
                                    Resumen de inmuebles/unidades seleccionadas
                                </div>
                                <div className='property-filters__section__list subtitle'>
                                    {filtersTreeData?.map(item =>
                                        item.state !== 'ReadOnly' ||
                                        showReadOnlys ? (
                                            <div
                                                className='settings__grid-container__item__list__element subtitle'
                                                key={item.key}
                                            >
                                                <div
                                                    className={`settings__grid-container__item__list__element__${
                                                        item.state ===
                                                        'ReadOnly'
                                                            ? 'readOnly'
                                                            : 'data'
                                                    } subtitle`}
                                                    key={item.key}
                                                    style={{ color: '#2994E6' }}
                                                >
                                                    <ConfigProvider
                                                        theme={{
                                                            components: {
                                                                Checkbox: {
                                                                    colorPrimary: `${
                                                                        item.state ===
                                                                        'ReadOnly'
                                                                            ? '#C4C3C5'
                                                                            : '#2994e6'
                                                                    }`,
                                                                    colorBorder: `${
                                                                        item.state ===
                                                                        'ReadOnly'
                                                                            ? '#C4C3C5'
                                                                            : '#2994e6'
                                                                    }`,
                                                                    colorPrimaryHover: `${
                                                                        item.state ===
                                                                        'ReadOnly'
                                                                            ? '#C4C3C5'
                                                                            : '#2994e6'
                                                                    }`
                                                                }
                                                            }
                                                        }}
                                                    >
                                                        <Checkbox
                                                            className={`settings__grid-container__item__list__element__${
                                                                item.state ===
                                                                'ReadOnly'
                                                                    ? 'readOnly'
                                                                    : 'data'
                                                            }__checkbox`}
                                                            onClick={() =>
                                                                onSelect(item)
                                                            }
                                                            checked={selectedKeys.includes(
                                                                item.key
                                                            )}
                                                        />
                                                    </ConfigProvider>
                                                    <div
                                                        className={`settings__grid-container__item__list__element__${
                                                            item.state ===
                                                            'ReadOnly'
                                                                ? 'readOnly'
                                                                : 'data'
                                                        }__text`}
                                                    >
                                                        {item.title}
                                                    </div>
                                                    {item.state ===
                                                        'ReadOnly' && (
                                                        <span
                                                            className={`settings__grid-container__item__list__element__readOnly__logo`}
                                                        >
                                                            <EyeOutlined
                                                                rev={''}
                                                            />
                                                        </span>
                                                    )}
                                                    {item.children &&
                                                        item.children.length >
                                                            0 && (
                                                            <button
                                                                className={`settings__grid-container__item__list__element__${
                                                                    item.state ===
                                                                    'ReadOnly'
                                                                        ? 'readOnly'
                                                                        : 'data'
                                                                }__dropdown`}
                                                                onClick={() =>
                                                                    setFiltersTreeData(
                                                                        prevItems =>
                                                                            prevItems.map(
                                                                                prevItem =>
                                                                                    prevItem.key ===
                                                                                    item.key
                                                                                        ? {
                                                                                              ...prevItem,
                                                                                              showChildren:
                                                                                                  !prevItem.showChildren
                                                                                          }
                                                                                        : prevItem
                                                                            )
                                                                    )
                                                                }
                                                            >
                                                                {item.showChildren ? (
                                                                    <CaretUpOutlined
                                                                        rev={''}
                                                                    />
                                                                ) : (
                                                                    <CaretDownOutlined
                                                                        rev={''}
                                                                    />
                                                                )}
                                                            </button>
                                                        )}
                                                </div>
                                                {item.showChildren &&
                                                    item.children &&
                                                    item.children.length > 0 &&
                                                    item.children.map(
                                                        (child, index) => (
                                                            <div
                                                                className={`settings__grid-container__item__list__element__${
                                                                    child.state ===
                                                                    'ReadOnly'
                                                                        ? 'readOnly'
                                                                        : 'data'
                                                                } subtitle`}
                                                                style={{
                                                                    marginLeft:
                                                                        '10%',
                                                                    color: '#2994E6',
                                                                    width: '95%'
                                                                }}
                                                                key={child.key}
                                                            >
                                                                <ConfigProvider
                                                                    theme={{
                                                                        components:
                                                                            {
                                                                                Checkbox:
                                                                                    {
                                                                                        colorPrimary:
                                                                                            '#2994e6',
                                                                                        colorBorder:
                                                                                            '#2994e6',
                                                                                        colorText:
                                                                                            '#2994e6'
                                                                                    }
                                                                            }
                                                                    }}
                                                                >
                                                                    <Checkbox
                                                                        className={`settings__grid-container__item__list__element__${
                                                                            item.state ===
                                                                            'ReadOnly'
                                                                                ? 'readOnly'
                                                                                : 'data'
                                                                        }__checkbox`}
                                                                        onClick={() =>
                                                                            onSelect(
                                                                                child
                                                                            )
                                                                        }
                                                                        checked={selectedKeys.includes(
                                                                            child.key
                                                                        )}
                                                                    />
                                                                </ConfigProvider>
                                                                <div
                                                                    className={`settings__grid-container__item__list__element__${
                                                                        child.state ===
                                                                        'ReadOnly'
                                                                            ? 'readOnly'
                                                                            : 'data'
                                                                    }__text`}
                                                                >
                                                                    {
                                                                        child.title
                                                                    }
                                                                </div>
                                                                {child.state ===
                                                                    'ReadOnly' && (
                                                                    <EyeOutlined
                                                                        rev={''}
                                                                    />
                                                                )}
                                                            </div>
                                                        )
                                                    )}
                                            </div>
                                        ) : (
                                            item.children &&
                                            item.children.length > 0 &&
                                            item.children.map(
                                                child =>
                                                    child.state !==
                                                        'ReadOnly' && (
                                                        <div
                                                            className='settings__grid-container__item__list__element subtitle'
                                                            key={child.key}
                                                            /* style={{
                                                            borderBottom:
                                                                '0.2px solid #2994E6'
                                                        }} */
                                                        >
                                                            <div
                                                                className={`settings__grid-container__item__list__element__data subtitle`}
                                                                style={{
                                                                    color: '#2994E6'
                                                                }}
                                                            >
                                                                <ConfigProvider
                                                                    theme={{
                                                                        components:
                                                                            {
                                                                                Checkbox:
                                                                                    {
                                                                                        colorPrimary:
                                                                                            '#2994e6',
                                                                                        colorBorder:
                                                                                            '#2994e6',
                                                                                        colorText:
                                                                                            '#2994e6'
                                                                                    }
                                                                            }
                                                                    }}
                                                                >
                                                                    <Checkbox
                                                                        className={`settings__grid-container__item__list__element__${
                                                                            item.state ===
                                                                            'ReadOnly'
                                                                                ? 'readOnly'
                                                                                : 'data'
                                                                        }__checkbox`}
                                                                        onClick={() =>
                                                                            onSelect(
                                                                                child
                                                                            )
                                                                        }
                                                                        checked={selectedKeys.includes(
                                                                            child.key
                                                                        )}
                                                                    />
                                                                </ConfigProvider>
                                                                <div
                                                                    className={`settings__grid-container__item__list__element__data__text`}
                                                                >
                                                                    {
                                                                        child.title
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                            )
                                        )
                                    )}
                                </div>
                            </div>
                            <div className='property-filters__footer subtitle'>
                                <button
                                    className='property-filters__footer__cancel-btn button'
                                    onClick={() =>
                                        setPersonalizedFilters(false)
                                    }
                                >
                                    Cancelar
                                </button>
                                <button className='property-filters__footer__save-btn button'>
                                    Guardar
                                </button>
                            </div>
                        </>
                    )}
                </div>
            )}
        </>
    );
};

export default LeftSection;
