export const constantsTable = {
    keysFilter: [
        'name',
        'address',
        'rut',
        'contactName',
        'contactEmail',
        'contactPhone'
    ]
};
