import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
//  <--HOOKS--> //
import { useToast } from '../../config/hooks/useToast';
import { useToastHook } from '../../store/slices/toast/toast.hook';
import { useAlert } from '../../config/hooks/useAlert';
import { useCallApi } from '../../config/hooks/useCallApi';
import { HTTP_METHODS } from '../../config/hooks/useCallApi/constants';
//  <--COMPONENTS--> //
import { Wrapper, WrapperButtons, Container } from '../../components/styles';
import PageTitle from '../../components/pagetitle/pageTitle';
import TableComponent from '../../components/table';
import { AppSectionTable } from '../../components/table/data/columns_table';
//  <--SERVICES--> //
import { ApiAppSection, ApiAppSectionId } from '../../config/service';
//  <--INTERFACE--> //
import { GestorInterface } from '../../interface';
import { constantsTable } from './constants';
import { lang } from '../langs';
//  <--REDUX--> //
import { UserStoreInterface } from '../../store/slices/users/user.interface';
import { useAppSelector } from '../../store/hooks';

const AppSection = () => {
    const { ToastConfig } = useToastHook();
    const { Colors } = useToast();
    const { preferences }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const defaultLang: string = preferences.lang;
    const {
        AlertElement,
        alertManagerRef,
        ConfigAlert,
        setConfigAlert,
        Defaults
    } = useAlert({ defaultLang });
    const navigate = useNavigate();
    const { GetData, LoadingData, LoaderElement } = useCallApi();
    const {
        [defaultLang as keyof typeof lang]: {
            pageAppSection: { title, buttonNew, deleteMessage }
        }
    } = lang;
    const { keysFilter } = constantsTable;
    const [dataList, setDataList] = useState<GestorInterface[]>([]);

    /**
     * @description
     * @function CallData is a function that returns a promise with the APPSECTION list
     */
    const CallData = async () => {
        const response = await GetData(ApiAppSection, HTTP_METHODS.GET);
        const { data, status } = response;
        setDataList(data);
        if (!status) {
            ToastConfig({
                message: data.message,
                color: Colors.Error
            });
        }
    };

    /**
     * @description
     * Function that is executed when the component is mounted, calls the function that returns the APPSECTION list
     */
    useEffect(() => {
        CallData();
    }, []);

    /**
     * @description
     * @function Edit is a function that redirects to the APPSECTION edition page
     * @param {string} id is a string that contains the id of the APPSECTION to be deleted
     */
    const Edit = (id: string) => {
        navigate(`./edit/${id}`);
    };

    /**
     * @description function that is executed when the delete button is pressed
     * @function Delete is a function that launches an alert to confirm the deletion of a APPSECTION
     * @param id is a string that contains the id of the APPSECTION to be deleted
     */
    const Delete = async (id: string) => {
        ConfigAlert.title = Defaults.titles.Delete;
        ConfigAlert.message = deleteMessage;
        ConfigAlert.okButtonText = Defaults.buttons.Ok.Accept;
        ConfigAlert.okButtonController = { func: ConfirmDelete, param: id };
        ConfigAlert.cancelButtonText = Defaults.buttons.Cancel.Cancel;
        setConfigAlert(ConfigAlert);
    };

    /**
     * @description function that is executed when the okDelete button is pressed
     * @function Delete is a function that deletes a APPSECTION
     * @param id is a string that contains the id of the APPSECTION to be deleted
     */
    const ConfirmDelete = async (id: string) => {
        const response = await GetData(
            ApiAppSectionId(id),
            HTTP_METHODS.DELETE
        );
        const { data, status, message } = response;
        if (status) {
            ToastConfig({
                message,
                color: Colors.Success
            });
            CallData();
        } else {
            ToastConfig({
                message: data.message,
                color: Colors.Error
            });
        }
    };

    return (
        <Container>
            <AlertElement ref={alertManagerRef} />
            <PageTitle title={title} icon='settings/propertiesB.svg' />
            <Wrapper>
                <WrapperButtons className='app-d-flex__right'>
                    <Link to={'./create'} className='btn bzero_btn_primary'>
                        {buttonNew}
                    </Link>
                </WrapperButtons>
                <TableComponent
                    data={dataList}
                    headers={AppSectionTable}
                    showFilters={true}
                    keysFilter={keysFilter}
                    showPagination={true}
                    actionsButtons={{ onEdit: Edit, onDelete: Delete }}
                />
            </Wrapper>
            {LoadingData && <LoaderElement />}
        </Container>
    );
};

export default AppSection;
