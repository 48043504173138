import { GetStorage } from '../../../config/utils/Storage';
import { ClientStoreInterface } from './client.interface';

const CCData = GetStorage(process.env.REACT_APP_PUBLIC_USER_CLIENTS);
const dataIni = [];

export const ClientDefault: ClientStoreInterface = {
    clientData: CCData || dataIni,
    selected: []
};
