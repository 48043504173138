// <--COMPONENTS--> //
import { Wrapper, Container } from '../../components/styles';
// <--REDUX--> //
import { useAppSelector } from '../../store/hooks';
import { UserStoreInterface } from '../../store/slices/users/user.interface';
// <--HOOKS--> //
// <--INTERFACE--> //
import { lang } from '../langs';

const MontlyClosure = () => {
    const { preferences }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const defaultLang: string = preferences.lang;
    const key = defaultLang;
    const {
        [key as keyof typeof lang]: {
            montlyClosure: { title }
        }
    } = lang;

    return (
        <Container>
            <Wrapper>
                <h1 className='page__title'>{title}</h1>
                <Container></Container>
            </Wrapper>
        </Container>
    );
};

export default MontlyClosure;
