import '../../settings.scss';
import { Link } from 'react-router-dom';
import { useState } from 'react';
//  <--COMPONENTS--> //
import SubNavBar from '../../../../components/layout/main/subnavbar/subnavbar';
import Header from '../../../../components/header/header';
import FontTableComponent from './font-table';
import ButtonBody from '../../../../components/button/button';
//  <--INTERFACE--> //
import { lang } from '../../../langs';
//  <--REDUX--> //
import { UserStoreInterface } from '../../../../store/slices/users/user.interface';
import { useAppSelector } from '../../../../store/hooks';

const Data = () => {
    const { preferences }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const defaultLang: string = preferences.lang;

    const {
        [defaultLang as keyof typeof lang]: {
            pageData: {
                title,
                option1,
                option2,
                font: { newTitle }
            }
        }
    } = lang;
    const [showFont, setShowFont] = useState(true);

    return (
        <>
            <SubNavBar
                type='settings'
                title={title}
                icon='settings/dataIconB.svg'
                kind='settings'
                option='Data'
            ></SubNavBar>
            <div className='settings'>
                <Header title={{ name: title }} icon='DataIconB.svg' />
                <div className='settings__grid-container'>
                    <div className='settings__grid-container__item'>
                        <div className='settings__grid-container__item__title title'>
                            <Link
                                className={`settings__grid-container__item__title__section2__item left ${
                                    !showFont ? 'active' : 'not-active'
                                }`}
                                onClick={() => setShowFont(false)}
                                to='/panel/data'
                            >
                                {option1}
                            </Link>
                            <Link
                                className={`settings__grid-container__item__title__section2__item right ${
                                    showFont ? 'active' : 'not-active'
                                }`}
                                onClick={() => setShowFont(true)}
                                to='/panel/data/font'
                            >
                                {option2}
                            </Link>
                        </div>
                        <FontTableComponent />
                        <div className='settings__grid-container__item__content'>
                            <ButtonBody
                                able='true'
                                kind='button button-body--create'
                                text={newTitle}
                                path='/panel/data/font/create'
                            ></ButtonBody>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Data;
