import { useEffect, useState } from 'react';
import jwtDecode from 'jwt-decode';
import {
    GetAuthCookie,
    Logout,
    SetAuthCookie
} from '../../../config/utils/Auth';
import { ApiValidateLogin } from '../../../config/service';
import { useCallApi } from '../../../config/hooks/useCallApi';
import { HTTP_METHODS } from '../../../config/hooks/useCallApi/constants';
import { UseNetworkStatus } from '../../../config/hooks/useNetworkStatus';

const Security = (props: any) => {
    const { OnlineComponent } = UseNetworkStatus();
    const { GetData, LoaderElement } = useCallApi();
    const [ready, setReady] = useState<boolean>(false);

    useEffect(() => {
        Verify();
    }, []);

    const Verify = async () => {
        const { token } = GetAuthCookie();
        if (!token || token === null || token === '') {
            Logout();
        } else {
            try {
                const data = await GetData(ApiValidateLogin, HTTP_METHODS.GET);
                if (!data.status) {
                    // Logout();
                    const newToken = data.data.token;
                    SetAuthCookie({ token: newToken });
                    setReady(true);
                } else {
                    jwtDecode(token);
                    setReady(true);
                }
            } catch (error) {
                Logout();
            }
        }
    };

    return (
        <>
            {!ready ? <LoaderElement /> : props.children}
            <OnlineComponent />
        </>
    );
};

export default Security;
