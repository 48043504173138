import '../../../settings.scss';
import { useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
//  <--COMPONENTS--> //
import SubNavBar from '../../../../../components/layout/main/subnavbar/subnavbar';
import Header from '../../../../../components/header/header';
import SupplyRequirementTableComponent from '../supply-requirement-table';
import FormBuilder from '../../../../../components/formBuilder/formBuilder';
import ButtonBody from '../../../../../components/button/button';
//  <--HOOKS--> //
import { useToast } from '../../../../../config/hooks/useToast';
import { useAlert } from '../../../../../config/hooks/useAlert';
import { useCallApi } from '../../../../../config/hooks/useCallApi';
import { HTTP_METHODS } from '../../../../../config/hooks/useCallApi/constants';
//  <--INTERFACE--> //
import {
    SupplyRequirementFormCreateInterface,
    SupplyRequirementFormValues,
    SupplyInterface,
    /* SupplyRequirementInterface, */
    /* PropertyInterface, */
    PropertyUnitInterface
} from '../../../../../interface';
//  <--REDUX--> //
import { UserStoreInterface } from '../../../../../store/slices/users/user.interface';
import { PropertyStoreInterface } from '../../../../../store/slices/property/property.interface';
import { useAppSelector } from '../../../../../store/hooks';
//  <--SERVICES--> //
import {
    ApiSupplyRequirement,
    ApiSupplyRequirementId,
    ApiSupply,
    ApiSupplyId
} from '../../../../../config/service';
//  <--OTHERS--> //
import { lang } from '../../../../langs';
import { FormCreateSupplyRequirement } from './form';
import '../../../../../scss/global/global.scss';

const SupplyRequirementCreate = () => {
    const { GetData, LoadingData, LoaderElement } = useCallApi();
    const {
        ConfigToast,
        setConfigToast,
        ToastElement,
        toastManagerRef,
        Colors
    } = useToast();
    const { preferences }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const { propertyData }: PropertyStoreInterface = useAppSelector(
        state => state.property
    );
    const defaultLang: string = preferences.lang;
    const {
        AlertElement,
        alertManagerRef,
        ConfigAlert,
        setConfigAlert,
        Defaults
    } = useAlert({ defaultLang });
    const fieldRef = useRef(null);
    const { id, requirementId } = useParams();
    const {
        [defaultLang as keyof typeof lang]: {
            pageSupply: {
                title,
                supplyRequirement: {
                    createTitle,
                    editTitle,
                    detailTitle,
                    deleteMessage,
                    deleteTitle,
                    newTitle
                }
            }
        }
    } = lang;
    const navigate = useNavigate();
    const [entityId, setEntityId] = useState<string>('');
    const [entity, setEntity] =
        useState<SupplyRequirementFormCreateInterface>();
    const [entitySupplyId, setEntitySupplyId] = useState<string>('');
    const [entitySupply, setEntitySupply] = useState<SupplyInterface>();
    const [readyForm, setReadyForm] = useState<boolean>(false);
    const [detailPage, setDetailPage] = useState<boolean>(true);
    const [updates, setUpdates] = useState<boolean>(false);
    /* const [showSupply, setShowSupply] = useState(true);
    const [dataList, setDataList] = useState<PropertyInterface[]>([]); */
    const [dataList2, setDataList2] = useState<PropertyUnitInterface[]>([]);
    const [dataList3, setDataList3] = useState<SupplyInterface[]>([]);
    /* const [dataList4, setDataList4] = useState<SupplyRequirementInterface[]>([]); */

    /**
     * @description CallSupply function, call the api to get the data of the supply and set the data in state to show title
     */
    const CallSupply = async () => {
        const response = await GetData(
            ApiSupplyId(entitySupplyId),
            HTTP_METHODS.GET
        );
        const { data } = response;
        setEntitySupply(data);
        SupplyRequirementFormValues.supply = data.id;
    };

    /**
     * @description
     * @function SetData is a function that sets data lists to show in form as options
     */
    const SetData = async () => {
        setReadyForm(false);
        const response = await GetData(ApiSupply, HTTP_METHODS.GET);
        setDataList3(
            [...response.data].sort((a, b) => a.name.localeCompare(b.name))
        );
        /* const response2 = await GetData(ApiSupplyRequirement, HTTP_METHODS.GET);
        setDataList4(
            [...response2.data].sort((a, b) => a.name.localeCompare(b.name))
        ); */
        setReadyForm(true);
    };

    /**
     * @description CallDataEdit function, call the api to get the data of the entity and set the data in state
     */
    const CallDataEdit = async () => {
        const response = await GetData(
            ApiSupplyRequirementId(entityId),
            HTTP_METHODS.GET
        );
        const { data, status } = response;
        const newData = {
            ...data,
            supply: data.supply ? data.supply.id : null,
            supplyRequirement: data.supplyRequiremetn
                ? data.supplyRequiremetn.id
                : null,
            property: data.property ? data.property.id : null,
            propertyUnit: data.propertyUnit ? data.propertyUnit.id : null
        };
        setDataList2(
            [...data.property.propertyUnits].sort((a, b) =>
                a.name.localeCompare(b.name)
            )
        );
        setEntity(newData);
        setReadyForm(true);
        if (!status) {
            ConfigToast.text = data.message;
            ConfigToast.backColor = Colors.Error;
            ConfigToast.title = 'Error';
            setConfigToast(ConfigToast);
        }
    };

    /**
     * @description
     * @function OnSubmit is a function that is called when the form is submitted
     * @param values is a object that contains the data of the form
     */
    const OnSubmit = async (values: SupplyRequirementFormCreateInterface) => {
        if (values.propertyUnit === '') {
            values.propertyUnit = null;
        }
        const response = requirementId
            ? await GetData(
                  ApiSupplyRequirementId(entityId),
                  HTTP_METHODS.PATCH,
                  values
              )
            : await GetData(ApiSupplyRequirement, HTTP_METHODS.POST, values);
        const { message, status } = response;
        if (status) {
            ConfigToast.text = message;
            ConfigToast.backColor = Colors.Success;
            setConfigToast(ConfigToast);
            setReadyForm(false);
            CallDataEdit();
            setUpdates(true);
        } else {
            ConfigToast.text = message;
            ConfigToast.backColor = Colors.Error;
            ConfigToast.title = 'Error';
            setConfigToast(ConfigToast);
        }
    };

    /**
     * @description
     * @function FocusOnInput is a function that is called when the form's cancel button is clicked
     * @param ref is a reference to the form
     */
    const FocusOnInput = (ref: any) => {
        ref.current.focus();
    };

    /**
     * @description
     * @constant FormButtons is a object that contains the buttons of the form
     */
    const FormButtons = {
        ok: {
            controller: OnSubmit
        },
        cancel: {
            controller: FocusOnInput
        }
    };

    const Edit = () => {
        setDetailPage(false);
    };

    /**
     * @description function that is executed when the delete button is pressed
     * @function Delete is a function that launches an alert to confirm the deletion of a COMPANY
     * @param requirementId is a string that contains the id of the COMPANY to be deleted
     */
    const Delete = async () => {
        ConfigAlert.title = Defaults.titles.Delete;
        ConfigAlert.message = deleteMessage;
        ConfigAlert.okButtonText = Defaults.buttons.Ok.Accept;
        ConfigAlert.okButtonController = {
            func: ConfirmDelete,
            param: requirementId
        };
        ConfigAlert.cancelButtonText = Defaults.buttons.Cancel.Cancel;
        setConfigAlert(ConfigAlert);
    };

    /**
     * @description function that is executed when the okDelete button is pressed
     * @function Delete is a function that deletes a COMPANY
     * @param requirementId is a string that contains the id of the COMPANY to be deleted
     */
    const ConfirmDelete = async (requirementId: string) => {
        const response = await GetData(
            ApiSupplyRequirementId(requirementId),
            HTTP_METHODS.DELETE
        );
        const { data, status, message } = response;
        if (status) {
            ConfigToast.text = message;
            ConfigToast.backColor = Colors.Success;
            setConfigToast(ConfigToast);
            navigate(`/panel/supply/${id}/supply-requirement`);
        } else {
            ConfigToast.text = data.message;
            ConfigToast.backColor = Colors.Error;
            ConfigToast.title = 'Error';
            setConfigToast(ConfigToast);
        }
    };

    const handleUnits = (units: PropertyUnitInterface[]) => {
        FormCreateSupplyRequirement[8].elements = [...units].sort((a, b) =>
            a.name.localeCompare(b.name)
        );
    };

    /**
     * @description this effect verify if exist id in url params, if exist, set the id in state
     */
    useEffect(() => {
        if (id) {
            setEntitySupplyId(id);
        }
    }, [id]);

    /**
     * @description this effect check that entitySupplyId is not empty and if it changes, if not empty, call the function to CallSupply
     */
    useEffect(() => {
        if (entitySupplyId !== '') {
            CallSupply();
        }
    }, [entitySupplyId]);

    /**
     * @description
     * Function that is executed when the component is mounted, calls the function that returns the datalists for the forms
     */
    useEffect(() => {
        SetData();
    }, [entitySupply]);

    useEffect(() => {
        FormCreateSupplyRequirement[7].elements = [...propertyData].sort(
            (a, b) => a.name.localeCompare(b.name)
        );
        FormCreateSupplyRequirement[8].elements = [];
    }, [propertyData]);

    useEffect(() => {
        FormCreateSupplyRequirement[8].elements = dataList2;
    }, [dataList2]);

    useEffect(() => {
        FormCreateSupplyRequirement[9].elements = dataList3;
    }, [dataList3]);

    /*  useEffect(() => {
        FormCreateSupplyRequirement[10].elements = dataList4;
    }, [dataList4]); */

    /* [
                ...propertyData
            ].sort((a, b) => a.name.localeCompare(b.name)) */
    /**
     * @description this effect verify if exist requirementId in url params, if exist, set the requirementId in state, else set readyForm to true
     */
    useEffect(() => {
        setReadyForm(false);
        if (requirementId) {
            setEntityId(requirementId);
        } else {
            setEntity(SupplyRequirementFormValues);
            setReadyForm(false);
            setTimeout(() => {
                setReadyForm(true);
            }, 50);
            setDataList2([]);
        }
    }, [requirementId]);

    /**
     * @description this effect check that entityId is not empty and if it changes, if not empty, call the function to CallDataEdit
     */
    useEffect(() => {
        if (entityId !== '') {
            CallDataEdit();
            setDetailPage(true);
        }
    }, [entityId]);

    useEffect(() => {
        setUpdates(false);
    }, [entity]);

    return (
        <>
            <AlertElement ref={alertManagerRef} />
            <ToastElement ref={toastManagerRef} />
            <SubNavBar
                type='settings'
                title={title}
                icon='settings/SupplyIcon.svg'
                kind='settings'
                option='Supply'
            ></SubNavBar>
            <div className='settings'>
                <Header
                    title={{ name: title, link: '/panel/supply' }}
                    icon='DataIconB.svg'
                    subtitle={{
                        name: entitySupply?.name ?? '',
                        link: `/panel/supply/edit/${id}`
                    }}
                    subsubtitle={{
                        name: 'Requerimientos',
                        link: `/panel/supply/${id}/supply-requirement`
                    }}
                    subsubsubtitle={{
                        name:
                            entity?.name && entity.name.length > 0
                                ? entity.name
                                : createTitle
                    }}
                />
                <div className='settings__grid-container'>
                    <div className='settings__grid-container__item'>
                        <SupplyRequirementTableComponent refresh={updates} />
                        <div className='settings__grid-container__item__content'>
                            <ButtonBody
                                able='true'
                                kind='button button-body--create'
                                text={newTitle}
                                path={`/panel/supply/${id}/supply-requirement/create`}
                            ></ButtonBody>
                        </div>
                    </div>
                    <div className='settings__grid-container__item_right'>
                        <div className='settings__grid-container__item_right__title subtitle'>
                            {requirementId ? (
                                <div>
                                    {detailPage ? detailTitle : editTitle}
                                </div>
                            ) : (
                                <div>{createTitle}</div>
                            )}
                        </div>
                        <div className='settings__grid-container__item_right__content'>
                            <>
                                {readyForm && (
                                    <>
                                        <FormBuilder
                                            initialValues={
                                                entity?.area
                                                    ? entity
                                                    : SupplyRequirementFormValues
                                            }
                                            buttons={FormButtons}
                                            lang={defaultLang}
                                            formStructure={
                                                FormCreateSupplyRequirement
                                            }
                                            focusOn={fieldRef}
                                            config={{
                                                noClearButton: !!entity?.area,
                                                editButton:
                                                    !!entity?.area && detailPage
                                            }}
                                            unitsOnSelect={handleUnits}
                                        />
                                    </>
                                )}
                            </>
                            {requirementId && detailPage && (
                                <div className='settings__grid-container__item_right__content__btn'>
                                    <button
                                        className='settings__grid-container__item_right__content__btn__delete-button item-btn subtitle'
                                        onClick={Delete}
                                    >
                                        {deleteTitle}
                                    </button>
                                    <button
                                        className='settings__grid-container__item_right__content__btn__edit-button item-btn subtitle'
                                        onClick={Edit}
                                    >
                                        {editTitle}
                                    </button>
                                </div>
                            )}
                        </div>
                        {LoadingData && <LoaderElement />}
                    </div>
                </div>
            </div>
        </>
    );
};

export default SupplyRequirementCreate;
