import { FormBuilderFields } from '../../../components/formBuilder/form-builder.interface';

export const FormCreateAppSection: FormBuilderFields[] = [
    {
        label: 'Nombre',
        fieldNameId: 'name',
        fieldType: 'text',
        fieldPlaceholder: 'Ingrese un nombre',
        isFocusInClean: true,
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            },
            min: {
                length: 3,
                state: true,
                message: 'Mínimo 3 carateres'
            },
            max: {
                length: 40,
                state: true,
                message: 'Máximo 40 caracteres'
            }
        }
    },
    {
        label: 'Path',
        fieldNameId: 'path',
        fieldType: 'text',
        fieldPlaceholder: 'Ingrese el path',
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            },
            min: {
                length: 3,
                state: true,
                message: 'Mínimo 3 carateres'
            }
        }
    },
    {
        label: 'Code',
        fieldNameId: 'code',
        fieldType: 'text',
        fieldPlaceholder: 'Ingrese el código',
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            },
            min: {
                length: 3,
                state: true,
                message: 'Mínimo 3 carateres'
            }
        }
    },
    {
        label: 'Is Aside',
        fieldNameId: 'isAside',
        fieldType: 'select',
        deffaultValues: true,
        elements: [
            {
                id: false,
                name: 'No'
            },
            {
                id: true,
                name: 'Sí'
            }
        ],
        fieldPlaceholder: 'Seleccione un elemento',
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            }
        }
    },
    {
        label: 'Is Header',
        fieldNameId: 'isHeader',
        fieldType: 'select',
        deffaultValues: true,
        elements: [
            {
                id: false,
                name: 'No'
            },
            {
                id: true,
                name: 'Sí'
            }
        ],
        fieldPlaceholder: 'Seleccione un elemento',
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            }
        }
    },
    {
        label: 'Is Icon',
        fieldNameId: 'isIcon',
        fieldType: 'select',
        deffaultValues: true,
        elements: [
            {
                id: false,
                name: 'No'
            },
            {
                id: true,
                name: 'Sí'
            }
        ],
        fieldPlaceholder: 'Seleccione un elemento',
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            }
        }
    },
    {
        label: 'Icon',
        fieldNameId: 'icon',
        fieldType: 'text',
        fieldPlaceholder: 'Ingrese el nombre del ícono',
        validations: {
            type: 'string',
            required: {
                state: false,
                message: 'Este campo es requerido'
            }
        }
    },
    {
        label: 'Orden',
        fieldNameId: 'order',
        fieldType: 'number',
        fieldPlaceholder: 'Ingrese el orden',
        isFocusInClean: true,
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            }
        }
    }
];
