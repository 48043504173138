import { useEffect } from 'react';
import { useToast } from '../../../config/hooks/useToast';
import { useAppSelector } from '../../../store/hooks';
// import { useAddToHomescreenPrompt, ModalComponent } from '../instalation';
import { UseNetworkStatus } from '../../../config/hooks/useNetworkStatus';

const Main = ({ children }: any) => {
    const { OnlineComponent } = UseNetworkStatus();

    // const [prompt, promptToInstall] = useAddToHomescreenPrompt();
    // const [isVisible, setVisibleState] = useState(false);

    // const hide = () => setVisibleState(false);

    // useEffect(() => {
    //     if (prompt) {
    //         setVisibleState(true);
    //     }
    // }, [prompt]);

    const { ConfigToast, setConfigToast, ToastElement, toastManagerRef } =
        useToast();
    const { show, toastData } = useAppSelector(state => state.toast);

    useEffect(() => {
        if (show) {
            Alerts(toastData.message, toastData.color);
        }
    }, [show]);

    const Alerts = (message, color) => {
        ConfigToast.text = message;
        ConfigToast.backColor = color;
        setConfigToast(ConfigToast);
    };

    return (
        <main>
            <ToastElement ref={toastManagerRef} />
            {children}
            {/* {isVisible && (
                <ModalComponent
                    onInstall={hide}
                    promptToInstall={promptToInstall}
                />
            )} */}
            <OnlineComponent />
        </main>
    );
};

export default Main;
