import { MaterialKindInterface } from './material-kind.interface';

export interface MaterialInterface {
    id: string;
    name: string;
    color: string;
    codeLer: string;
    state: number;
    createdAt: string;
    updatedAt: string;
    kind: MaterialKindInterface;
}

export interface MaterialFormCreateInterface {
    name: string;
    color: string;
    codeLer: string;
    kind: string;
}

export const MaterialFormValues: MaterialFormCreateInterface = {
    name: '',
    color: 'rgb(0, 0, 0)',
    codeLer: '',
    kind: ''
};
