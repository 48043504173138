export interface GestorInterface {
    id: string;
    name: string;
    contactName: string;
    email: string;
    contactNumber: string;
    state: number;
    createdAt: string;
    updatedAt: string;
}

export interface GestorFormCreateInterface {
    name: string;
    email: string;
    contactName: string;
    contactNumber: string;
}

export const GestorFormValues: GestorFormCreateInterface = {
    name: '',
    email: '',
    contactName: '',
    contactNumber: ''
};
