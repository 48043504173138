import { GetStorage, SaveStorage } from '../../../config/utils/Storage';
import { UserStoreInterface } from './user.interface';
const lang = process.env.REACT_APP_PUBLIC_LANGUAJE
    ? process.env.REACT_APP_PUBLIC_LANGUAJE
    : 'es';
SaveStorage(lang, process.env.REACT_APP_PUBLIC_STORE_LANG);
const UserData = GetStorage(process.env.REACT_APP_PUBLIC_USER_DATA_COOKIE);
const DeffaultProfile = parseInt(
    GetStorage(process.env.REACT_APP_PUBLIC_DEFFAULT_ROLE)
);
const userDataIni = {
    email: '',
    id: '',
    name: ''
};

export const UserDefault: UserStoreInterface = {
    userData: UserData || userDataIni,
    isLoged: !!UserData,
    defaultProfile: DeffaultProfile || 0,
    preferences: {
        lang
    }
};
