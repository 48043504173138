/* Styles */
import './filters.scss';

interface FormSearch {
    search: string;
}

interface Props {
    value: FormSearch;
    controller: (object: object) => void;
}

const Filters = ({ value, controller }: Props) => {
    const OnChangeInput = event => {
        controller({
            ...value,
            [event.target.name]: event.target.value
        });
    };

    return (
        <div className='filters_search'>
            <input
                type='text'
                className='filters_search__filters_input'
                placeholder={`Buscar`}
                id='search'
                name='search'
                value={value.search}
                onChange={OnChangeInput}
                required
                autoComplete='off'
            />
        </div>
    );
};

export default Filters;
