import { createSlice } from '@reduxjs/toolkit';
import { UnitDefault } from './initialValues';

export const unitSlice = createSlice({
    name: 'unit',
    initialState: UnitDefault,
    reducers: {
        setUnitData: (state, action) => {
            const data = action.payload;
            state.unitData = data;
        }
    }
});

export const { setUnitData } = unitSlice.actions;

export default unitSlice.reducer;
