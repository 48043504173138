import './records.scss';
//  <--COMPONENTS--> //
import { lang } from '../langs';
import SubNavBar from '../../components/layout/main/subnavbar/subnavbar';
/* import { Link } from 'react-router-dom';
import LogoComponent from '../../components/logo/logo';
import ButtonBody from '../../components/button/button'; */
//  <--REDUX--> //
import { UserStoreInterface } from '../../store/slices/users/user.interface';
import { useAppSelector } from '../../store/hooks';

const Records = () => {
    const { preferences }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const defaultLang: string = preferences.lang;
    const {
        [defaultLang as keyof typeof lang]: {
            pageRecords: { title }
        }
    } = lang;

    return (
        <>
            <SubNavBar
                type='settings'
                title={title}
                icon='SettingsIconB.svg'
            ></SubNavBar>
            <div className='settings-grid-container'>
                <div className='settings-grid-container__optionItem'>hola</div>
                <div className='settings-grid-container__optionItem'>hola</div>
                <div className='settings-grid-container__optionItem'>hola</div>
                <div className='settings-grid-container__optionItem'>hola</div>
                <div className='settings-grid-container__optionItem'>hola</div>
            </div>
        </>
    );
};

export default Records;
