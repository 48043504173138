import '../../../settings.scss';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
//  <--COMPONENTS--> //
import SubNavBar from '../../../../../components/layout/main/subnavbar/subnavbar';
import Header from '../../../../../components/header/header';
import FontTableComponent from '../font-table';
import FormBuilder from '../../../../../components/formBuilder/formBuilder';
import ButtonBody from '../../../../../components/button/button';
//  <--HOOKS--> //
import { useToast } from '../../../../../config/hooks/useToast';
import { useAlert } from '../../../../../config/hooks/useAlert';
import { useCallApi } from '../../../../../config/hooks/useCallApi';
import { HTTP_METHODS } from '../../../../../config/hooks/useCallApi/constants';
//  <--INTERFACE--> //
import {
    FontFormCreateInterface,
    FontFormValues,
    PropertyInterface,
    MaterialInterface,
    GestorInterface,
    TypologyInterface,
    PropertyUnitInterface
} from '../../../../../interface';
//  <--REDUX--> //
import { UserStoreInterface } from '../../../../../store/slices/users/user.interface';
import { PropertyStoreInterface } from '../../../../../store/slices/property/property.interface';
import { useAppSelector } from '../../../../../store/hooks';
//  <--SERVICES--> //
import {
    ApiFont,
    ApiFontId,
    ApiMaterial,
    ApiGestor,
    ApiTypology,
    ApiPropertyUnit,
    ApiFontCronExtractGsheet,
    ApiCronJob
} from '../../../../../config/service';
//  <--OTHERS--> //
import { lang } from '../../../../langs';
import { FormCreateFont } from './form';
import '../../../../../scss/global/global.scss';

const FontCreate = () => {
    const { GetData, LoadingData, LoaderElement } = useCallApi();
    const {
        ConfigToast,
        setConfigToast,
        ToastElement,
        toastManagerRef,
        Colors
    } = useToast();
    const { preferences }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const { propertyData }: PropertyStoreInterface = useAppSelector(
        state => state.property
    );
    const defaultLang: string = preferences.lang;
    const {
        AlertElement,
        alertManagerRef,
        ConfigAlert,
        setConfigAlert,
        Defaults
    } = useAlert({ defaultLang });
    const fieldRef = useRef(null);
    const { id } = useParams();
    const {
        [defaultLang as keyof typeof lang]: {
            pageData: {
                title,
                option1,
                option2,
                font: {
                    createTitle,
                    editTitle,
                    detailTitle,
                    deleteMessage,
                    deleteTitle,
                    newTitle,
                    uploadDataTitle
                }
            }
        }
    } = lang;
    const [entityId, setEntityId] = useState<string>('');
    const [entity, setEntity] = useState<FontFormCreateInterface>();
    const [readyForm, setReadyForm] = useState<boolean>(false);
    const [detailPage, setDetailPage] = useState<boolean>(true);
    const navigate = useNavigate();
    const [updates, setUpdates] = useState<boolean>(false);
    const [showFont, setShowFont] = useState(true);
    const [dataList, setDataList] = useState<PropertyInterface[]>([]);
    const [dataList2, setDataList2] = useState<MaterialInterface[]>([]);
    const [dataList3, setDataList3] = useState<GestorInterface[]>([]);
    const [dataList4, setDataList4] = useState<TypologyInterface[]>([]);

    /**
     * @description
     * @function CallData is a function that returns a promise with the Company list
     */
    const CallData = async () => {
        setDataList(
            [...propertyData].sort((a, b) => a.name.localeCompare(b.name))
        );

        const response2 = await GetData(ApiMaterial, HTTP_METHODS.GET);
        const res = response2;
        setDataList2(
            [...res.data].sort((a, b) => a.name.localeCompare(b.name))
        );

        const response3 = await GetData(ApiGestor, HTTP_METHODS.GET);
        const { data, status } = response3;
        setDataList3([...data].sort((a, b) => a.name.localeCompare(b.name)));

        const response4 = await GetData(ApiTypology, HTTP_METHODS.GET);
        setDataList4(response4.data);

        if (!status) {
            ConfigToast.text = data.message;
            ConfigToast.backColor = Colors.Error;
            ConfigToast.title = 'Error';
            setConfigToast(ConfigToast);
        }
    };

    /**
     * @description
     * Function that is executed when the component is mounted, calls the function that returns the Company and UserRole list
     */
    useEffect(() => {
        CallData();
    }, []);

    useEffect(() => {
        if (dataList && dataList?.length > 0) {
            FormCreateFont[5].elements = dataList; // property
        }
    }, [dataList]);

    useEffect(() => {
        if (dataList2 && dataList2?.length > 0) {
            FormCreateFont[7].elements = dataList2; // material
        }
    }, [dataList2]);

    useEffect(() => {
        if (dataList3 && dataList3?.length > 0) {
            FormCreateFont[8].elements = dataList3; // gestor
        }
    }, [dataList3]);

    useEffect(() => {
        if (dataList4 && dataList4?.length > 0) {
            FormCreateFont[4].elements = dataList4; // typology
        }
    }, [dataList4]);

    /**
     * @description this effect verify if exist id in url params, if exist, set the id in state, else set readyForm to true
     */
    useEffect(() => {
        setReadyForm(false);
        if (id) {
            setEntityId(id);
        } else {
            setEntity(FontFormValues);
            setReadyForm(false);
            setTimeout(() => {
                setReadyForm(true);
            }, 50);
        }
    }, [id]);

    /**
     * @description CallDataEdit function, call the api to get the data of the entity and set the data in state
     */
    const CallDataEdit = async () => {
        const response = await GetData(ApiFontId(entityId), HTTP_METHODS.GET);
        const { data, status, message } = response;
        const response2 = await GetData(ApiPropertyUnit, HTTP_METHODS.GET);
        FormCreateFont[6].elements = response2.data;
        const newData = {
            ...data,
            material: data.material ? data.material.id : null,
            gestor: data.gestor ? data.gestor.id : null,
            property: data.property ? data.property.id : null,
            propertyUnit: data.propertyUnit ? data.propertyUnit.id : null,
            dataTypology: data.dataTypology ? data.dataTypology.id : null
        };
        setEntity(newData);
        setReadyForm(true);
        if (!status) {
            ConfigToast.text = message;
            ConfigToast.backColor = Colors.Error;
            ConfigToast.title = 'Error';
            setConfigToast(ConfigToast);
        }
    };

    /**
     * @description this effect check that entityId is not empty and if it changes, if not empty, call the function to CallDataEdit
     */
    useEffect(() => {
        if (entityId !== '') {
            CallDataEdit();
            setDetailPage(true);
        }
    }, [entityId]);

    /**
     * @description
     * @function OnSubmit is a function that is called when the form is submitted
     * @param values is a object that contains the data of the form
     */
    const OnSubmit = async (values: FontFormCreateInterface) => {
        const response = id
            ? await GetData(ApiFontId(entityId), HTTP_METHODS.PATCH, values)
            : await GetData(ApiFont, HTTP_METHODS.POST, values);
        const { message, status } = response;
        if (status) {
            ConfigToast.text = message;
            ConfigToast.backColor = Colors.Success;
            setConfigToast(ConfigToast);
            setReadyForm(false);
            CallDataEdit();
            setUpdates(true);
        } else {
            ConfigToast.text = message;
            ConfigToast.backColor = Colors.Error;
            ConfigToast.title = 'Error';
            setConfigToast(ConfigToast);
        }
    };

    /**
     * @description
     * @function FocusOnInput is a function that is called when the form's cancel button is clicked
     * @param ref is a reference to the form
     */
    const FocusOnInput = (ref: any) => {
        ref.current.focus();
    };

    /**
     * @description
     * @constant FormButtons is a object that contains the buttons of the form
     */
    const FormButtons = {
        ok: {
            controller: OnSubmit
        },
        cancel: {
            controller: FocusOnInput
        }
    };

    const Edit = () => {
        setDetailPage(false);
    };

    /**
     * @description function that is executed when the delete button is pressed
     * @function Delete is a function that launches an alert to confirm the deletion of a COMPANY
     * @param id is a string that contains the id of the COMPANY to be deleted
     */
    const Delete = async () => {
        ConfigAlert.title = Defaults.titles.Delete;
        ConfigAlert.message = deleteMessage;
        ConfigAlert.okButtonText = Defaults.buttons.Ok.Accept;
        ConfigAlert.okButtonController = { func: ConfirmDelete, param: id };
        ConfigAlert.cancelButtonText = Defaults.buttons.Cancel.Cancel;
        setConfigAlert(ConfigAlert);
    };

    /**
     * @description function that is executed when the okDelete button is pressed
     * @function Delete is a function that deletes a COMPANY
     * @param id is a string that contains the id of the COMPANY to be deleted
     */
    const ConfirmDelete = async (id: string) => {
        const response = await GetData(ApiFontId(id), HTTP_METHODS.DELETE);
        const { data, status, message } = response;
        if (status) {
            ConfigToast.text = message;
            ConfigToast.backColor = Colors.Success;
            setConfigToast(ConfigToast);
            navigate('/panel/data/font');
        } else {
            ConfigToast.text = data.message;
            ConfigToast.backColor = Colors.Error;
            ConfigToast.title = 'Error';
            setConfigToast(ConfigToast);
        }
    };

    const handleUnits = (units: PropertyUnitInterface[]) => {
        FormCreateFont[6].elements = [...units].sort((a, b) =>
            a.name.localeCompare(b.name)
        );
    };

    useEffect(() => {
        setUpdates(false);
    }, [entity]);

    const UploadData = async () => {
        if (entity?.identifier) {
            const response = await GetData(
                ApiFontCronExtractGsheet(entity.identifier),
                HTTP_METHODS.POST
            );
            const { status, message } = response;
            if (status) {
                ConfigToast.text = message;
                ConfigToast.backColor = Colors.Success;
                setConfigToast(ConfigToast);
                await GetData(ApiCronJob, HTTP_METHODS.POST);
            } else {
                ConfigToast.text = message;
                ConfigToast.backColor = Colors.Error;
                ConfigToast.title = 'Error';
                setConfigToast(ConfigToast);
            }
        }
    };

    return (
        <>
            <AlertElement ref={alertManagerRef} />
            <ToastElement ref={toastManagerRef} />
            <SubNavBar
                type='settings'
                title={title}
                icon='settings/DataIcon.svg'
                kind='settings'
                option='Data'
            ></SubNavBar>
            <div className='settings'>
                <Header
                    title={{ name: title, link: '/panel/data/font' }}
                    icon='DataIconB.svg'
                    subtitle={{ name: id ? entity?.name ?? createTitle : '' }}
                />
                <div className='settings__grid-container'>
                    <div className='settings__grid-container__item'>
                        <div className='settings__grid-container__item__title title'>
                            <Link
                                className={`settings__grid-container__item__title__section2__item left ${
                                    !showFont ? 'active' : 'not-active'
                                }`}
                                onClick={() => setShowFont(false)}
                                to='/panel/data'
                            >
                                {option1}
                            </Link>
                            <Link
                                className={`settings__grid-container__item__title__section2__item right ${
                                    showFont ? 'active' : 'not-active'
                                }`}
                                onClick={() => setShowFont(true)}
                                to='/panel/data/font'
                            >
                                {option2}
                            </Link>
                        </div>
                        <FontTableComponent refresh={updates} />
                        <div className='settings__grid-container__item__content'>
                            <ButtonBody
                                able='true'
                                kind='button button-body--create'
                                text={newTitle}
                                path='/panel/data/font/create'
                            ></ButtonBody>
                        </div>
                    </div>
                    <div className='settings__grid-container__item_right'>
                        <div className='settings__grid-container__item_right__title title'>
                            {id ? (
                                <div>
                                    {detailPage ? detailTitle : editTitle}
                                </div>
                            ) : (
                                <div>{createTitle}</div>
                            )}
                        </div>
                        <div className='settings__grid-container__item_right__content'>
                            <>
                                {readyForm && (
                                    <FormBuilder
                                        initialValues={
                                            entity?.name
                                                ? entity
                                                : FontFormValues
                                        }
                                        buttons={FormButtons}
                                        lang={defaultLang}
                                        formStructure={FormCreateFont}
                                        focusOn={fieldRef}
                                        config={{
                                            noClearButton: !!entity?.name,
                                            editButton:
                                                !!entity?.name && detailPage
                                        }}
                                        unitsOnSelect={handleUnits}
                                    />
                                )}
                            </>
                            {id && detailPage && (
                                <>
                                    <div className='settings__grid-container__item_right__content__btn'>
                                        <button
                                            className='settings__grid-container__item_right__content__btn__delete-button item-btn subtitle'
                                            onClick={UploadData}
                                        >
                                            {uploadDataTitle}
                                        </button>
                                    </div>
                                    <div className='settings__grid-container__item_right__content__btn'>
                                        <button
                                            className='settings__grid-container__item_right__content__btn__delete-button item-btn subtitle'
                                            onClick={Delete}
                                        >
                                            {deleteTitle}
                                        </button>
                                        <button
                                            className='settings__grid-container__item_right__content__btn__edit-button item-btn subtitle'
                                            onClick={Edit}
                                        >
                                            {editTitle}
                                        </button>
                                    </div>
                                </>
                            )}
                        </div>
                        {LoadingData && <LoaderElement />}
                        {id && (
                            <>
                                <div className='settings__grid-container__item_right__title subtitle'>
                                    Requerimientos
                                </div>
                                <div className='settings__grid-container__item_right__content'>
                                    <ButtonBody
                                        able='true'
                                        kind='button button-body--create'
                                        text='Ver requerimientos'
                                        path={`/panel/data/font/${id}/requirements`}
                                    ></ButtonBody>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default FontCreate;
