import '../ranking.scss';

interface Props {
    disqualified: any[];
    pdf?: boolean;
}

const Disqualified = ({ disqualified, pdf }: Props) => {
    return (
        <div
            className='ranking__punished'
            style={{ height: pdf ? 'auto' : '25%' }}
        >
            {!pdf && (
                <div className='ranking__punished__title title'>
                    Inmuebles/Unidades seleccionadas que no participan de
                    ranking por falta de requerimiento
                </div>
            )}
            <div className='ranking__punished__content'>
                <div className='ranking__punished__content__list'>
                    {disqualified.length > 0 &&
                        disqualified.map(item => (
                            <div
                                className='ranking__punished__content__list__item'
                                key={item.property ?? item.propertyUnit}
                            >
                                <div
                                    className={`ranking__punished__content__list__item__number ${
                                        pdf ? 'ranking-pdf' : 'title'
                                    }`}
                                >
                                    #?
                                </div>
                                <div
                                    className={`ranking__punished__content__list__item__name ${
                                        pdf ? 'ranking-pdf' : 'subtitle'
                                    }`}
                                >
                                    {item.propertyName ?? item.propertyUnitName}
                                </div>
                                <img
                                    className={`ranking__punished__content__list__item__icon ${
                                        pdf ? 'ranking-pdf' : 'subtitle'
                                    }`}
                                    src='/svg-icons/WarningIcon.svg'
                                />
                                <div
                                    className={`ranking__punished__content__list__item__message ${
                                        pdf ? 'ranking-pdf' : 'subtitle'
                                    }`}
                                >
                                    {item.warning?.[0]?.message ??
                                        item.warning?.[1]?.message ??
                                        item.warning?.[2]?.message}
                                </div>
                            </div>
                        ))}
                </div>
            </div>
        </div>
    );
};

export default Disqualified;
