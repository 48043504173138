import { lang } from '../../../../components/langs';
import { UserStoreInterface } from '../../../../store/slices/users/user.interface';
import { useAppSelector } from '../../../../store/hooks';
import MyProperty from './my-property';
import Disqualified from './disqualified';
import RankingList from './ranking-list';
import { Doughnut } from 'react-chartjs-2';
import { NormalizeName } from '../../../../config/utils/Utilities';

interface Props {
    data: any;
    name?: string;
    period: string;
    myProperty?: any;
    podio: any;
    disqualified: any;
    clientImage?: string;
}

const RankingCertificate = ({
    data,
    name,
    period,
    myProperty,
    podio,
    disqualified,
    clientImage
}: Props) => {
    const { preferences }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const defaultLang: string = preferences.lang;
    const key = defaultLang;
    const {
        [key as keyof typeof lang]: {
            dataCenterComponents: {
                monthlyClosureCard: {
                    /* months, */
                    certificatesTxts: {
                        rut,
                        address,
                        web,
                        ranking: {
                            title,
                            text1a,
                            text1b,
                            myPropertyTitle,
                            podioTitle,
                            rankingTitle,
                            disqualifiedTitle
                        }
                    }
                }
            }
        }
    } = lang;
    const fechaActual = new Date();
    const fechaActualFormateada = fechaActual.toLocaleString();

    return (
        <div className='pdf-container'>
            <div className='certificate'>
                <div className='certificate__header-balance'>
                    <div className='certificate__header-balance__info'>
                        <div className='certificate__header-balance__info__text'>
                            <h2>
                                {' '}
                                {rut} | {address} | {web}{' '}
                            </h2>
                            <h2> Descargado el: {fechaActualFormateada} </h2>
                        </div>
                        {clientImage && (
                            <div className='certificate__header-balance__info__logo'>
                                <img
                                    className='left-header__logo__img'
                                    src={`${clientImage}`}
                                />
                            </div>
                        )}
                    </div>
                    <div className='certificate__header-balance__title'>
                        <div className='certificate__header-balance__title__cert-name'>
                            {title} | {period}
                        </div>
                        <div className='certificate__header-balance__title__property-name'>
                            {name}
                        </div>
                    </div>
                </div>
                <div className='certificate__section'>
                    <p>
                        {' '}
                        {text1a} {period}
                        {text1b}
                    </p>
                </div>
                {myProperty && (
                    <div className='certificate__section'>
                        <h1 className='certificate__section__title'>
                            {myPropertyTitle}
                        </h1>
                        <div className='certificate__section__table'>
                            <MyProperty myProperty={myProperty} pdf={true} />
                        </div>
                    </div>
                )}
                <div className='certificate__section'>
                    <h1 className='certificate__section__title'>
                        {' '}
                        {podioTitle}{' '}
                    </h1>
                    <div className='certificate__section__table'>
                        <div className='ranking__list__content__podium'>
                            {podio?.map((item, index) => (
                                <div
                                    className={`ranking__list__content__podium__item ${
                                        index === 1
                                            ? 'first-certificate'
                                            : index === 0
                                            ? 'second-certificate'
                                            : 'third-certificate'
                                    }`}
                                    key={index}
                                >
                                    <div className='ranking__list__content__podium__item__header podiun-pdf'>
                                        <img
                                            className='ranking__list__content__podium__item__header__logo'
                                            src={`/svg-icons/${
                                                index === 0
                                                    ? 'SecondPlaceIcon.svg'
                                                    : index === 1
                                                    ? 'FirstPlaceIcon.svg'
                                                    : 'ThirdPlaceIcon.svg'
                                            }`}
                                        />
                                        <div className='ranking__list__content__podium__item__header__content'>
                                            <div
                                                className={`ranking__list__content__podium__item__header__content__name ranking-pdf`}
                                            >
                                                {item.propertyName ??
                                                    item.propertyUnitName}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='ranking__list__content__podium__item__chart'>
                                        <Doughnut
                                            data={{
                                                datasets: [
                                                    {
                                                        data: [
                                                            item?.BZeroIndexTrim,
                                                            100 -
                                                                item?.BZeroIndexTrim
                                                        ],
                                                        backgroundColor:
                                                            item.BZeroIndexTrim <=
                                                            50
                                                                ? [
                                                                      '#03dac5',
                                                                      '#fff'
                                                                  ]
                                                                : item.BZeroIndexTrim >
                                                                  75
                                                                ? [
                                                                      '#CE3E50',
                                                                      '#fff'
                                                                  ]
                                                                : [
                                                                      '#FDDF4A',
                                                                      '#fff'
                                                                  ],
                                                        borderColor: '#fff',
                                                        borderWidth: 8
                                                    }
                                                ]
                                            }}
                                            options={{
                                                plugins: {
                                                    legend: {
                                                        display: false
                                                    },
                                                    tooltip: {
                                                        enabled: true,
                                                        backgroundColor:
                                                            'rgb(255, 255, 255)',
                                                        titleColor:
                                                            'rgb(162 162 163)', // Color del texto del título del tooltip
                                                        bodyColor:
                                                            'rgb(162 162 163)', // Color del texto del cuerpo del tooltip
                                                        footerColor:
                                                            'rgb(162 162 163)',
                                                        padding: 6,
                                                        boxPadding: 3,
                                                        usePointStyle: true
                                                    }
                                                },
                                                rotation: -90,
                                                circumference: 180,
                                                cutout: '75%',
                                                maintainAspectRatio: true,
                                                responsive: true
                                            }}
                                        />
                                        <div className='bzeroindex-card__content__chart__number percentage-certificate'>
                                            {item?.BZeroIndexTrim.toLocaleString(
                                                'es-CL',
                                                {
                                                    minimumFractionDigits: 1,
                                                    maximumFractionDigits: 1
                                                }
                                            )}
                                            %
                                        </div>
                                        <div
                                            className={`bzeroindex-card__content__chart__inf ranking-pdf`}
                                        >
                                            0%
                                        </div>
                                        <div
                                            className={`bzeroindex-card__content__chart__sup ranking-pdf`}
                                        >
                                            100%
                                        </div>
                                    </div>
                                    <div className='ranking__list__content__podium__item__detail detail-certificate'>
                                        <div
                                            className={`ranking__myproperty__content__data__item ranking-pdf`}
                                            style={{
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}
                                        >
                                            <img
                                                className='ranking__list__content__podium__item__header__logo'
                                                src='/svg-icons/Recyclable.svg'
                                            />
                                            <div className='ranking__myproperty__content__data__item__q'>
                                                {item?.RecyclableTrim.toLocaleString(
                                                    'es-CL',
                                                    {
                                                        minimumFractionDigits: 0,
                                                        maximumFractionDigits: 0
                                                    }
                                                )}
                                            </div>
                                        </div>
                                        <div
                                            className={`ranking__myproperty__content__data__item ranking-pdf`}
                                            style={{
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}
                                        >
                                            <img
                                                className='ranking__list__content__podium__item__header__logo'
                                                src={`/svg-icons/material-icons/${NormalizeName(
                                                    'orgánicos'
                                                )}.svg`}
                                            />
                                            <div className='ranking__myproperty__content__data__item__q'>
                                                {item?.OrganicTrim.toLocaleString(
                                                    'es-CL',
                                                    {
                                                        minimumFractionDigits: 0,
                                                        maximumFractionDigits: 0
                                                    }
                                                )}
                                            </div>
                                        </div>
                                        <div
                                            className={`ranking__myproperty__content__data__item ranking-pdf`}
                                            style={{
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}
                                        >
                                            <img
                                                className='ranking__list__content__podium__item__header__logo'
                                                src={`/svg-icons/material-icons/${NormalizeName(
                                                    'Basura'
                                                )}.svg`}
                                            />
                                            <div className='ranking__myproperty__content__data__item__q'>
                                                {item.TrashTrim.toLocaleString(
                                                    'es-CL',
                                                    {
                                                        minimumFractionDigits: 0,
                                                        maximumFractionDigits: 0
                                                    }
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {data.length > 0 && (
                    <div className='certificate__section'>
                        <h1 className='certificate__section__title'>
                            {' '}
                            {rankingTitle} {period}{' '}
                        </h1>
                        <div className='certificate__section__table'>
                            <RankingList podio={podio} data={data} pdf={true} />
                        </div>
                    </div>
                )}
                {disqualified.length > 0 && (
                    <div className='certificate__section'>
                        <h1 className='certificate__section__title'>
                            {disqualifiedTitle}
                        </h1>
                        <div className='certificate__section__table'>
                            <Disqualified
                                disqualified={disqualified}
                                pdf={true}
                            />
                        </div>
                    </div>
                )}
                <div className='certificate__footer'>
                    <img
                        className='certificate__footer-img'
                        src='/certificates/footer.jpg'
                    />
                </div>
            </div>
        </div>
    );
};

export default RankingCertificate;
