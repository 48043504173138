import { FormBuilderFields } from '../../../../components/formBuilder/form-builder.interface';

export const FormCreateSupply: FormBuilderFields[] = [
    {
        label: 'Nombre',
        fieldNameId: 'name',
        fieldType: 'text',
        fieldPlaceholder: 'Ingrese el nombre',
        isFocusInClean: true,
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            },
            min: {
                length: 3,
                state: true,
                message: 'Mínimo 3 carateres'
            },
            max: {
                length: 40,
                state: true,
                message: 'Máximo 40 caracteres'
            }
        }
    },
    {
        label: 'Descripción',
        fieldNameId: 'description',
        fieldType: 'text',
        fieldPlaceholder: 'Ingrese la descripción',
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            },
            min: {
                length: 3,
                state: true,
                message: 'Mínimo 3 carateres'
            },
            max: {
                length: 40,
                state: true,
                message: 'Máximo 40 caracteres'
            }
        }
    },
    {
        label: 'Unidad de medida',
        fieldNameId: 'unitSupply',
        fieldType: 'select',
        elements: [{ id: 'unidades', name: 'Unidades' }],
        fieldPlaceholder: 'Seleccione la unidad de medida',
        isFocusInClean: true,
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            }
        }
    },
    {
        label: 'Capacidad',
        fieldNameId: 'capacity',
        fieldType: 'number',
        fieldPlaceholder: 'Ingrese la capacidad',
        isFocusInClean: true,
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            }
        }
    },
    {
        label: 'Unidad de medida capacidad',
        fieldNameId: 'unitCapacity',
        fieldType: 'text',
        fieldPlaceholder: 'Ingrese la unidad de medida de la capacidad',
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            },
            min: {
                length: 1,
                state: true,
                message: 'Mínimo 3 carateres'
            },
            max: {
                length: 40,
                state: true,
                message: 'Máximo 40 caracteres'
            }
        }
    },
    {
        label: 'Equivalencia',
        fieldNameId: 'equivalence',
        fieldType: 'number',
        fieldPlaceholder: 'Ingrese la equivalencia',
        isFocusInClean: true,
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            }
        }
    },
    {
        label: 'Unidad de medida equivalencia',
        fieldNameId: 'unitEquivalence',
        fieldType: 'text',
        fieldPlaceholder: 'Ingrese la unidad de medida de la equivalencia',
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            },
            min: {
                length: 1,
                state: true,
                message: 'Mínimo 3 carateres'
            },
            max: {
                length: 40,
                state: true,
                message: 'Máximo 40 caracteres'
            }
        }
    }
];
