import '../../settings.scss';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
//  <--COMPONENTS--> //
import SubNavBar from '../../../../components/layout/main/subnavbar/subnavbar';
import Header from '../../../../components/header/header';
import TypologyTableComponent from '../typology-table';
import FormBuilder from '../../../../components/formBuilder/formBuilder';
import ButtonBody from '../../../../components/button/button';
//  <--HOOKS--> //
import { useToast } from '../../../../config/hooks/useToast';
import { useAlert } from '../../../../config/hooks/useAlert';
import { useCallApi } from '../../../../config/hooks/useCallApi';
import { HTTP_METHODS } from '../../../../config/hooks/useCallApi/constants';
//  <--INTERFACE--> //
import {
    TypologyFormCreateInterface,
    TypologyFormValues
} from '../../../../interface';
//  <--REDUX--> //
import { UserStoreInterface } from '../../../../store/slices/users/user.interface';
import { useAppSelector } from '../../../../store/hooks';
//  <--SERVICES--> //
import { ApiTypology, ApiTypologyId } from '../../../../config/service';
//  <--OTHERS--> //
import { lang } from '../../../langs';
import { FormCreateTypology } from './form';
import '../../../../scss/global/global.scss';

const DataCreate = () => {
    const { GetData, LoadingData, LoaderElement } = useCallApi();
    const {
        ConfigToast,
        setConfigToast,
        ToastElement,
        toastManagerRef,
        Colors
    } = useToast();
    const { preferences }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const defaultLang: string = preferences.lang;
    const {
        AlertElement,
        alertManagerRef,
        ConfigAlert,
        setConfigAlert,
        Defaults
    } = useAlert({ defaultLang });
    const fieldRef = useRef(null);
    const { id } = useParams();
    const {
        [defaultLang as keyof typeof lang]: {
            pageData: {
                title,
                option1,
                option2,
                typology: {
                    createTitle,
                    editTitle,
                    detailTitle,
                    deleteMessage,
                    deleteTitle,
                    newTitle
                }
            }
        }
    } = lang;
    const [entityId, setEntityId] = useState<string>('');
    const [entity, setEntity] = useState<TypologyFormCreateInterface>();
    const [readyForm, setReadyForm] = useState<boolean>(false);
    const [detailPage, setDetailPage] = useState<boolean>(true);
    const navigate = useNavigate();
    const [updates, setUpdates] = useState<boolean>(false);
    const [showFont, setShowFont] = useState(false);

    /**
     * @description this effect verify if exist id in url params, if exist, set the id in state, else set readyForm to true
     */
    useEffect(() => {
        setReadyForm(false);
        if (id) {
            setEntityId(id);
        } else {
            setEntity(TypologyFormValues);
            setReadyForm(false);
            setTimeout(() => {
                setReadyForm(true);
            }, 50);
        }
    }, [id]);

    /**
     * @description CallDataEdit function, call the api to get the data of the entity and set the data in state
     */
    const CallDataEdit = async () => {
        const response = await GetData(
            ApiTypologyId(entityId),
            HTTP_METHODS.GET
        );
        const { data, status } = response;
        setEntity(data);
        setReadyForm(true);
        if (!status) {
            ConfigToast.text = data.message;
            ConfigToast.backColor = Colors.Error;
            ConfigToast.title = 'Error';
            setConfigToast(ConfigToast);
        }
    };

    /**
     * @description this effect check that entityId is not empty and if it changes, if not empty, call the function to CallDataEdit
     */
    useEffect(() => {
        if (entityId !== '') {
            CallDataEdit();
            setDetailPage(true);
        }
    }, [entityId]);

    /**
     * @description
     * @function OnSubmit is a function that is called when the form is submitted
     * @param values is a object that contains the data of the form
     */
    const OnSubmit = async (values: TypologyFormCreateInterface) => {
        const newValues = {
            ...values,
            transferResidue: values.transferResidue === 'De entrega'
        };
        const response = id
            ? await GetData(
                  ApiTypologyId(entityId),
                  HTTP_METHODS.PATCH,
                  newValues
              )
            : await GetData(ApiTypology, HTTP_METHODS.POST, newValues);
        const { message, data, status } = response;
        if (status) {
            ConfigToast.text = message;
            ConfigToast.backColor = Colors.Success;
            setConfigToast(ConfigToast);
            setReadyForm(false);
            CallDataEdit();
            setUpdates(true);
        } else {
            ConfigToast.text = data.message;
            ConfigToast.backColor = Colors.Error;
            ConfigToast.title = 'Error';
            setConfigToast(ConfigToast);
        }
    };

    /**
     * @description
     * @function FocusOnInput is a function that is called when the form's cancel button is clicked
     * @param ref is a reference to the form
     */
    const FocusOnInput = (ref: any) => {
        ref.current.focus();
    };

    /**
     * @description
     * @constant FormButtons is a object that contains the buttons of the form
     */
    const FormButtons = {
        ok: {
            controller: OnSubmit
        },
        cancel: {
            controller: FocusOnInput
        }
    };

    const Edit = () => {
        setDetailPage(false);
    };

    /**
     * @description function that is executed when the delete button is pressed
     * @function Delete is a function that launches an alert to confirm the deletion of a COMPANY
     * @param id is a string that contains the id of the COMPANY to be deleted
     */
    const Delete = async () => {
        ConfigAlert.title = Defaults.titles.Delete;
        ConfigAlert.message = deleteMessage;
        ConfigAlert.okButtonText = Defaults.buttons.Ok.Accept;
        ConfigAlert.okButtonController = { func: ConfirmDelete, param: id };
        ConfigAlert.cancelButtonText = Defaults.buttons.Cancel.Cancel;
        setConfigAlert(ConfigAlert);
    };

    /**
     * @description function that is executed when the okDelete button is pressed
     * @function Delete is a function that deletes a COMPANY
     * @param id is a string that contains the id of the COMPANY to be deleted
     */
    const ConfirmDelete = async (id: string) => {
        const response = await GetData(ApiTypologyId(id), HTTP_METHODS.DELETE);
        const { data, status, message } = response;
        if (status) {
            ConfigToast.text = message;
            ConfigToast.backColor = Colors.Success;
            setConfigToast(ConfigToast);
            navigate('/panel/data');
        } else {
            ConfigToast.text = data.message;
            ConfigToast.backColor = Colors.Error;
            ConfigToast.title = 'Error';
            setConfigToast(ConfigToast);
        }
    };

    useEffect(() => {
        setUpdates(false);
    }, [entity]);

    return (
        <>
            <AlertElement ref={alertManagerRef} />
            <ToastElement ref={toastManagerRef} />
            <SubNavBar
                type='settings'
                title={title}
                icon='settings/DataIcon.svg'
                kind='settings'
                option='Data'
            ></SubNavBar>
            <div className='settings'>
                <Header
                    title={{ name: title, link: '/panel/data' }}
                    icon='DataIconB.svg'
                    subtitle={{ name: id ? editTitle : createTitle }}
                />
                <div className='settings__grid-container'>
                    <div className='settings__grid-container__item'>
                        <div className='settings__grid-container__item__title title'>
                            <Link
                                className={`settings__grid-container__item__title__section2__item left ${
                                    !showFont ? 'active' : 'not-active'
                                }`}
                                onClick={() => setShowFont(false)}
                                to='/panel/data'
                            >
                                {option1}
                            </Link>
                            <Link
                                className={`settings__grid-container__item__title__section2__item right ${
                                    showFont ? 'active' : 'not-active'
                                }`}
                                onClick={() => setShowFont(true)}
                                to='/panel/data/font'
                            >
                                {option2}
                            </Link>
                        </div>
                        <TypologyTableComponent refresh={updates} />
                        <div className='settings__grid-container__item__content'>
                            <ButtonBody
                                able='true'
                                kind='button button-body--create'
                                text={newTitle}
                                path='/panel/data/create'
                            ></ButtonBody>
                        </div>
                    </div>
                    <div className='settings__grid-container__item_right'>
                        <div className='settings__grid-container__item_right__title title'>
                            {id ? (
                                <div>
                                    {detailPage ? detailTitle : editTitle}
                                </div>
                            ) : (
                                <div>{createTitle}</div>
                            )}
                        </div>
                        <div className='settings__grid-container__item_right__content'>
                            <>
                                {readyForm && (
                                    <FormBuilder
                                        initialValues={
                                            entity?.name
                                                ? entity
                                                : TypologyFormValues
                                        }
                                        buttons={FormButtons}
                                        lang={defaultLang}
                                        formStructure={FormCreateTypology}
                                        focusOn={fieldRef}
                                        config={{
                                            noClearButton: !!entity?.name,
                                            editButton:
                                                !!entity?.name && detailPage
                                        }}
                                    />
                                )}
                            </>
                            {id && detailPage && (
                                <div className='settings__grid-container__item_right__content__btn'>
                                    <button
                                        className='settings__grid-container__item_right__content__btn__delete-button item-btn subtitle'
                                        onClick={Delete}
                                    >
                                        {deleteTitle}
                                    </button>
                                    <button
                                        className='settings__grid-container__item_right__content__btn__edit-button item-btn subtitle'
                                        onClick={Edit}
                                    >
                                        {editTitle}
                                    </button>
                                </div>
                            )}
                        </div>
                        {LoadingData && <LoaderElement />}
                    </div>
                </div>
            </div>
        </>
    );
};

export default DataCreate;
